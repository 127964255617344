<template>
  <router-link
    v-if="routeInPermissions(this.$router.resolve({ name: this.entity }), permissions)"
    :to="{ name: this.entity }"
    :aria-label="$t('components.create-new', { entity: this.entity })"
  >
    <button
      :aria-label="entity"
      class="
        bg-[var(--NovikGray)]
        hover:bg-[var(--NovikDarkGray)]
        rounded-full
        w-14
        h-14
        fixed
        bottom-6
        sm:bottom-16
        right-6
      "
    >
      <i class="icon-add-entity text-white text-2xl"></i>
    </button>
  </router-link>
</template>

<script>
import { routeInPermissions } from '/src/helper'

export default {
  name: 'Add',

  inject: {
    permissions: {
      from: 'permissions'
    }
  },

  methods: {routeInPermissions},

  props: {
    entity: String
  }
}
</script>
