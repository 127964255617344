<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 mx-auto md:mx-0 flex flex-col">
      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="role-name" class="block pl-2 text-sm">{{ $t('components.role') }} {{ $t('general.name') }}</label>
        <v-input
          type="text"
          name="role-name"
          v-model="role.name"
          autocomplete="off"
        />
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="description" class="block pl-2 text-sm">{{ $t('general.description') }}</label>
        <v-input
          type="text"
          name="description"
          v-model="role.description"
          autocomplete="off"
        />
      </div>

      <div class="mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
        <label for="email">{{ $t('components.permissions') }}</label>
        <div class="flex justify-between p-2 text-sm rounded hover:bg-[var(--EntityBG)]" v-for="permission in permissions">
          <v-toggleswitch
            v-model="role.permissions"
            :entry="permission"
            :keyValue="['id', 'name']"
            :debounced="false"
          />
        </div>
      </div>

      <div class="mb-9">
        <div class="flex justify-end items-center">
          <a class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer" @click="$router.back()">{{ $t('general.cancel') }}</a>
          <v-button
            class="!w-max uppercase"
            theme="gray"
            @click="createRole"
          >
            {{ $t('general.create') }} {{ $t('components.role') }}
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Button from '/src/components/functionality/Button.vue'
import Input from '/src/components/functionality/Input.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import Loader from '/src/components/partials/Loader.vue'

export default {
  name: 'RoleAdd',

  components: {
    'v-button': Button,
    'v-input': Input,
    'v-toggleswitch': ToggleSwitch,
    'v-loader': Loader
  },

  data() {
    return {
      toast: this.$root.$refs.toast,
      permissions: [],
      role: {
        name: '',
        description: '',
        permissions: []
      }
    }
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
    })
  },

  methods: {
    fetchData() {
      this.$store.dispatch('RoleService/getPermissions')
        .then(data => {
          data.forEach((permission, index) => {
            this.permissions.push({id: index, name: permission})
          })
        })
    },

    createRole() {
      this.$store.dispatch('RoleService/addRole', this.role)
        .then((response) => {
            this.toast.make({
              'status': 'success',
              'text': response.data.status.message,
              'timer': 2000
            })
            this.$router.push({name: 'Roles'})
        }).catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': false
          })
      })
    }
  }
}
</script>
