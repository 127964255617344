<template>
  <label
    for="dropzone-file"
    :class="theme === 'flat' ? 'h-auto w-64' : 'w-full h-64'"
    class="
      w-full
      flex
      flex-col
      justify-center
      items-center
      bg-[var(--EntityBG)]
      rounded-lg
      cursor-pointer
      hover:bg-[var(--EntityDarkBG)]
    "
  >
    <div class="flex flex-col justify-center items-center pt-5 pb-6">
      <svg v-if="theme === 'flat'" class="mb-3 w-13 h-11" xmlns="http://www.w3.org/2000/svg" width="50.732" height="44.391" viewBox="0 0 50.732 44.391">
        <path class="fill-[var(--NovikGray)]" d="M50.732,43.1V71.635a4.757,4.757,0,0,1-4.756,4.756H4.756A4.757,4.757,0,0,1,0,71.635V43.1a4.757,4.757,0,0,1,4.756-4.756h8.72l1.219-3.26A4.75,4.75,0,0,1,19.144,32H31.579a4.75,4.75,0,0,1,4.449,3.082l1.229,3.26h8.72A4.757,4.757,0,0,1,50.732,43.1ZM37.257,57.366a11.89,11.89,0,1,0-11.89,11.89A11.9,11.9,0,0,0,37.257,57.366Zm-3.171,0a8.72,8.72,0,1,1-8.72-8.72A8.732,8.732,0,0,1,34.086,57.366Z" transform="translate(0 -32)"/>
      </svg>
      <svg v-else class="mb-3 w-32 h-24" xmlns="http://www.w3.org/2000/svg" width="127.534" height="89.273" viewBox="0 0 127.534 89.273">
        <path class="fill-[var(--NovikLightGray)]" d="M107.128,70.778A19.153,19.153,0,0,0,89.273,44.753a19.032,19.032,0,0,0-10.621,3.228,31.886,31.886,0,0,0-59.522,15.9c0,.538.02,1.076.04,1.614A28.7,28.7,0,0,0,28.7,121.273h73.332a25.505,25.505,0,0,0,5.1-50.5ZM78.393,83.013H65.361v22.318a3.2,3.2,0,0,1-3.188,3.188H52.608a3.2,3.2,0,0,1-3.188-3.188V83.013H36.387a3.182,3.182,0,0,1-2.252-5.44l21-21a3.2,3.2,0,0,1,4.5,0l21,21a3.187,3.187,0,0,1-2.252,5.44Z" transform="translate(0 -32)"/>
      </svg>

      <slot></slot>
    </div>
    <input
      id="dropzone-file"
      type="file"
      class="hidden"
      @input="imageUploaded"
      :accept="accept ?? '*'"
    />
  </label>
</template>

<script>
export default {
  name: 'Upload',

  props: {
    modelValue: {},
    theme: String,
    accept: String
  },

  emits: [
    'update:modelValue'
  ],

  methods: {
    imageUploaded(e) {
      this.$emit('update:modelValue', e.target.files[0])
    }
  }
}
</script>
