<template>
  <div
    class="
      py-2
      px-3
      border
      border-[var(--EntityBorder)]
      rounded
      w-max"
    :class="removable ? 'group text-white bg-[var(--NovikGray)] cursor-pointer' : 'text-[var(--NovikGray)] bg-white'"
  >
    <span>{{ tag }}</span>
    <span v-show="removable" class="pl-2 inline-block invisible group-hover:visible" @click="$emit('removeAction')">
      <svg class="fill-white" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 14 14">
        <path
          id="path"
          d="M19,6.4,17.6,5,12,10.6,6.4,5,5,6.4,10.6,12,5,17.6,6.4,19,12,13.4,17.6,19,19,17.6,13.4,12Z"
          transform="translate(-5 -5)"
          fill-rule="evenodd"
        />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Tag',

  props: {
    tag: String|Number,
    removable: Boolean
  },

  emits: ['removeAction'],
}
</script>
