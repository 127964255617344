<template>
  <div id="login-page" class="flex flex-col h-screen items-center justify-center bg-[var(--NovikLightGreen)]">
    <img class="w-72 h-32 mb-20" src="/src/assets/img/novik-logo.svg" alt="logo">

    <div v-if="error" class="mb-9">
      <p class="text-[var(--NovikRed)]">{{ $t('components.validations.general-error') }}</p>
      <p class="text-[var(--NovikRed)]">{{ $t(error) }}</p>
    </div>

    <form @submit.prevent="login" class="w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/4 xl:w-1/5">
      <div class="mb-5">
        <label for="email" class="block text-sm font-medium text-gray-700">{{ $t('general.email') }}</label>
        <v-input
          type="text"
          name="email"
          v-model="formData.email"
          autocomplete="email"
          :required="true"
        />
      </div>

      <div class="mb-9">
        <label for="password" class="block text-sm font-medium text-gray-700">{{ $t('general.password') }}</label>
        <v-input
          type="password"
          name="password"
          v-model="formData.password"
          autocomplete="current-password"
          :required="true"
          placeholder="*******************"
        />
      </div>

      <div>
        <v-button theme="gray" class="uppercase font-bold flex justify-center" :disabled="loading">
          <v-loader v-if="loading" :size="24" class="!m-0" />
          <span v-else>{{ $t('routes.Login') }}</span>
        </v-button>
      </div>
    </form>

    <small class="mt-14 underline text-[var(--NovikGray)]">
      <router-link
        :to="{ name: 'resetEmail' }"
      >
        {{ $t('components.password.forgot') }}?
      </router-link>
    </small>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Input from '/src/components/functionality/Input.vue'
import Button from '/src/components/functionality/Button.vue'
import Loader from '/src/components/partials/Loader.vue'

export default {
  name: 'Login',

  components: {
    'v-input': Input,
    'v-button': Button,
    'v-loader': Loader,
  },

  data() {
    return {
      error: null,
      formData: {
        email: null,
        password: null
      }
    }
  },

  inject: {
    isLoggedIn: {
      from: 'isLoggedIn'
    }
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({
        name: 'Dashboard'
      })
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
    }),
  },

  methods: {
    login() {
      this.$store.dispatch('AuthService/login', this.formData)
        .then(() => {
          this.$router.push({
            name: 'Dashboard'
          })
        })
        .catch((error) => {
          this.error = error.response.data.status.message
        })
    }
  }
}
</script>
<script setup>
</script>
