<template>
  <table class="w-full">
    <thead>
    <tr>
      <th class="text-left" v-for="key in columns">
        {{ key }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="entry in rowDataLimited">
      <td v-for="key in columns">
        {{ entry[key] }}
      </td>
    </tr>
    </tbody>
  </table>

  <div class="mt-4 w-full flex flex-wrap justify-center items-center gap-3">
    <v-pagination
      v-model="startRow"
      :theme="paginationOptions.theme"
      :rowsPerPage="modelValue"
      :rows="rowData.length"
      :options="paginationOptions"
    />
    <div v-if="paginationOptions.perPageSelector" class="w-40 h-10">
      <v-row-amount-selector
        v-model="rowsModel"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '/src/components/partials/Pagination.vue'
import RowAmountSelector from '/src/components/partials/RowAmountSelector.vue'

export default {
  name: 'Table',

  components: {
    'v-pagination': Pagination,
    'v-row-amount-selector': RowAmountSelector
  },

  data() {
    return {
      startRow: this.startingRow ?? 0,
      rowsModel: 10,
    }
  },

  props: {
    rowData: Array,
    columns: Array,
    modelValue: Number,
    startingRow: Number,
    paginationOptions: {}
  },

  mounted() {
    this.rowsModel = this.modelValue
  },

  computed: {
    rowDataLimited() {
      return this.rowData.slice(this.startRow, this.startRow + Number(this.modelValue))
    }
  },

  watch: {
    rowsModel: {
      handler() {
        this.$emit('update:modelValue', this.rowsModel)
      }
    }
  }
}
</script>
