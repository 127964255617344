<template>
  <div class="m-5">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="size ?? 50" :height="size ?? 50" viewBox="0 50 347.5 347.5" class="animate-spin">
      <defs>
        <clipPath id="clip-path">
          <path id="Path_1172" data-name="Path 1172" d="M8600-1275.809v245.246l156.534,156.534h186.79v-395.964Z" transform="translate(-7807 1438)" fill="none"/>
        </clipPath>
      </defs>
      <g id="Path_1180" data-name="Path 1180" transform="translate(0 49.809)" fill="none">
        <path d="M 174 20 C 89.08413696289062 20 20 89.08413696289062 20 174 C 20 258.9158935546875 89.08413696289062 328 174 328 C 258.9158935546875 328 328 258.9158935546875 328 174 C 328 89.08413696289062 258.9158935546875 20 174 20 M 174 0 C 270.0975036621094 0 348 77.90243530273438 348 174 C 348 270.0975036621094 270.0975036621094 348 174 348 C 77.90243530273438 348 0 270.0975036621094 0 174 C 0 77.90243530273438 77.90243530273438 0 174 0 Z" stroke="none" class="fill-[var(--EntityDarkBG)]"/>
      </g>
      <g id="Mask_Group_5" data-name="Mask Group 5" transform="translate(-619 -162.191)" clip-path="url(#clip-path)">
        <g id="Path_1179" data-name="Path 1179" transform="translate(619 212)" fill="none">
          <path d="M 174 20 C 89.08413696289062 20 20 89.08413696289062 20 174 C 20 258.9158935546875 89.08413696289062 328 174 328 C 258.9158935546875 328 328 258.9158935546875 328 174 C 328 89.08413696289062 258.9158935546875 20 174 20 M 174 0 C 270.0975036621094 0 348 77.90243530273438 348 174 C 348 270.0975036621094 270.0975036621094 348 174 348 C 77.90243530273438 348 0 270.0975036621094 0 174 C 0 77.90243530273438 77.90243530273438 0 174 0 Z" stroke="none" class="fill-[var(--NovikGreen)]"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    size: Number
  }
}
</script>
