<template>
  <div class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 mx-auto md:mx-0 flex flex-col">
      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="role-name" class="block text-sm">{{ $t('components.role') }} {{ $t('general.name') }}</label>
        <v-input
          type="text"
          name="role-name"
          v-model="role.name"
          autocomplete="off"
          :debounced="true"
          @update:modelValue="commitData"
        />
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="description" class="block text-sm">{{ $t('general.description') }}</label>
        <v-input
          type="text"
          name="description"
          v-model="role.description"
          autocomplete="off"
          :debounced="true"
          @update:modelValue="commitData"
        />
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <div
          v-if="permissionsLoaded"
          v-for="(group, index) in activePermissions"
        >
          <p class="text-sm">{{ index.toLowerCase() }}</p>
          <v-card>
            <div
              v-for="(value, permission) in group"
              class="
                flex
                justify-between
                p-2
                text-sm
                rounded
                hover:bg-[var(--EntityBG)]
              "
            >
              <v-toggleswitch
                v-model="activePermissions[index][permission]"
                :entry="{title: permission, entry: value}"
                :keyValue="['entry', 'title']"
                @update:modelValue="commitData"
              />
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Input from '/src/components/functionality/Input.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import Card from '/src/components/partials/Card.vue'

export default {
  name: 'Detail',

  components: {
    'v-input': Input,
    'v-toggleswitch': ToggleSwitch,
    'v-card': Card
  },

  data() {
    return {
      toast: this.$root.$refs.toast,
      role: [],
      allPermissions: {},
      activePermissions: {},
      permissionsLoaded: false
    }
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },

  methods: {
    fetchData() {
      this.$store.dispatch('RoleService/getRolesDetail', this.$route.params.id)
        .then(data => {
          this.role = data
        })

      this.$store.dispatch('RoleService/getPermissions')
        .then(data => {
          data.forEach(entry => {
            const matches = entry.match(/(\w*)\.(.*)/)

            if (!this.allPermissions.DEFAULT) {
              this.allPermissions.DEFAULT = []
            }

            if (matches === null) {
              this.allPermissions.DEFAULT.push(entry)
              return
            }

            if (!this.allPermissions[matches[1].toUpperCase()]) {
              this.allPermissions[matches[1].toUpperCase()] = []
            }

            this.allPermissions[matches[1].toUpperCase()].push(matches[2])
          })

          this.$store.dispatch('RoleService/getPermissionsByRoleId', this.$route.params.id)
            .then(data => {
              Object.keys(this.allPermissions).forEach(group => {
                if (!this.activePermissions[group]) {
                  this.activePermissions[group] = {}
                }

                this.allPermissions[group].forEach(permission => {
                  if (!this.activePermissions[group][permission]) {
                    this.activePermissions[group][permission] = false
                  }

                  this.activePermissions[group][permission] = !!data.permissions[group]?.includes(permission)
                })
              })

              this.permissionsLoaded = true
            })
        })
    },

    commitData() {
      this.role.permissions = {}
      Object.keys(this.activePermissions).forEach(group => {
        Object.keys(this.activePermissions[group]).forEach(permission => {
          if (this.activePermissions[group][permission]) {
            this.role.permissions[permission] = true
          }
        })
      })

      this.$store.dispatch('RoleService/updateRole', this.role)
        .then((response) => {
          this.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })
        }).catch((error) => {
        this.toast.make({
          'status': 'error',
          'text': error.status?.message ?? error,
          'timer': false
        })
      })
    }
  }
}
</script>
