<template>
  <template v-if="closable">
    <div
      class="
        flex
        flex-col
        bg-white
        rounded
        shadow-md
        mt-1
        mb-5
      "
      :class="extraClasses"
    >
      <div
        class="flex border-[var(--EntityBG)]"
        :class="{
            'border-b-2': open
          }
        "
      >
        <div class="border-r-2 border-[var(--EntityBG)]">
          <v-icon-button
            theme="flat"
            class="!w-max rounded-none"
            :icon="open ? 'icon-visible' : 'icon-invisible'"
            @click="open = !open"
          />
        </div>

        <div v-if="label" class="flex items-center text-sm text-[var(--NovikGray)] px-3.5">
          <span>
            {{ label }}
          </span>
        </div>
      </div>
      <div v-if="open" class="p-4">
        <slot />
      </div>
    </div>
  </template>

  <div
    v-else
    class="
      bg-white
      rounded
      shadow-md
      p-4
      mt-1
      mb-9
    "
    :class="extraClasses"
  >
    <slot />
  </div>
</template>

<script>
import IconButton from '/src/components/functionality/IconButton.vue'

export default {
  name: 'Card',

  components: {
    'v-icon-button': IconButton
  },

  data() {
    return {
      open: true
    }
  },

  props: {
    label: String,
    closable: Boolean,
    extraClasses: ''
  }
}
</script>
