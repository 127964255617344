<template>
  <template v-if="isActive">
    <div class="mb-5 text-[var(--NovikGray)]">
      <p class="text-[var(--NovikGray)]">{{ $t('campaign.add-code') }}</p>
      <v-card>
        <div class="mb-9 text-[var(--NovikGray)]">
          <label for="campaign_code" class="w-full">
            {{ $t('campaign.code') }}
          </label>
          <v-input
            name="campaign_code"
            v-model="code.code"
            :debounced="false"
          />
        </div>

        <div
          class="
            p-2
            rounded
            hover:bg-[var(--EntityBG)]
            transition-[background-color]
            mb-5
            last:mb-0
          "
        >
          <div class="flex justify-between">
            <v-toggleswitch
              v-model="code.isUnique"
              :entry="{name: $t('general.is-unique'), value: false}"
              :keyValue="['value', 'name']"
              :debounced="false"
            />
          </div>
        </div>

        <div class="mb-9 text-[var(--NovikGray)]">
          <label class="w-full">
            {{ $t(`campaign.${productMapping}`) }}
          </label>
          <v-input
            v-if="
              productMapping === 'price' ||
              productMapping === 'points' ||
              productMapping === 'cashback'
            "
            v-model="code.gift"
            :debounced="true"
            :placeholder="productMapping"
            type="number"
            class="w-full"
          />
          <v-select-box
            v-if="productMapping === 'gift' && !!giftableProducts.length"
            v-model="code.gift"
            :options="giftableProducts"
            :keyValue="['id', 'name']"
            :extraValues="['article_number']"
            theme="white"
            :searchable="true"
            class="w-full"
          />
        </div>

        <div
          class="
            p-2
            rounded
            hover:bg-[var(--EntityBG)]
            transition-[background-color]
            mb-5
            last:mb-0
          "
        >
          <div class="flex justify-between">
            <v-toggleswitch
              v-model="code.isRetailer"
              :entry="{name: $t('general.is-retailer'), value: false}"
              :keyValue="['value', 'name']"
              :debounced="false"
            />
          </div>
        </div>

        <div class="mb-9">
          <v-multi-select
            v-if="code.isRetailer"
            name="Retailers"
            v-model="code.retailers"
            :entries="campaignRetailers"
            :searchable="true"
            :keyValue="['id', 'name']"
            :placeholder="$t('general.multiselect')"
            :hide-tags="true"
          />
        </div>

        <div class="flex justify-end items-center">
          <v-button
            class="!w-max"
            @clicked="this.$emit(
              'commitData',
              {
                endpoint: '/codes',
                data: {
                  code: this.code
                }
              }
            )"
            theme="gray"
            :disabled="codeFieldsFilled"
          >
            {{ $t('general.add') }}
          </v-button>
        </div>
      </v-card>

      <div v-if="!!campaign.codes">
        <p class="text-[var(--NovikGray)]">{{ $t('campaign.codes') }}</p>
        <v-card>
          <v-add-list
            v-model="campaign.codes"
            :add="false"
            :remove="true"
            :key-value="['id', 'code']"
          />
        </v-card>
      </div>
    </div>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Button from '/src/components/functionality/Button.vue'
import Card from '/src/components/partials/Card.vue'
import Input from '/src/components/functionality/Input.vue'
import MultiSelect from '/src/components/functionality/MultiSelect.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import AddList from '/src/components/functionality/AddList.vue'

export default {
  name: 'Code',

  components: {
    'v-add-list': AddList,
    'v-toggleswitch': ToggleSwitch,
    'v-multi-select': MultiSelect,
    'v-select-box': SelectBox,
    'v-input': Input,
    'v-button': Button,
    'v-card': Card,
  },

  data() {
    return {
      code: {
        slug: '',
        code: '',
        isUnique: false,
        gift: '',
        isRetailer: false,
        retailers: []
      }
    }
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    codeFieldsFilled() {
      return false
    },

    requiredFields() {
      return [
        !this.nextDisabled
      ]
    }
  },

  methods: Step.methods,

  watch: Step.watch
}
</script>
