import Logout from '/src/views/login/Logout.vue'

import Login from '/src/views/login/Login.vue'
import ResetEmail from '/src/views/login/ResetEmail.vue'
import ResetPassword from '/src/views/login/ResetPassword.vue'
import ResetSuccess from '/src/views/login/ResetSuccess.vue'

import Profile from '/src/views/accounts/Profile.vue'
import Testing from '/src/views/testing/Testing.vue'

import Dashboard from '/src/views/Dashboard.vue'

import Products from '/src/views/products/Products.vue'
import ProductsAdd from '/src/views/products/product/Add.vue'
import ProductsDetail from '/src/views/products/product/Detail.vue'
import SerialMasks from '/src/views/products/SerialMasks.vue'
import SerialMasksDetail from '/src/views/products/serialMasks/Detail.vue'

import Campaigns from '/src/views/campaigns/Campaigns.vue'
import CampaignsAdd from '/src/views/campaigns/campaign/Add.vue'
import CampaignsDetail from '/src/views/campaigns/campaign/Detail.vue'

import Retailers from '/src/views/retailers/Retailers.vue'
import RetailersAdd from '/src/views/retailers/retailer/Add.vue'
import RetailersDetail from '/src/views/retailers/retailer/Detail.vue'

import Claims from '/src/views/claims/Claims.vue'
import ClaimsDetail from '/src/views/claims/claim/Detail.vue'

import Statistics from '/src/views/statistics/Statistics.vue'

import Users from '/src/views/accounts/Users.vue'
import UserAdd from '/src/views/accounts/user/Add.vue'
import UserDetail from '/src/views/accounts/user/Detail.vue'
import Roles from '/src/views/accounts/Roles.vue'
import RoleDetail from '/src/views/accounts/role/Detail.vue'
import RoleAdd from '/src/views/accounts/role/Add.vue'

import Import from '/src/views/csv/Import.vue'
import Export from '/src/views/csv/Export.vue'

import Settings from '/src/views/settings/Settings.vue'
import Domains from '/src/views/settings/Domains.vue'

import Error from '/src/views/Error.vue'

const routes = [
  // Redirect anything unknown back to root
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },

  // Redirect root to the login page
  {
    path: '/',
    redirect: '/login'
  },

  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      guard: null,
      title: 'Logout'
    },
    components: {
      Logout: Logout
    },
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    components: {
      Login: Login,
      resetEmail: ResetEmail,
      resetPassword: ResetPassword,
      resetSuccess: ResetSuccess
    },
    meta: {
      guard: null,
      title: 'Login'
    },
    children: [
      {
        path: '/reset-password',
        name: 'resetEmail',
        component: ResetEmail,
        meta: {
          guard: null,
          title: 'resetEmail'
        },
        children: [
          {
            path: ':token',
            name: 'resetPassword',
            component: ResetPassword,
            meta: {
              guard: null,
              title: 'resetPassword'
            }
          },
          {
            path: 'success',
            name: 'resetSuccess',
            component: ResetSuccess,
            meta: {
              guard: null,
              redirectFrom: 'resetPassword',
              title: 'resetSuccess'
            }
          }
        ]
      }
    ]
  },

  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    components: {
      Profile: Profile,
      Testing: Testing,
    },
    meta: {
      requiresAuth: true,
      guard: null,
      title: 'Profile'
    },
    exclude: true,
    children: [
      {
        path: '/testing',
        name: 'Testing',
        component: Testing,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'Testing',
        },
        showInMenu: true,
        components: {
          Testing: Testing,
        },
      },
    ]
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      guard: null,
      title: 'Dashboard',
    },
    showInMenu: true,
  },

  {
    path: '/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    meta: {
      requiresAuth: true,
      guard: 'campaigns.index',
      title: 'Campaigns',
    },
    showInMenu: true,
    components: {
      Campaigns: Campaigns,
      CampaignsAdd: CampaignsAdd,
      CampaignsDetail: CampaignsDetail
    },
    children: [
      {
        path: ':id',
        name: 'CampaignsDetail',
        component: CampaignsDetail,
        meta: {
          requiresAuth: true,
          guard: 'campaigns.show',
          title: 'CampaignsDetail',
        },
        exclude: true
      },
      {
        path: 'create',
        name: 'CampaignsAdd',
        component: CampaignsAdd,
        meta: {
          requiresAuth: true,
          guard: 'campaigns.store',
          title: 'CampaignsAdd',
        },
        exclude: true
      }
    ]
  },

  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true,
      guard: 'products.index',
      title: 'Products',
    },
    showInMenu: true,
    components: {
      Products: Products,
      ProductsAdd: ProductsAdd,
      ProductsDetail: ProductsDetail,
      SerialMasks: SerialMasks,
      SerialMasksDetail: SerialMasksDetail
    },
    children: [
      {
        path: ':id',
        name: 'ProductsDetail',
        component: ProductsDetail,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'ProductsDetail'
        },
        exclude: true
      },
      {
        path: 'create',
        name: 'ProductsAdd',
        component: ProductsAdd,
        meta: {
          requiresAuth: true,
          guard: 'products.store',
          title: 'ProductsAdd',
        },
        exclude: true
      },
      {
        path: 'serial-masks',
        name: 'SerialMasks',
        component: SerialMasks,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'SerialMasks'
        },
        children: [
          {
            path: ':id',
            name: 'SerialMasksDetail',
            component: SerialMasksDetail,
            meta: {
              requiresAuth: true,
              guard: null,
              title: 'SerialMasksDetail'
            },
            exclude: true
          },
        ]
      },
    ]
  },

  {
    path: '/retailers',
    name: 'Retailers',
    component: Retailers,
    meta: {
      requiresAuth: true,
      guard: null,
      title: 'Retailers',
    },
    showInMenu: true,
    components: {
      Retailers: Retailers,
      RetailersAdd: RetailersAdd,
      RetailersDetail: RetailersDetail,
    },
    children: [
      {
        path: 'create',
        name: 'RetailersAdd',
        component: RetailersAdd,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'RetailersAdd',
        },
        exclude: true
      },
      {
        path: ':id',
        name: 'RetailersDetail',
        component: RetailersDetail,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'RetailersDetail',
        },
        exclude: true
      },
    ]
  },

  {
    path: '/claims',
    name: 'Claims',
    component: Claims,
    meta: {
      requiresAuth: true,
      guard: null,
      title: 'Claims',
    },
    showInMenu: true,
    components: {
      Claims: Claims,
      ClaimsDetail: ClaimsDetail
    },
    children: [
      {
        path: ':id',
        name: 'ClaimsDetail',
        component: ClaimsDetail,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'ClaimsDetail',
        },
        exclude: true
      },
    ]
  },

  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      requiresAuth: true,
      guard: null,
      title: 'Statistics',
    },
    showInMenu: true,
    components: {
      Statistics: Statistics
    },
  },

  {
    path: '/account',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      guard: 'users.index',
      title: 'Accounts',
    },
    showInMenu: true,
    components: {
      Users: Users,
      UsersDetail: UserDetail,
      UsersAdd: UserAdd,
      Roles: Roles,
      RolesDetail: RoleDetail,
      RolesAdd: RoleAdd
    },
    children: [
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: {
          requiresAuth: true,
          guard: 'users.index',
          title: 'Users',
        },
        exclude: true,
        children: [
          {
            path: ':id',
            name: 'UsersDetail',
            component: UserDetail,
            meta: {
              requiresAuth: true,
              guard: 'users.show',
              title: 'UsersDetail'
            },
            exclude: true
          },
          {
            path: 'create',
            name: 'UsersAdd',
            component: UserAdd,
            meta: {
              requiresAuth: true,
              guard: 'users.store',
              title: 'UsersAdd',
            },
            exclude: true
          }
        ]
      },

      {
        path: 'roles',
        name: 'Roles',
        component: Roles,
        meta: {
          requiresAuth: true,
          guard: 'roles.index',
          title: 'Roles',
        },
        children: [
          {
            path: ':id',
            name: 'RolesDetail',
            component: RoleDetail,
            meta: {
              requiresAuth: true,
              guard: 'roles.show',
              title: 'RolesDetail',
            },
            exclude: true
          },
          {
            path: 'add',
            name: 'RolesAdd',
            component: RoleAdd,
            meta: {
              requiresAuth: true,
              guard: 'roles.store',
              title: 'RolesAdd',
            },
            exclude: true
          }
        ]
      }
    ]
  },

  {
    path: '/import',
    name: 'Import',
    component: Import,
    meta: {
      requiresAuth: true,
      guard: null,
      title: 'Import',
    },
    showInMenu: true,
    components: {
      Import: Import,
      Export: Export,
    },
    children: [
      {
        path: 'export',
        name: 'Export',
        component: Export,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'Export',
        }
      }
    ]
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      guard: 'general.setting.index',
      title: 'Settings',
    },
    showInMenu: true,
    components: {
      Settings: Settings,
      Domains: Domains,
    },
    children: [
      {
        path: 'domains',
        name: 'Domains',
        component: Domains,
        meta: {
          requiresAuth: true,
          guard: null,
          title: 'Domains',
        }
      }
    ]
  },

  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      guard: null,
      title: 'Error',
    },
    components: {
      Error: Error,
    }
  }
]

export default routes
