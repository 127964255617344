<template>
  <template v-if="isActive">
    <p class="text-[var(--NovikGray)]">{{ $t('campaign.texts') }}</p>
    <v-card>
      <v-button-row
        v-model="activeLanguage"
        :options="campaign.languages"
        :keyValue="['id', 'name']"
        :disabled="!languageChangeable"
        :class="{'mb-5': activeLanguage > 0}"
      />
      <template
        v-if="!!textsPopulated"
        v-for="language in campaign.languages"
      >
        <template v-if="language.id === activeLanguage" v-for="text in campaignTexts">
          <p class="text-sm text-[var(--NovikGray)]">{{ $t(`campaign.texts.${text.handler}`) }}</p>
          <v-card
            extra-classes="
              !bg-[var(--EntityBG)]
              shadow-none
            "
          >
            <div class="mb-9 text-[var(--NovikGray)]">
              <label
                :for="`campaign_text_${text.handler.toLowerCase()}_${language.code.toLowerCase()}`"
                class="w-full text-sm"
              >
                {{ $t(`campaign.texts.${text.handler}.title`) }}
              </label>
              <v-input
                :name="`campaign_text_${text.handler.toLowerCase()}_${language.code.toLowerCase()}`"
                v-model="this.campaign.campaignText.filter(entry => {
                    return entry.id === text.id &&
                      entry.language.id === language.id
                  })[0].title
                "
                :debounced="true"
                @update:modelValue="commitData()"
                @input="languageChangeable = false"
              />
            </div>

            <div class="text-[var(--NovikGray)]">
              <p class="w-full text-sm">
                {{ $t(`campaign.texts.${text.handler}.content`) }}
              </p>
              <v-content-editor
                v-model="this.campaign.campaignText.filter(entry => {
                    return entry.id === text.id &&
                      entry.language.id === language.id
                  })[0].content
                "
                :debounced="true"
                @update:modelValue="commitData()"
                @input="languageChangeable = false"
              />
            </div>
          </v-card>
        </template>
      </template>
    </v-card>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Button from '/src/components/functionality/Button.vue'
import Card from '/src/components/partials/Card.vue'
import Input from '/src/components/functionality/Input.vue'
import ContentEditor from '/src/components/partials/TinyMCE.vue'
import ButtonRow from '/src/components/functionality/ButtonRow.vue'

export default {
  name: 'Texts',

  data() {
    return {
      textsPopulated: false,
      activeLanguage: 0,
      languageChangeable: true
    }
  },

  components: {
    'v-button-row': ButtonRow,
    'v-content-editor': ContentEditor,
    'v-button': Button,
    'v-card': Card,
    'v-input': Input,
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      return [true]
    }
  },

  methods: {
    commitData() {
      this.languageChangeable = true

      this.$emit(
        'commitData',
        {
          endpoint: '/campaign_text',
          data: {
            campaign_text: this.campaign.campaignText
          }
        }
      )
    },

    populateCampaignText() {
      this.campaign.languages?.forEach(language => {
        this.campaignTexts?.forEach(text => {
          const textData = this.campaign.campaignText.filter(entry => {
            return entry.id === text.id &&
              entry.language.id === language.id
          })

          if (!textData.length) {
            this.campaign.campaignText.push({
              id: text.id,
              title: '',
              content: '',
              language: {
                id: language.id
              }
            })
          }
        })
      })

      this.textsPopulated = true
    }
  },

  watch: {
    ...Step.watch,

    campaignTexts: {
      handler() {
        this.textsPopulated = false
        this.populateCampaignText()
      }
    },

    campaign: {
      handler() {
        this.textsPopulated = false
        this.populateCampaignText()
      },
      deep: true
    }
  }
}
</script>
