<template>
  <template v-if="isActive">
    <p class="text-[var(--NovikGray)]">{{ $t('campaign.products') }}</p>
    <v-card
      :closable="true"
    >
      <div
        :class="{
          'mb-2': !!campaign.products?.length
        }"
      >
        <v-multi-select
          name="Products"
          v-model="campaign.products"
          :entries="campaignProducts"
          :searchable="true"
          :keyValue="['id', 'name']"
          :extraValues="['article_number']"
          :placeholder="$t('general.multiselect')"
          :hide-tags="true"
          @update:modelValue="commitData()"
        />
      </div>

      <div
        v-for="(product, index) in campaign.products"
        class="
          flex
          justify-between
          items-center
          gap-x-2
          border-b
          border-[var(--EntityDarkBG)]
          last:border-0
          transition-all
          duration-300
          hover:bg-[var(--EntityBG)]
          p-2
          rounded
          text-[var(--NovikGray)]
        "
      >
        <p class="grow ml-2">
          {{ product.name }} ({{ product.article_number }})
        </p>
        <div>
          <v-input
            v-if="
              productMapping === 'price' ||
              productMapping === 'points' ||
              productMapping === 'cashback'
            "
            v-model="product.value"
            :debounced="true"
            :placeholder="productMapping"
            type="number"
            class="min-w-[16rem]"
            @update:modelValue="commitData()"
          />
          <v-select-box
            v-if="productMapping === 'gift' && !!giftableProducts.length"
            v-model="product.gift"
            :options="giftableProducts"
            :keyValue="['id', 'name']"
            :extraValues="['article_number']"
            theme="white"
            :searchable="true"
            class="min-w-[16rem]"
            @update:modelValue="commitData()"
          />
        </div>
        <div>
          <v-icon-button
            theme="flat"
            class="!w-max hover:!bg-[var(--EntityDarkBG)]"
            icon="icon-remove-entity"
            @click="this.campaign.products.splice(index, 1)"
          />
        </div>
      </div>
    </v-card>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Button from '/src/components/functionality/Button.vue'
import MultiSelect from '/src/components/functionality/MultiSelect.vue'
import Input from '/src/components/functionality/Input.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import Card from '/src/components/partials/Card.vue'
import IconButton from '/src/components/functionality/IconButton.vue'

export default {
  name: 'Products',

  components: {
    'v-icon-button': IconButton,
    'v-button': Button,
    'v-multi-select': MultiSelect,
    'v-input': Input,
    'v-select-box': SelectBox,
    'v-card': Card,
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      return [true]
    },
  },

  methods: {
    commitData() {
      this.$emit(
        'commitData',
        {
          endpoint: '/products',
          data: this.campaign.products
        }
      )
    },
  },

  watch: Step.watch
}
</script>
