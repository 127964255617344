import axios from '/src/store/utility/Request'

class AuthRepository {
  login(user) {
    return axios.post('login',{
        email: user.email,
        password: user.password
      }
    )
  }

  register(user) {
    return axios.post('signup',{
        username: user.username,
        email: user.email,
        password: user.password
      }
    )
  }

  forgetPassword (data) {
    return axios.post('forget-password', data)
  }

  resetPassword (data) {
    return axios.post('reset-password', data)
  }
}

export default new AuthRepository()
