class Radio {
  constructor() {
    return {
      type: 'radio',
      name: 'Radio',
      settings: {
        name: [],
        content: [],
        options: [],
        validations: []
      }
    }
  }
}

export default Radio
