<template>
  <div class="w-full bg-[var(--EntityDarkBG)] rounded-full" :class="size === 'large' ? 'h-2.5' : 'h-1'">
    <div class="progress-indicator rounded-full bg-[var(--NovikGreen)] h-full" :style="`width: ${this.progress}%`"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',

  props: {
    progress: Number,
    size: String
  }
}
</script>
