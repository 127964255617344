<template>
  <div
    class="
      menu
      fixed
      row-start-1
      bg-white
      row-end-7
      h-screen
      pb-10
      transition-[width]
      z-40
      overflow-y-auto
      overflow-x-hidden
      overscroll-none
    "
    :class="modelValue ? 'w-full lg:w-72' : 'menu-closed w-0 lg:w-16'"
  >
    <div class="bg-[var(--NovikGreen)] flex overflow-x-hidden cursor-pointer" @click="toggleMenu">
      <i class="icon-menu-bars text-2xl text-white mr-5 my-3 transition-[margin-left]" :class="modelValue ? 'ml-6 sm:ml-8' : 'ml-5'"></i>
      <span class="text-white self-center">{{ $t('components.menu') }}</span>
    </div>
    <div>
      <div class="py-5 border-b border-[var(--EntityDarkBG)] transition-[padding] h-24" :class="modelValue ? 'pl-9' : 'hidden lg:block pl-2.5'">
        <img v-if="modelValue" class="max-w-xs" width="146" height="56" src="/src/assets/img/novik-logo.svg" alt="logo">
        <img v-else width="45" height="56" src="/src/assets/img/novik-icon.svg" alt="logo">
      </div>

      <div class="border-b border-[var(--EntityDarkBG)] h-16 lg:h-12" :class="{hidden: !modelValue}">
        <v-brand-selector />
      </div>

      <v-menu-link
        v-if="isWindowMobile"
        :link="getProfileRoute()"
        :subMenu="subMenu"
        :extraClasses="modelValue ? 'pl-8' : 'hidden pl-4'"
        @clicked="handleMenuLinkClick"
      />

      <v-menu-link
        v-for="link in menuLinks"
        :link="link"
        :subMenu="subMenu"
        :extraClasses="modelValue ? 'pl-8' : 'hidden lg:flex pl-4'"
        @clicked="handleMenuLinkClick"
      />

      <v-menu-link
        v-if="isWindowMobile"
        :link="getLogoutRoute()"
        :extraClasses="modelValue ? 'mt-5 bg-[var(--EntityBG)] pl-8 border-t' : 'hidden pl-4'"
        @clicked="handleMenuLinkClick"
      />
    </div>
  </div>

  <v-navbar
    :menuOpen="modelValue"
    :link="$route.matched[0]"
    :subMenu="subMenu"
    @clicked="toggleMenu"
  />
</template>

<script>
import routes from '/src/router/routes'
import { routeInPermissions } from '/src/helper'

import MenuLink from '/src/components/menu/MenuLink.vue'
import BrandSelector from '/src/components/menu/BrandSelector.vue'
import Navbar from '/src/components/menu/Nav.vue'

export default {
  name: 'Menu',

  components: {
    'v-menu-link': MenuLink,
    'v-brand-selector': BrandSelector,
    'v-navbar': Navbar
  },

  data() {
    return {
      initialMenuState: this.currentUser?.settings.filter(group => group.group_id === 1)[0]
        .settings.filter(entry => entry.handler === 'MENU_OPEN')[0]
        .value
    }
  },

  props: {
    modelValue: Boolean
  },

  emits: [
    'update:modelValue'
  ],

  inject: {
    exactRouteName: {
      from: 'exactRouteName'
    },
    isWindowMobile: {
      from: 'isWindowMobile'
    },
    currentUser: {
      from: 'currentUser'
    }
  },

  mounted() {
    if (!!this.isWindowMobile) {
      this.$emit('update:modelValue', false)
    }
  },

  computed: {
    menuLinks() {
      return routes.filter(route => route.showInMenu).filter((collection) => {
        return routeInPermissions(collection, this.currentUser.permissions)
      })
    },

    subMenu() {
      if (this.$route.matched.length) {
        return this.$route.matched[0].children?.filter(child => !child.exclude)
          .filter((child) => routeInPermissions(child, this.currentUser.permissions))
      }

      return null
    }
  },

  methods: {
    getProfileRoute() {
      return routes.filter(route => route.name === 'Profile')[0]
    },

    getLogoutRoute() {
      return routes.filter(route => route.name === 'Logout')[0]
    },

    toggleMenu() {
      if (!this.isWindowMobile) {
        this.$store.dispatch('UserService/commitData', {
          id: this.currentUser.id,
          endpoint: '/settings',
          data: {
            id: 1,
            value: !this.modelValue
          }
        })

        this.initialMenuState = !this.modelValue
      }
      this.$emit('update:modelValue', !this.modelValue)
    },

    handleMenuLinkClick() {
      if (this.isWindowMobile) {
        this.$emit('update:modelValue', false)
      }
    }
  },

  watch: {
    isWindowMobile: {
      handler() {
        if (!!this.isWindowMobile) {
          this.$emit('update:modelValue', false)
        } else {
          this.$emit('update:modelValue', this.initialMenuState)
        }

      }
    }
  }
}
</script>
