<template>
  <span class="hidden tooltip object-center flex flex-col content-center justify-center absolute -mx-1 py-1 px-3 w-max rounded-lg text-white bg-[var(--NovikGray)]">
    <slot></slot>
    <div
      class="absolute w-2 h-2 self-center rotate-45 bg-[var(--NovikGray)]"
      :class="{
        '-bottom-1': location === 'top',
        '-right-1': location === 'left',
        '-left-1': location === 'right',
        '-top-1': location === 'bottom',
      }"
    ></div>
  </span>
</template>

<script>
export default {
  name: 'Tooltip',

  data() {
    return {
      height: 0,
      width: 0,
      linkedElement: ''
    }
  },

  props: {
    location: String
  },

  mounted() {
    this.linkedElement = this.$el.parentNode

    this.linkedElement.classList.add('relative')

    this.getTooltipHeight(this.$el)
    this.getTooltipWidth(this.$el)
  },

  methods: {
    toggleTooltip() {
      let tooltip = this.$el

      this.setTooltipPosition(tooltip)

      tooltip.classList.toggle('hidden')
    },

    setTooltipPosition(tooltip) {
      if (this.location === 'top') {
        tooltip.style.setProperty('left', `calc(${this.linkedElement.offsetWidth / 2}px - ${this.width / 2}px)`)
        tooltip.style.setProperty('top', `-${this.height + 16}px`)
      }
      else if (this.location === 'left') {
        tooltip.style.setProperty('top', `calc(${this.linkedElement.offsetHeight / 2}px - ${this.height / 2}px)`)
        tooltip.style.setProperty('left', `-${this.width + 16}px`)
      }
      else if (this.location === 'right') {
        tooltip.style.setProperty('top', `calc(${this.linkedElement.offsetHeight / 2}px - ${this.height / 2}px)`)
        tooltip.style.setProperty('right', `-${this.width + 16}px`)
      }
      else if (this.location === 'bottom') {
        tooltip.style.setProperty('left', `calc(${this.linkedElement.offsetWidth / 2}px - ${this.width / 2}px)`)
        tooltip.style.setProperty('bottom', `-${this.height + 16}px`)
      }
    },

    getTooltipWidth(tooltip) {
      tooltip.classList.toggle('invisible')
      tooltip.classList.toggle('hidden')

      this.width = tooltip.offsetWidth

      tooltip.classList.toggle('hidden')
      tooltip.classList.toggle('invisible')
    },

    getTooltipHeight(tooltip) {
      tooltip.classList.toggle('invisible')
      tooltip.classList.toggle('hidden')

      this.height = tooltip.offsetHeight

      tooltip.classList.toggle('hidden')
      tooltip.classList.toggle('invisible')
    },

    addListeners() {
      this.linkedElement.addEventListener('mouseover', () => { this.toggleTooltip() })
      this.linkedElement.addEventListener('mouseout', () => { this.toggleTooltip() })
    }
  },

  watch: {
    linkedElement: {
      handler() {
        this.addListeners()
      }
    }
  }
}
</script>
