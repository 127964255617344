import axios from '/src/store/utility/Request'

class RoleRepository {
  getRoles () {
    return axios.get('roles')
  }

  getRoleDetail( id ) {
    return axios.get(`roles/${id}`)
  }

  getPermissions(){
    return axios.get('permissions')
  }

  getPermissionsByRoleId( id ){
    return axios.get(`roles/${id}/permissions`)
  }

  addRole( data ) {
    return axios.post('roles', data)
  }

  updateRole( data ){
    return axios.put(`roles/${data.id}`, data)
  }

  deleteRole( id ){
    return axios.delete(`roles/${id}`)
  }
}

export default new RoleRepository()
