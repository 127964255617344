<template>
  <div class="h-16 px-2 grid grid-areas-table-header items-center gap-x-4 border-b border-[var(--EntityDarkBG)]">
    <div style="grid-area: search">
      <button
        class="
          flex
          justify-center
          items-center
          transition-[background-color]
          duration-300
          hover:bg-[var(--EntityDarkBG)]
          rounded-full
          w-12
          aspect-square
        "
        @click="this.searchbar.visible = !this.searchbar.visible"
        :aria-label="$t('general.search')"
      >
        <i class="icon-search text-2xl text-[var(--NovikGray)]" />
      </button>
    </div>

    <div style="grid-area: filters" class="flex items-center gap-x-4">
      <button
        class="
          flex
          justify-center
          items-center
          transition-[background-color]
          duration-300
          hover:bg-[var(--EntityDarkBG)]
          rounded-full
          w-12 aspect-square
        "
        aria-label="Filter settings"
        @click="this.$refs.filterModal.open()"
      >
        <i class="icon-filter text-2xl text-[var(--NovikGray)]" />
      </button>
      <p
        v-if="!!filter?.length"
        class="text-sm italic text-[var(--NovikGray)]"
      >
        {{ $t('general.filters-active') }}:
        <span
          v-if="!!filterTexts.length"
          v-for="(entry, index) in filter"
        >
          {{ filterTexts[index] }}<span v-if="index !== filter.length - 1">, </span>
        </span>
        <span v-else>
          {{ $t('general.none') }}
        </span>
      </p>

      <v-modal
        ref="filterModal"
        :modalHeader="$t('general.filter-settings')"
      >
        <slot name="filterOptions" />

        <div class="flex justify-between p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
          <v-toggleswitch
            v-model="sortDescending"
            :entry="{text: `${$t('general.toggle')} ${$t('general.sorting')}`, entry: false}"
            :keyValue="['entry', 'text']"
          />
        </div>
      </v-modal>
    </div>

    <div style="grid-area: results" v-if="!!results" class="hidden sm:block">
      <p class="text-sm italic text-[var(--NovikGray)]">
        {{ `${$t('general.results')}: ${results}` }}
      </p>
    </div>

    <div style="grid-area: delete" v-if="deletable">
      <button
        class="
          flex
          justify-center
          items-center
          transition-[background-color]
          duration-300
          hover:bg-[var(--EntityDarkBG)]
          rounded-full
          w-12 aspect-square
        "
        aria-label="Delete selection"
        :disabled="!deleteClause"
        @click="openDeleteModal(deleteClause)"
      >
        <i
          class="
            icon-remove-entity
            text-2xl
            text-[var(--NovikLightGray)]
            transition-colors
            duration-300
          "
          :class="{
            '!text-[var(--NovikGray)]': deleteClause
          }"
        />
      </button>
      <v-modal
        ref="deleteModal"
        :modalHeader="$t('general.delete-selection')"
      >
        <p>{{ $t('general.delete-confirmation', {'entity': entity.toLowerCase()}) }}</p>
        <div class="flex justify-end items-center">
          <a
            href="#"
            class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
            @click="this.$refs.deleteModal.close()">
            {{ $t('general.cancel') }}
          </a>
          <v-button
            class="!w-max uppercase"
            theme="gray"
            @click="$emit('deleteSelection')"
          >
            {{ $t('general.confirm') }}
          </v-button>
        </div>
      </v-modal>
    </div>
  </div>

  <div
    v-show="searchbar.visible"
    class="
      p-3
      bg-[var(--EntityDarkBG)]
    "
  >
    <v-input
      type="text"
      name="search"
      v-model="searchbar.request"
      :placeholder="`${$t('components.search', {subject: entity.toLowerCase()})}...`"
    />
  </div>
</template>

<script>
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import Input from '/src/components/functionality/Input.vue'
import Modal from '/src/components/partials/Modal.vue'
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'TableViewHeader',

  components: {
    'v-button': Button,
    'v-toggleswitch': ToggleSwitch,
    'v-modal': Modal,
    'v-input': Input
  },

  data() {
    return {
      sortDescending: this.modelValue
    }
  },

  props: {
    modelValue: Boolean,
    deletable: Boolean,
    deleteClause: Boolean,
    entity: String,
    filter: {},
    filterTexts: Array,
    searchbar: {},
    results: String | Number
  },

  emits: [
    'update:modelValue',
    'deleteSelection'
  ],

  methods: {
    openDeleteModal(clause) {
      if (clause) {
        this.$refs.deleteModal.open()
      } else {
        this.$root.$refs.toast.make({
          'status': 'error',
          'text': 'None selected.',
          'timer': 1000
        })
      }
    }
  },

  watch: {
    sortDescending: {
      handler() {
        this.$emit('update:modelValue', this.sortDescending)
      }
    }
  }
}
</script>
