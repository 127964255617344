<template>
  <div class="flex flex-col bg-white rounded-lg">
    <div class="flex border-b-2 border-[var(--EntityBG)]">
      <div class="border-r-2 border-[var(--EntityBG)]">
        <v-icon-button
          theme="flat"
          class="!w-max rounded-none"
          :icon="modelValue.visible ? 'icon-visible' : 'icon-invisible'"
          @click="modelValue.visible = !modelValue.visible"
        />
      </div>

      <div class="flex items-center flex-grow text-[var(--NovikGray)] border-r-2 border-[var(--EntityBG)] px-3.5">
        <span>
          {{ modelValue.name }} - {{ modelValue.settings.name.filter(entry => entry.id === activeLanguage)[0].value}}
        </span>
      </div>

      <div class="border-r-2 border-[var(--EntityBG)]">
        <v-icon-button
          theme="flat"
          class="!w-max rounded-none rotate-180"
          icon="icon-menu-brand-chevron"
          :disabled="order === 0"
          :title="$t('general.move-up')"
          @click="$emit('changeOrder', [order, true])"
        />
      </div>

      <div class="border-r-2 border-[var(--EntityBG)]">
        <v-icon-button
          theme="flat"
          class="!w-max rounded-none"
          icon="icon-menu-brand-chevron"
          :disabled="order === lastIndex"
          :title="$t('general.move-down')"
          @click="$emit('changeOrder', [order, false])"
        />
      </div>

      <div>
        <v-icon-button
          theme="flat"
          class="!w-max rounded-none"
          icon="icon-remove-entity"
          @click="$emit('removeEntry')"
        />
      </div>
    </div>

    <div v-if="modelValue.visible" class="flex flex-col p-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import IconButton from '/src/components/functionality/IconButton.vue'

export default {
  name: 'Panel',

  components: {
    'v-icon-button': IconButton
  },

  props: {
    modelValue: {},
    order: Number,
    lastIndex: Number
  },

  inject: {
    activeLanguage: {
      from: 'activeLanguage'
    }
  },

  emits: [
    'changeOrder',
    'removeEntry'
  ]
}
</script>
