<template>
  <div class="flex justify-center w-full overflow-x-auto">
    <template v-for="step in steps">
      <div class="flex flex-col justify-center">
        <a
          href="#"
          class="
            rounded-full
            cursor-pointer
            border-4
            border-[var(--NovikGreen)]
            bg-white
            w-11
            h-11
            text-[var(--NovikGray)]
            flex
            items-center
            justify-center"
          :class="{
            '!bg-[var(--NovikLightGreen)] border-[var(--NovikLightGreen)]': theme === 'light',

            '!bg-[var(--NovikGreen)] text-white stroke-white':
              step.stepNum === modelValue || step.finished,
            '!text-[var(--NovikDarkGreen)] !stroke-[var(--NovikDarkGreen)]':
              (step.stepNum === modelValue || step.finished) && theme === 'light',

            '!bg-[var(--EntityBG)] !text-[var(--NovikLightGray)] border-[var(--EntityDarkBG)] !cursor-default':
              step.disabled
          }"
          @click="setActiveStep(step)"
          :title="$t('components.tracker.step', {'step_num': step.stepNum})"
        >
          <svg
            v-if="step.finished && step.stepNum !== modelValue"
            xmlns="http://www.w3.org/2000/svg"
            class="fill-none"
            width="14.712"
            height="10.513"
            viewBox="0 0 14.712 10.513"
          >
            <path
              id="Path_293"
              data-name="Path 293"
              d="M1187.471,717.879l4.9,4.833,8.407-8.4"
              transform="translate(-1186.769 -713.608)"
              stroke-width="2"
            />
          </svg>
          <template v-else>
            {{ step.stepNum }}
          </template>
        </a>
        <span
          v-if="!noText"
          class="text-center -mx-5 mt-3"
          :class="{
            'text-[var(--NovikLightGray)]': step.disabled
          }"
        >
            {{ $t('components.tracker.step', {'step_num': step.stepNum}) }}
        </span>
      </div>

      <div
        v-if="step.stepNum !== steps.length"
        class="h-1 w-full min-w-[2rem] max-w-[15rem] mt-5 rounded-full bg-[var(--NovikGreen)]"
        :class="{
          '!bg-[var(--NovikLightGreen)]': theme === 'light',
        }"
      ></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Tracker',

  props: {
    modelValue: Number,
    theme: String,
    steps: Array,
    noText: Boolean
  },

  methods: {
    setActiveStep(step) {
      if (!step.disabled) {
        this.$router.push({
          path: this.$route.path,
          query: {
            step: step.stepNum
          }
        })
        this.$emit('update:modelValue', step.stepNum)
      }
    }
  }
}
</script>
