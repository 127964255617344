import UserRepository from '/src/store/repositories/UserRepository'
import router from '/src/router'
import { setLoading } from '/src/helper.js'

export const UserService = {
  namespaced: true,

  actions: {
    getTranslations ({ state }, locale) {
      setLoading(state, 'getTranslations', true)
      return UserRepository.getTranslations(locale)
        .then(response => {
          setLoading(state, 'getTranslations', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getTranslations', false)
          window.error = error
          throw router.push({ name: 'Error'})
        })
    },

    users ({ state }) {
      setLoading(state, 'users', true)
      return UserRepository.getUsers()
        .then(response => {
          setLoading(state, 'users', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'users', false)
          throw error
        })
    },

    getUser ({ state }, userid) {
      setLoading(state, 'getUser', true)
      return UserRepository.getUserDetail(userid)
        .then(response => {
          setLoading(state, 'getUser', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getUser', false)
          throw error
        })
    },

    getCurrentUser ({ state }) {
      setLoading(state, 'getCurrentUser', true)
      return UserRepository.loggedUser()
        .then(async response => {
          if (response.data.status.code !== 200) {
            throw response
          } else {
            try {
              // Try to get the permissions from the token by:
              // - Splitting the data and only using what we need
              // - Replacing both '-' and '_' delimiters for '+' and '/' respectively
              // - Letting Javascript's AtoB method decode the string
              // - Returning only the scopes as the permissions
              // If this fails, throw and handle error
              response.data.data.permissions = JSON.parse(
                window.atob(
                  localStorage.getItem('token')
                    .split('.')[1]
                    .replace('-', '+')
                    .replace('_', '/')
                )
              ).scopes
            }
            catch {
              throw response
            }

            setLoading(state, 'getCurrentUser', false)

            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getCurrentUser', false)
          window.error = error
          throw router.push({ name: 'Error'})
        })
    },

    uploadAvatar ({ state }, data) {
      setLoading(state, 'uploadAvatar', true)
      return UserRepository.uploadAvatar(data)
        .then(response => {
          setLoading(state, 'uploadAvatar', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response
        })
        .catch(error => {
          setLoading(state, 'uploadAvatar', false)
          throw error
        })
    },

    removeAvatar({ state }) {
      return UserRepository.removeAvatar()
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }

          return response
        })
        .catch(error => {
          throw error
        })
    },

    commitData({ state }, data) {
      return UserRepository.commitData(data)
        .then((response) => {
          if (response.data.status.code === 200 || response.data.status.code === 207) {
            return response
          } else {
            throw response
          }
        })
        .catch(error => {
          throw error
        })
    },

    deleteUser ({ state }, user) {
      setLoading(state, 'deleteUser', true)
      return UserRepository.deleteUser(user)
        .then(response => {
          setLoading(state, 'deleteUser', false)
          if (response.data.status.code !== 200) {
            throw response
          }
          return response
        })
        .catch(error => {
          setLoading(state, 'deleteUser', false)
          throw error
        })
    },

    verifyEmail ({ state }, email) {
      return UserRepository.verifyEmail(email)
        .then(response => {
          throw response
        })
        .catch(error => {
          if (error.response.status === 404) {
            return error.response
          }
          throw error
        })
    },

    addUser ({ state }, data) {
      setLoading(state, 'addUser', true)
      return UserRepository.addUser(data)
        .then(response => {
          setLoading(state, 'addUser', false)
          if (response.data.status.code !== 201) {
            throw response
          }
          return response
        })
        .catch(error => {
          setLoading(state, 'addUser', false)
          throw error
        })
    },

    getLanguages({ state }) {
      setLoading(state, 'getLanguages', true)
      return UserRepository.getLanguages()
        .then(response => {
          setLoading(state, 'getLanguages', false)
          if (response.data.status.code !== 200) {
            throw response
          }
          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getLanguages', false)
          throw error
        })
    }
  }
}
