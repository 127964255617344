<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full flex flex-col bg-[var(--EntityBG)]">
    <div
      v-if="domains && countries"
      class="
        mt-20
        px-4
        md:px-0
        w-full
        sm:w-2/3
        md:w-3/5
        mx-auto
        md:mx-0
        flex
        flex-col
        self-center
      "
    >
      <div v-for="domain in domains">
        <a
          :href="`https://${domain.name}`"
          target="_blank"
          class="
            text-[var(--NovikGray)]
            link-external
            hover:text-[var(--NovikDarkGray)]
            transition-colors
            duration-300
          "
        >{{ domain.name }}</a>
        <v-card>
          <small class="text-[var(--NovikGray)]">{{ $t('components.select-countries-for-domain') }}</small>
          <v-multi-select
            name="countries"
            v-model="domain.countries"
            :entries="countries"
            :searchable="true"
            :keyValue="['id', 'country']"
            :placeholder="$t('general.multiselect')"
            @update:modelValue="commitData(domain)"
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Loader from '/src/components/partials/Loader.vue'
import Card from '/src/components/partials/Card.vue'
import MultiSelect from '/src/components/functionality/MultiSelect.vue'

export default {
  name: 'Domains',

  components: {
    'v-multi-select': MultiSelect,
    'v-loader': Loader,
    'v-card': Card
  },

  data() {
    return {
      domains: [],
      countries: {}
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  methods: {
    fetchData() {
      this.$store.dispatch("GeneralService/getDomains")
        .then(data => {
          this.domains = data
        })

      this.$store.dispatch('GeneralService/getCountries')
        .then(data => {
          this.countries = data
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.data.status.message,
            'timer': false
          })
        })
    },

    commitData(domain) {
      this.$store.dispatch("GeneralService/updateDomains", domain)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message,
            'timer': false
          })
        })
    },
  }
}
</script>
