<template>
  <teleport to="body">
    <transition name="slide-fade">
      <div
        v-if="isVisible"
        class="
          fixed
          top-4
          left-0
          right-0
          z-50
          w-full
          mx-auto
          max-w-2xl
        "
      >
        <div class="relative z-[51] bg-white rounded-lg shadow">
          <div class="flex justify-between items-start p-4 rounded-t border-b">
            <h3 class="text-xl font-semibold text-[var(--NovikGray)]">
              {{ modalHeader }}
            </h3>
            <button
              @click="close()"
              type="button"
              class="
                hover:bg-[var(--EntityDarkBG)]
                w-7
                h-7
                p-1.5
                rounded-lg
                ml-auto
                inline-flex
                items-center
              "
            >
              <span class="text-[var(--NovikGray)] text-xl">
                <svg
                  class="fill-[var(--NovikGray)]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                >
                  <path
                    id="path"
                    d="M19,6.4,17.6,5,12,10.6,6.4,5,5,6.4,10.6,12,5,17.6,6.4,19,12,13.4,17.6,19,19,17.6,13.4,12Z"
                    transform="translate(-5 -5)"
                    fill-rule="evenodd"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div class="p-6 space-y-6 text-[var(--NovikGray)]">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    modalHeader: String
  },

  data: () => ({
    isVisible: false,
  }),

  mounted() {
    window.addEventListener('closeModal', () => {
      this.close()
    })
  },

  methods: {
    open() {
      this.isVisible = true
      this.emitOpen()
    },

    close() {
      this.isVisible = false
      this.emitOpen()
    },

    emitOpen() {
      this.$root.modalActive = this.isVisible
    }
  }
}
</script>
