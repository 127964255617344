<template>
  <v-selectbox
    v-model="selection"
    :options="currentUser.brands"
    :keyValue="['fqdn', 'name']"
    :no-remove="true"
    theme="flat"
    class="brandselector"
    @update:modelValue="switchToBrand"
  />
</template>

<script>
import SelectBox from '/src/components/functionality/SelectBox.vue'

export default {
  name: 'BrandSelector',

  components: {
    'v-selectbox': SelectBox
  },

  data() {
    return {
      selection: this.currentUser.brands.filter(entry => entry.is_current)[0] ?? this.currentUser.brands[0]
    }
  },

  inject: {
    currentUser: {
      from: 'currentUser'
    }
  },

  methods: {
    switchToBrand() {
      this.$store.dispatch('BrandService/switchBrands', this.selection)
        .then(response => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 5000
          })
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': 0
          })
        })
    }
  }
}
</script>
