class SelectBox {
  constructor() {
    return {
      type: 'selectbox',
      name: 'Select box',
      settings: {
        name: [],
        content: [],
        options: [],
        validations: []
      }
    }
  }
}

export default SelectBox
