<template>
  <button
    @click="$emit('click', $event)"
    :disabled="disabled"
    :class="{
      buttonFlat: theme === 'flat',
      buttonWhite: theme === 'white',
      buttonGray: theme === 'gray',

      disabled: disabled
    }"
    class="w-full transition-all duration-300 py-3 px-10 rounded"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',

  props: {
    disabled: Boolean,
    theme: String
  },

  emits: [
    'click'
  ]
}
</script>
