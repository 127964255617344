<template>
  <div
    v-if="!!add"
    class="
      add-list-header
      flex
      justify-between
      items-center
    "
  >
    <v-input
      :type="inputType ?? 'text'"
      :name="`newEntry-${uniqueId}`"
      :placeholder="$t('components.newEntry')"
      v-model="newEntry"
      autocomplete="off"
      :min-length="minLength"
      :max-length="maxLength"
      class="w-full"
    />
    <v-icon-button
      theme="white"
      class="!w-[52px] ml-4 rounded-full"
      icon="icon-add-entity"
      :disabled="!newEntry.length || newEntry.length < minLength || newEntry.length > maxLength"
      @click="addEntry"
      :title="$t('general.add')"
    />
  </div>

  <div>
    <div
      v-for="entry in entries"
      class="
        add-list-entry
        first:mt-5
        flex
        justify-between
        items-center
        border-b
        border-[var(--EntityDarkBG)]
        last:border-0
        transition-all
        duration-300
        hover:bg-[var(--EntityBG)]
        pl-2
        rounded
      "
    >
      <p class="text-sm text-[var(--NovikGray)]">
        {{ entry[keyValue[1]] }}
      </p>
      <span v-if="remove">
        <v-icon-button
          theme="flat"
          class="!w-max"
          icon="icon-remove-entity"
          @click="removeEntry(entry[keyValue[0]])"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { uniqueId } from '/src/helper.js'
import Input from '/src/components/functionality/Input.vue'
import IconButton from '/src/components/functionality/IconButton.vue'

export default {
  name: 'AddList',

  components: {
    'v-input': Input,
    'v-icon-button': IconButton,
  },

  data() {
    return {
      newEntry: '',
      entries: [],
      uniqueId: uniqueId()
    }
  },

  props: {
    modelValue: Array,
    add: Boolean,
    remove: Boolean,
    keyValue: Array,
    inputType: String,
    minLength: Number,
    maxLength: Number,
  },

  emits: [
    'update:modelValue'
  ],

  mounted() {
    this.entries = this.modelValue
  },

  methods: {
    addEntry() {
      if (!!this.entries.filter(entry => entry[this.keyValue[1]] === this.newEntry).length) {
        this.$root.$refs.toast.make({
          'status': 'warning',
          'text': this.$t('components.add-list.duplicate-entry'),
          'timer': 1000
        })

        return
      }

      this.entries.push({
        [this.keyValue[0]]: this.newEntry
      })

      this.$emit('update:modelValue', this.entries)
    },

    removeEntry(key) {
      const entryIndex = this.entries.indexOf(this.entries.find(entry => entry[this.keyValue[0]] === key))

      this.entries.splice(entryIndex, 1)

      this.$emit('update:modelValue', this.entries)
    }
  }
}
</script>
