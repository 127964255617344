<template>
  <template v-if="isActive">
    <div v-if="campaign.campaign_settings">
      <div v-for="settingGroup in campaign.campaign_settings">
        <p class="text-[var(--NovikGray)]">{{ settingGroup.campaign_setting_group_name }}</p>
        <v-card>
          <v-setting
            v-for="setting in settingGroup.settings"
            :setting="setting"
            :parent="true"
            @update="(args) => settingUpdated(args)"
          />
        </v-card>
      </div>
    </div>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Button from '/src/components/functionality/Button.vue'
import Setting from '/src/components/partials/Setting.vue'
import Card from '/src/components/partials/Card.vue'

export default {
  name: 'Settings',

  components: {
    'v-button': Button,
    'v-setting': Setting,
    'v-card': Card
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      return [true]
    }
  },

  methods: {
    settingUpdated(setting) {
      this.$emit(
        'commitData',
        {
          endpoint: '/settings',
          data: setting
        }
      )
    }
  },

  watch: Step.watch
}
</script>
