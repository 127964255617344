import axios from '/src/store/utility/Request'

class CsvRepository {
  parseData(data) {
    return axios.post('csv/import/parse', data)
  }

  processData(data) {
    return axios.post('csv/import/process', data)
  }
}

export default new CsvRepository()
