<template>
  <v-select-box
    v-model="rows"
    theme="white"
    :options="options"
    :keyValue="['amount', 'name']"
    :noRemove="true"
  />
</template>

<script>
import SelectBox from '/src/components/functionality/SelectBox.vue'

export default {
  name: 'RowAmountSelector',

  components: {
    'v-select-box': SelectBox
  },

  data() {
    return {
      rows: {
        amount: 0
      },
      options: {
        '10': {
          name: `10 / ${this.$t('general.page')}`,
          amount: 10
        },
        '20': {
          name: `20 / ${this.$t('general.page')}`,
          amount: 20
        },
        '30': {
          name: `30 / ${this.$t('general.page')}`,
          amount: 30
        },
      }
    }
  },

  props: {
    modelValue: Number
  },

  emits: [
    'update:modelValue'
  ],

  mounted() {
    this.rows.amount = this.modelValue
  },

  methods: {
    setRows() {
      this.$emit('update:modelValue', this.rows.amount)
    }
  },

  watch: {
    rows: {
      handler() {
        this.setRows()
      }
    }
  }
}
</script>
