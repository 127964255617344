<template>
  <div class="relative w-64 z-10" data-carousel="static">
    <div class="relative h-max overflow-hidden rounded-lg">
      <div
        v-for="(panel, identifier) in panels"
        :class="{hidden: identifier !== current}"
        class="p-4 text-left duration-200 ease-linear"
      >
        <h4 class="text-lg" v-html="panel.title"></h4>
        <p class="w-full mt-2" v-html="panel.content"></p>
      </div>
    </div>
    <div v-if="trackerTheme !== 'button'" class="flex space-x-2 justify-center mb-4">
      <button
        v-for="(panel, identifier) in panels"
        :class="{
          '!bg-[var(--NovikGray)]': identifier === current,

          'w-3 h-3': trackerTheme === 'round',
          'w-6 h-1': trackerTheme === 'flat',
        }"
        class="bg-[var(--NovikLightGray)] rounded-full"
        @click="setStep(identifier)"
      ></button>
    </div>
  </div>
  <div class="flex z-10">
    <v-button v-if="trackerTheme === 'button'" class="!px-3 !rounded-none !rounded-bl-lg space-x-1" theme="gray">
      <span class="mr-2">{{ $t('components.tooltip.step', {'step_num': this.current + 1 }) }}</span>
      <button
        v-for="(panel, identifier) in panels"
        :class="{
          '!bg-[var(--NovikGray)]': identifier === current,
          '!border-white': identifier === current,
        }"
        class="bg-[var(--NovikLightGray)] border-2 border-transparent w-3 h-3 rounded-full"
        @click="setStep(identifier)"
      ></button>
    </v-button>
    <v-button v-else @clicked="moveStep(-1)" class="!px-3 !rounded-none !rounded-bl-lg" theme="gray">
      {{ this.current === 0 ? $t('components.tooltip.cancel') : $t('components.tooltip.previous') }}
    </v-button>

    <v-button
      @clicked="moveStep(1)"
      class="!px-3 !rounded-none !rounded-br-lg"
      :class="{
        'w-max !px-8': trackerTheme === 'button'
      }"
      theme="white"
    >
      {{ this.current === this.panels.length - 1 ? $t('components.tooltip.finish') : $t('components.tooltip.next') }}
    </v-button>
  </div>
</template>

<script>
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'SteppedContent',

  components: {
    'v-button': Button
  },

  data() {
    return {
      panels: [],
      current: 0
    }
  },

  props: {
    content: {},
    trackerTheme: String
  },

  emits: [
    'scrolledPastBounds',
    'finishedReading'
  ],

  mounted() {
    this.hydratePanels()
  },

  methods: {
    hydratePanels() {
      Object.values(this.content).forEach((value) => {
        this.panels.push(value)
      })
    },

    moveStep(movement) {
      this.current = this.current + movement

      if (this.current >= this.panels.length) {
        this.$emit('finishedReading')
      }

      if (this.current < 0 || this.current >= this.panels.length) {
        this.current = 0

        this.$emit('scrolledPastBounds')
      }
    },

    setStep(step) {
      this.current = step
    }
  }
}
</script>
