<template>
  <div class="flex self-center">
    <v-button
      class="decrement"
      @click="$emit('update:modelValue', --modelValue)"
      :class="large ? '!w-max !px-4 rounded-r-none' : 'self-center !w-6 aspect-square !p-0 !border-2 rounded-full'"
      :theme="large ? 'gray' : 'white'"
    >
      &minus;
    </v-button>

    <input
      type="number"
      :class="large ? 'border-t border-b border-[var(--NovikGray)] h-12 px-2 w-20' : 'mx-2 px-2 w-16 rounded'"
      class="text-field text-center bg-white"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >

    <v-button
      class="increment"
      @click="$emit('update:modelValue', ++modelValue)"
      :class="large ? '!w-max !px-4 rounded-l-none' : 'self-center !w-6 aspect-square !p-0 !border-2 rounded-full'"
      :theme="large ? 'gray' : 'white'"
    >
      &plus;
    </v-button>
  </div>
</template>

<script>
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'Counter',

  components: {
    'v-button': Button
  },

  props: {
    large: Boolean,
    modelValue: Number
  },

  emits: [
    'update:modelValue'
  ]
}
</script>
