<template>
  <template v-if="isActive">
    <div>
      <p class="text-[var(--NovikGray)]">Date settings</p>
      <p class="text-[var(--NovikLightGray)] mb-5">Nam facilisis est quis euismod laoreet. Mauris pharetra, orci ut tristique vulputate, libero nibh euismod arcu, in maximus neque purus id tellus. Nam interdum interdum lacinia. Nunc turpis mauris, rutrum vitae bibendum eget, pharetra quis arcu. Cras at turpis est.</p>
      <v-daterangepicker
        v-if="campaign.dates"
        v-model="campaign.dates"
        :texts="dates.texts"
        @commitData="
          this.$emit(
            'commitData',
            {
              endpoint: '/dates',
              data: campaign.dates
            }
          )"
        @dateSetInvalid="args => dateSetInvalid(args)"
      />
    </div>

    <div v-if="campaign.verification && verification" class="mb-9">
      <p class="text-[var(--NovikGray)]">Select claim verification</p>
      <v-select-box
        v-model="verification"
        :options="campaignThirdParties.filter(entry => entry.is_verification)"
        :keyValue="['id', 'name']"
        theme="white"
        :no-remove="true"
        @update:modelValue="
          this.$emit(
            'commitData',
            {
              endpoint: '/verification',
              data: {
                verification_id: verification.id,
                verification_reference: campaign.verification.verification_reference ?? ''
              }
            }
          )"
      />
      <v-input
        v-if="verification.has_campaign_reference_code"
        v-model="campaign.verification.verification_reference"
        name="verification"
        class="mt-5"
        :debounced="true"
        @update:modelValue="
          this.$emit(
            'commitData',
            {
              endpoint: '/verification',
              data: {
                verification_id: verification.id,
                verification_reference: campaign.verification.verification_reference ?? ''
              }
            }
          )"
      />
    </div>

    <div v-if="campaign.handling && handling" class="mb-9">
      <p class="text-[var(--NovikGray)]">Select claim handling</p>
      <v-select-box
        v-model="handling"
        :options="campaignThirdParties.filter(entry => entry.is_handling)"
        :keyValue="['id', 'name']"
        theme="white"
        :no-remove="true"
        @update:modelValue="
          this.$emit(
            'commitData',
            {
              endpoint: '/handling',
              data: {
                handling_id: handling.id,
                handling_reference: campaign.handling.handling_reference ?? ''
              }
            }
          )"
      />
      <v-input
        v-if="handling.has_campaign_reference_code"
        v-model="campaign.handling.handling_reference"
        name="handling"
        class="mt-5"
        :debounced="true"
        @update:modelValue="
          this.$emit(
            'commitData',
            {
              endpoint: '/handling',
              data: {
                handling_id: handling.id,
                handling_reference: campaign.handling.handling_reference ?? ''
              }
            }
          )"
      />
    </div>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Input from '/src/components/functionality/Input.vue'
import Button from '/src/components/functionality/Button.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import DateRangePicker from '/src/components/functionality/DateRangePicker.vue'

export default {
  name: 'Duration',

  components: {
    'v-input': Input,
    'v-select-box': SelectBox,
    'v-button': Button,
    'v-daterangepicker': DateRangePicker,
  },

  data() {
    return {
      verification: {},
      handling: {},
      dates: {
        texts: {
          start: {
            title: 'Start',
          },
          end: {
            title: 'End',
          },
          finish: {
            title: 'Finish',
            description: 'Nam facilisis est quis euismod laoreet. Mauris pharetra, orci ut tristique vulputate, libero nibh euismod arcu, in maximus neque purus id tellus.'
          }
        }
      }
    }
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      if (!this.campaign) {
        return [false]
      }

      if (!this.campaign.verification) {
        return [false]
      }

      if (!this.campaign.handling) {
        return [false]
      }

      return [
        !!this.campaign.dates,
        !!this.campaign.verification.verification_id &&
          (this.campaign.verification.verification_id > 1 ? !!this.campaign.verification.verification_reference : true),
        !!this.campaign.handling.handling_id &&
          (this.campaign.handling.handling_id > 1 ? !!this.campaign.handling.handling_reference : true)
      ]
    }
  },

  methods: {
    setVerificationFromAPIData() {
      this.verification = this.campaignThirdParties.filter(entry => {
        return entry.id === this.campaign.verification?.verification_id
      })[0] ?? {}
    },

    setHandlingFromAPIData() {
      this.handling = this.campaignThirdParties.filter(entry => {
        return entry.id === this.campaign.handling?.handling_id
      })[0] ?? {}
    },

    dateSetInvalid(args) {
      const failedChecks = args.filter(value => {
        return Object.entries(value).filter(entry => {
          return !entry[1]
        }).length
      })

      failedChecks.forEach(check => {
        Object.keys(check).forEach(entry => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': this.$t(`campaigns.dates.failed-checks.${entry}`),
            'timer': false
          })
        })
      })

    }
  },

  watch: {
    ...Step.watch,

    campaignThirdParties: {
      handler() {
        this.setVerificationFromAPIData()
        this.setHandlingFromAPIData()
      },
      immediate: true
    },

    campaign: {
      handler() {
        this.setVerificationFromAPIData()
        this.setHandlingFromAPIData()
      },
      immediate: true
    }
  }
}
</script>
