<template>
  <div class="hidden tooltip rich-tooltip object-center flex flex-col content-center absolute -mx-1 w-max rounded-lg text-[var(--NovikGray)] bg-white z-10">
    <button @click="closeTooltip" class="absolute top-3 right-3 w-8 h-8 flex items-center justify-center rounded-full text-xl p-2 cursor-pointer duration-300 transition-all hover:bg-[var(--EntityDarkBG)] z-30">
      <svg class="fill-[var(--NovikGray)]" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path id="path" d="M19,6.4,17.6,5,12,10.6,6.4,5,5,6.4,10.6,12,5,17.6,6.4,19,12,13.4,17.6,19,19,17.6,13.4,12Z" transform="translate(-5 -5)" fill-rule="evenodd"/>
      </svg>
    </button>
    <slot name="content" :closeTooltip="closeTooltip"></slot>
    <div
      class="absolute w-2 h-2 self-center rotate-45 bg-white"
      :class="{
        '-right-1 top-2': location === 'left',
        '-left-1 top-2': location === 'right',
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'RichTooltip',

  data() {
    return {
      height: 0,
      width: 0,
      linkedElement: '',
      tooltip: ''
    }
  },

  props: {
    location: String
  },

  mounted() {
    this.tooltip = this.$el

    this.linkedElement = this.tooltip.parentNode

    this.linkedElement.classList.add('relative')

    this.getTooltipHeight(this.tooltip)
    this.getTooltipWidth(this.tooltip)

    window.addEventListener('closeTooltip', () => {
      this.closeTooltip()
    })
  },

  methods: {
    toggleTooltip(e) {
      this.setTooltipPosition(this.tooltip)

      if (!e.target.closest('.tooltip')) {
        this.tooltip.classList.toggle('hidden')
      }
    },

    closeTooltip() {
      if (!this.tooltip.classList.contains('hidden')) {
        this.tooltip.classList.add('hidden')
      }
    },

    setTooltipPosition(tooltip) {
      if (this.location === 'left') {
        tooltip.style.setProperty('top', `calc(${this.linkedElement.offsetHeight / 2}px - 0.75rem)`)
        tooltip.style.setProperty('left', `-${this.width + 16}px`)
      }
      else if (this.location === 'right') {
        tooltip.style.setProperty('top', `calc(${this.linkedElement.offsetHeight / 2}px - 0.75rem)`)
        tooltip.style.setProperty('right', `-${this.width + 16}px`)
      }
    },

    getTooltipWidth(tooltip) {
      tooltip.classList.toggle('invisible')
      tooltip.classList.toggle('hidden')

      this.width = tooltip.offsetWidth

      tooltip.classList.toggle('hidden')
      tooltip.classList.toggle('invisible')
    },

    getTooltipHeight(tooltip) {
      tooltip.classList.toggle('invisible')
      tooltip.classList.toggle('hidden')

      this.height = tooltip.offsetHeight

      tooltip.classList.toggle('hidden')
      tooltip.classList.toggle('invisible')
    },

    addListeners() {
      this.linkedElement.addEventListener('click', (e) => { this.toggleTooltip(e) })
    }
  },

  watch: {
    linkedElement: {
      handler() {
        this.addListeners()
      }
    }
  }
}
</script>
