import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'
import store from '/src/store'

import fallbackMessages from '/src/lang/fallbackLang'

// The defaultLocale is set, in order, to:
// - The visitor's browser default language
// - The server's env default language
// - if neither has been set, fallback to english
export const defaultLocale = window.navigator.language.split('-')[0] ??
  import.meta.env.VITE_I18N_DEFAULT_LOCALE ??
  'en'

export async function fetchTranslations(locale) {
  return store.dispatch('UserService/getTranslations', locale)
    .then((response) => {
      return response
    })
    .catch((e) => {
      return false
    })
}

export function setupI18n(options = {locale: defaultLocale}) {
  const i18n = createI18n({
    fallbackLocale: 'en',
    silentTranslationWarn: import.meta.env.VITE_I18N_SILENCE_WARNS === String(true)
  })

  i18n.global.locale = defaultLocale

  document.querySelector('html').setAttribute('lang', options.locale)

  return i18n
}

export async function loadLocaleMessages(i18n, locale) {
  i18n.global.setLocaleMessage(locale, fallbackMessages[locale])

  const messages = await fetchTranslations(locale)

  i18n.global.setLocaleMessage(locale, {...fallbackMessages[locale], ...messages[locale]})

  return nextTick()
}
