<template>
  <v-image
    :clause="!!user.avatar"
    :theme="theme"
    :image="{
      src: user.avatar,
      alt: `${user.firstname} ${user.lastname}`
    }"
  >
    <span>{{ `${getFirstLetter(user.firstname)}${getFirstLetter(user.lastname)}` }}</span>
  </v-image>
</template>

<script>
import Image from '/src/components/partials/Image.vue'

export default {
  name: 'Avatar',

  components: {
    'v-image': Image
  },

  props: {
    user: {},
    theme: String
  },

  methods: {
    getFirstLetter(name) {
        return name[0]?.toUpperCase() ?? '-'
    }
  }
}
</script>
