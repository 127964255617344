import axios from '/src/store/utility/Request'

class RetailersRepository {
  getRetailers() {
    return axios.get('retailers')
  }

  getRetailer(id) {
    return axios.get(`retailers/${id}`)
  }

  createRetailer(data) {
    return axios.post('retailers', data)
  }

  updateRetailer(data) {
    return axios.put(`retailers/${data.id}`, data.data)
  }

  deleteRetailer(id) {
    return axios.delete(`retailers/${id}`)
  }
}

export default new RetailersRepository()
