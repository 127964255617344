<template>
  <template v-if="isActive">
    <div class="mb-5 text-[var(--NovikGray)]">
      <p>{{ $t('components.campaign') }} {{ $t('general.type') }}</p>
      <template v-for="[header, campaignType] in Object.entries(typesFormatted)">
        <div class="mb-9 flex flex-wrap justify-between bg-white p-2 rounded-md shadow-md">
          <p>{{ header }}</p>

          <v-button-row
            v-model="campaign.campaign_type_id"
            :options="campaignType"
            class="!w-full"
            :keyValue="['id', 'type']"
            :disabled="!!campaign.id"
          />
        </div>
      </template>
    </div>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$router.push({name: 'Campaigns'})"
      >
        {{ $t('general.cancel') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Button from '/src/components/functionality/Button.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import ButtonRow from '/src/components/functionality/ButtonRow.vue'

export default {
  name: 'Types',

  components: {
    'v-button': Button,
    'v-selectbox': SelectBox,
    'v-button-row': ButtonRow,
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      return [
        !this.nextDisabled
      ]
    },

    nextDisabled() {
      return this.campaign.campaign_type_id === ''
    },

    typesFormatted() {
      let groups = {}
      for (let type of this.campaignTypes) {
        if (!Object.keys(groups).includes(type.group)) {
          groups[type.group] = []
        }

        groups[type.group].push(type)
      }

      return groups
    }
  },

  watch: Step.watch
}
</script>
