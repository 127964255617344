import ManagerRepository from '/src/store/repositories/ManagerRepository'

export const ManagerService = {
  namespaced: true,

  actions: {
    getApiUrl({ commit, state }, current_url) {
      return ManagerRepository.getApiUrl(current_url)
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }

          localStorage.setItem('api_url', response.data.data.api_fqdn)
          window.dispatchEvent(new CustomEvent('ApiUrlChange'))
        })
        .catch(error => {
          throw error
        })
    }
  }
}
