import axios from '/src/store/utility/Request'

class SerialMaskRepository {
  getSerialMasks() {
    return axios.get('serial-masks')
  }

  getSerialMask(id) {
    return axios.get(`serial-masks/${id}`)
  }

  updateSerialMaskFields(data) {
    return axios.put(`serial-mask-fields/${data.id}/serial-mask-field-values`, {values: data.values})
  }
}

export default new SerialMaskRepository()
