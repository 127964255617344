<template>
  <div class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 mx-auto md:mx-24 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6">
      <v-image
        :clause="false"
        theme="large"
        class="!rounded-xl"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="p-8 md:p-16" viewBox="0 0 50.732 44.391"><path class="fill-white" d="M50.732,43.1V71.635a4.757,4.757,0,0,1-4.756,4.756H4.756A4.757,4.757,0,0,1,0,71.635V43.1a4.757,4.757,0,0,1,4.756-4.756h8.72l1.219-3.26A4.75,4.75,0,0,1,19.144,32H31.579a4.75,4.75,0,0,1,4.449,3.082l1.229,3.26h8.72A4.757,4.757,0,0,1,50.732,43.1ZM37.257,57.366a11.89,11.89,0,1,0-11.89,11.89A11.9,11.9,0,0,0,37.257,57.366Zm-3.171,0a8.72,8.72,0,1,1-8.72-8.72A8.732,8.732,0,0,1,34.086,57.366Z" transform="translate(0 -32)"/></svg>
      </v-image>
    </div>

    <div class="mt-20 mx-auto px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 md:mx-0 flex flex-col">
      <div class="text-[var(--NovikGray)]">
        <v-card>
          <div
            class="
              flex
              justify-between
              items-center
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <v-toggleswitch
              v-model="product.is_active"
              :entry="{text: `${$t('products.is-active')}`, entry: false}"
              :keyValue="['entry', 'text']"
              :debounced="false"
            />
          </div>
        </v-card>
      </div>

      <div class="mb-9 text-[var(--NovikGray)]">
        <label for="name" class="mb-1 block text-sm">{{ $t('general.name') }}</label>
        <v-input
          type="text"
          name="name"
          v-model="product.name"
          autocomplete="off"
          :debounced="true"
        />
      </div>

      <div class="mb-9 text-[var(--NovikGray)]">
        <label for="article_number" class="mb-1 block text-sm">{{ $t('products.article_number') }}</label>
        <v-input
          type="text"
          name="article_number"
          v-model="product.article_number"
          autocomplete="off"
          :debounced="true"
        />
      </div>

      <div v-if="!!product.languages.length" class="text-[var(--NovikGray)]">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.languages') }}</p>
        <v-card>
          <div
            v-for="language in product.languages"
            class="
              mb-5
              last:mb-0
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <label :for="language.name" class="mb-1 block text-sm">{{ language.name }}</label>
            <v-input
              type="text"
              :name="language.name"
              v-model="language.value"
              autocomplete="off"
              :debounced="true"
            />
          </div>
        </v-card>
      </div>

      <div class="text-[var(--NovikGray)]">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.serial-mask') }}</p>
        <v-card>
          <div
            class="
              flex
              justify-between
              items-center
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <v-toggleswitch
              v-model="product.has_serial_mask"
              :entry="{text: `${$t('products.has-serial-mask')}`, entry: false}"
              :keyValue="['entry', 'text']"
              :debounced="false"
            />
          </div>

          <div
            v-if="!!product.has_serial_mask"
            class="
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.serial-mask-pattern') }}</p>
            <v-selectbox
              v-if="serialMasks"
              identifier="SelectSerialMask"
              class="mt-5"
              v-model="product.serial_mask"
              :options="serialMasks"
              :noRemove="true"
              theme="white"
              :keyValue="['id', 'example']"
            />
          </div>
        </v-card>
      </div>

      <div class="text-[var(--NovikGray)]">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.ean_barcodes') }}</p>
        <v-card>
          <v-add-list
            v-model="product.ean_barcodes"
            :add="true"
            :remove="true"
            :key-value="['ean_barcode', 'ean_barcode']"
          />
        </v-card>
      </div>

      <div class="mb-9 flex justify-end items-center">
        <a class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer" @click="$router.back()">{{ $t('general.cancel') }}</a>
        <v-button
          class="!w-max uppercase"
          theme="gray"
          @click="commitData()"
          :disabled="createDisabled"
        >
          {{ $t('general.create') }} {{ $t('components.product') }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import Image from '/src/components/partials/Image.vue'
import Input from '/src/components/functionality/Input.vue'
import Card from '/src/components/partials/Card.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import AddList from '/src/components/functionality/AddList.vue'
import Button from '/src/components/functionality/Button.vue'
import ImageUpload from '/src/components/functionality/ImageUpload.vue'

export default {
  name: 'Add',

  components: {
    'v-image-upload': ImageUpload,
    'v-button': Button,
    'v-input': Input,
    'v-card': Card,
    'v-toggleswitch': ToggleSwitch,
    'v-selectbox': SelectBox,
    'v-add-list': AddList,
    'v-image': Image
  },

  data() {
    return {
      product: {
        is_active: false,
        name: '',
        article_number: '',
        languages: [],
        has_serial_mask: false,
        serial_mask: {},
        ean_barcodes: []
      },
      serialMasks: []
    }
  },

  computed: {
    createDisabled() {
      const productData = {}

      Object.entries(this.product).forEach(data => {
        switch(data[0]) {
          case 'name':
          case 'article_number':
          case 'ean_barcodes':
            productData[data[0]] = !!data[1].length
            break
          case 'languages':
            productData[data[0]] = !!data[1].filter(entry => !!entry.value && !!entry.value.length).length
            break
          case 'serial_mask':
            if (this.product.has_serial_mask) {
              productData[data[0]] = !!Object.values(data[1]).length
              break
            }
            break
        }
      })

      return !!Object.values(productData).filter(entry => !entry).length
    }
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  methods: {
    fetchData() {
      this.$store.dispatch("ProductService/getSerialMasks")
        .then(data => {
          this.serialMasks = data
        })

        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': 0
          })
        })

      this.$store.dispatch("ProductService/getLanguages")
        .then(data => {
          this.product.languages = data.data
        })

        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': 0
          })
        })
    },

    commitData() {
      this.$store.dispatch("ProductService/createProduct", this.product)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })

          this.$router.push({name: 'Products'})
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message,
            'timer': false
          })
        })
    },
  }
}
</script>
