class Option {
  constructor() {
    return {
      type: 'option',
      name: 'Option',
      settings: {
        name: [],
        content: [],
      }
    }
  }
}

export default Option
