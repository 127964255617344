import { createApp } from 'vue'

import '/src/assets/css/app.css'

import App from '/src/App.vue'

import Toast from '/src/components/partials/Toast.vue'

import { setupI18n } from '/src/i18n'
import router from '/src/router'
import store from '/src/store'

import currentUser from '/src/currentUser'


export const i18n = setupI18n()

export let user = currentUser
window.addEventListener('userRefreshed', (event) => {
  user.data = event.detail
})


const app = createApp(App)

app
  .component('v-toast', Toast)

app
  .use(i18n)
  .use(router)
  .use(store)

app
  .mount('#app')
