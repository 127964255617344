import SerialMaskRepository from '/src/store/repositories/SerialMaskRepository'
import { setLoading } from '/src/helper.js'

export const SerialMaskService = {
  namespaced: true,

  actions: {
    getSerialMasks({ state }) {
      setLoading(state, 'getSerialMasks', true)
      return SerialMaskRepository.getSerialMasks()
        .then((response) => {
          setLoading(state, 'getSerialMasks', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getSerialMasks', false)
          throw error
        })
    },

    getSerialMask({ state }, id) {
      setLoading(state, 'getSerialMask', true)
      return SerialMaskRepository.getSerialMask(id)
        .then((response) => {
          setLoading(state, 'getSerialMask', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getSerialMask', false)
          throw error
        })
    },

    updateSerialMaskFields({ state }, data) {
      return SerialMaskRepository.updateSerialMaskFields(data)
        .then((response) => {
          if (response.data.status.code !== 200) {
            throw response
          }

          return response
        })
        .catch(error => {
          throw error
        })
    }
  }
}
