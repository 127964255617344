<template>
  <img
    v-if="clause"
    :src="image.src"
    class="
      aspect-square
      rounded-full
      object-contain
      bg-white
    "
    :class="{
      'w-14': theme === 'small',
      'w-full': theme === 'large'
    }"
    :alt="image.alt"
  >
  <div
    v-else
    class="
      rounded-full
      aspect-square
      bg-[var(--NovikGray)]
      text-white
      flex
      justify-center
      items-center
    "
    :class="{
      'w-14 text-xl': theme === 'small',
      'w-full text-7xl sm:text-5xl md:text-5xl lg:text-7xl xl:text-8xl': theme === 'large'
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Image',

  props: {
    clause: Boolean,
    theme: String,
    image: {
      src: String,
      alt: String
    }
  }
}
</script>
