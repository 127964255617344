<template>
  <v-editor
    v-model="value"
    @update:modelValue="waitForKey()"
    :init="{
      height: 500,
      menubar: false,
      statusbar: false,
      toolbar: this.toolbar,
      license_key: 'gpl',
      content_css: contentCss,
      skin_url: skinUrl,
      path_absolute: '/',
      relative_urls: false,
    }"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

import 'tinymce'

import 'tinymce/icons/default/icons.min'
import 'tinymce/themes/silver/theme.min'
import 'tinymce/models/dom/model.min'

export default {
  name: 'TinyMCE',

  components: {
    'v-editor': Editor
  },

  data() {
    return {
      delay: {
        current: 0,
        waitTime: this.waitTime ?? 1000
      },
      value: this.modelValue,
      toolbar: [
        ['undo', 'redo'],
        ['bold', 'italic'],
        ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
        ['outdent', 'indent'],
        ['removeformat']
      ].map(entry => entry.join(' ')).join(' | '),
      contentCss: new URL('/tinymce/skins/content/default/content.min.css', import.meta.url).href,
      skinUrl: new URL('/tinymce/skins/ui/oxide', import.meta.url).href
    }
  },

  props: {
    modelValue: String,
    debounced: Boolean,
    waitTime: Number
  },

  emits: [
    'update:modelValue'
  ],

  methods: {
    debouncedUpdate() {
      this.delay.current = setTimeout(() => {
        this.$emit('update:modelValue', this.value)
      }, this.debounced ? this.delay.waitTime : 0)
    },

    waitForKey() {
      clearTimeout(this.delay.current)

      this.debouncedUpdate()
    }
  },

  watch: {
    modelValue: {
      handler() {
        this.value = this.modelValue
      }
    }
  }
}
</script>
