<template>
  <template
    v-if="visible"
  >
    <div
      v-if="setting.type === 'text'"
      class="
        rounded
        hover:bg-[var(--EntityBG)]
        transition-[background-color]
        text-[var(--NovikGray)]
        mb-9
        last:mb-0
        p-2
      "
    >
      <label
        :for="setting.name.replace(/\s/g, '-').toLowerCase()"
      >
        {{ setting.name }}
        <br>
        <small class="text-[var(--NovikGray)]">{{ setting.description }}</small>
      </label>
      <v-input
        :name="setting.name"
        v-model="setting.value"
        :debounced="true"
        :validations="setting.validation"
        @update:modelValue="$emit('update', {
          id: setting.id,
          value: setting.value
        })"
      />
    </div>

    <div
      v-if="setting.type === 'boolean'"
      class="
        p-2
        rounded
        hover:bg-[var(--EntityBG)]
        transition-[background-color]
        mb-9
        last:mb-0
      "
    >
      <div class="flex justify-between">
        <v-toggleswitch
          v-model="setting.value"
          :entry="setting"
          :keyValue="['value', 'name']"
          :debounced="false"
          @update:modelValue="$emit('update', {
            id: setting.id,
            value: setting.value
          })"
        />
      </div>

      <small class="text-[var(--NovikGray)]">{{ setting.description }}</small>
    </div>

    <div
      v-if="
        setting.type === 'select'
      "
      class="
        p-2
        rounded
        hover:bg-[var(--EntityBG)]
        transition-[background-color]
        mb-5
        last:mb-0
      "
    >
      <p class="text-[var(--NovikGray)]">{{ setting.name }}</p>
      <small class="text-[var(--NovikGray)]">{{ setting.description }}</small>
      <v-selectbox
        :identifier="setting.name"
        v-model="setting.value"
        :options="setting.options"
        :keyValue="['value', 'name']"
        :no-remove="setting.validation?.split('|').includes('required')"
        theme="white"
        @update:modelValue="$emit('update', {
          id: setting.id,
          value: setting.value.value
        })"
      />
    </div>
  </template>

  <Setting
    v-if="setting.children && visible"
    v-for="child in setting.children"
    :setting="child"
    :parent="setting"
    @update="(args) => $emit('update', args)"
  />
</template>

<script>
import Input from '/src/components/functionality/Input.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'

export default {
  name: 'Setting',

  components: {
    'v-input': Input,
    'v-toggleswitch': ToggleSwitch,
    'v-selectbox': SelectBox,
  },

  props: {
    setting: {},
    parent: {}
  },

  mounted() {
    this.syncValueWithOption()
  },

  computed: {
    visible() {
      return (
        this.setting.parent_id === 0 ||
        this.selected ||
        (
          this.parent.type === 'group' &&
          this.parent.selected
        )
      )
    },

    selected() {
      if (typeof this.parent.value === 'object') {
        return this.parent.value.value === this.setting.handler
      }

      return this.parent.value === true
    }
  },

  emits: [
    'update'
  ],

  methods: {
    syncValueWithOption() {
      if (
        typeof this.setting.value === 'string' &&
        !!this.setting.value.length &&
        !!this.setting.options.length
      ) {
        const value = this.setting.value
        this.setting.value = this.setting.options.filter(entry => entry.value === value)[0]
      }
    }
  },

  watch: {
    selected: {
      handler() {
        this.setting.selected = this.selected
      },
      immediate: true
    },

    setting: {
      handler() {
        this.syncValueWithOption()
      },
      immediate: true
    }
  }
}
</script>
