<template>
  <div id="login-page" class="flex flex-col h-screen items-center justify-center bg-[var(--NovikLightGreen)]">
    <div class="w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/4 xl:w-1/5 mb-5">
      <h1 class="headline text-4xl text-center uppercase mb-9 text-[var(--NovikGray)]">{{ $t('components.password.success') }}</h1>
      <p class="text-center text-[var(--NovikGray)]">{{ $t('components.password.success-body') }}</p>
    </div>

    <small class="mt-14 underline text-[var(--NovikGray)]">
      <router-link
        :to="{ name: 'Login' }"
      >
        {{ $t('general.back-to') }} {{ $t('routes.Login') }}
      </router-link>
    </small>
  </div>
</template>

<script>
export default {
  name: 'ResetSuccess',
}
</script>
