<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full flex flex-col">
    <div class="p-4 bg-[var(--NovikDarkGreen)]">
      <v-tracker
        v-model="activeStep"
        theme="light"
        :steps="tracker.steps"
        :no-text="true"
      />
    </div>

    <div
      class="
        mt-20
        px-4
        md:px-0
        w-full
        sm:w-2/3
        md:w-3/5
        mx-auto
        md:mx-0
        flex
        flex-col
        self-center
      "
    >
      <template v-for="step in tracker.steps">
        <component
          :is="`Step${step.stepNum}`"
          :isFinalStep="step.stepNum === stepCount"
          :isActive="step.stepNum === activeStep"
          :campaign="campaign"
          :campaignTypes="campaignTypes"
          :campaignLanguages="campaignLanguages"
          :campaignCountries="campaignCountries"
          :campaignThirdParties="campaignThirdParties"
          :campaignProducts="campaignProducts"
          :campaignRetailers="campaignRetailers"
          :productMapping="productMapping"
          :giftableProducts="giftableProducts"
          :campaignTexts="campaignTexts"

          @finished="(finished) => completeStep(step.stepNum, finished)"
          @prevStep="prevStep"
          @nextStep="nextStep"
          @commitData="(args) => commitData(args)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { addDays } from '/src/helper'

import Loader from '/src/components/partials/Loader.vue'
import Tracker from '/src/components/functionality/Tracker.vue'

import Types from '/src/components/campaign/Types.vue'
import Copy from '/src/components/campaign/Copy.vue'
import Locale from '/src/components/campaign/Locale.vue'
import Duration from '/src/components/campaign/Duration.vue'
import Settings from '/src/components/campaign/Settings.vue'
import Products from '/src/components/campaign/Products.vue'
import Texts from '/src/components/campaign/Texts.vue'
import Questionnaire from '/src/components/campaign/Questionnaire.vue'
import Code from '/src/components/campaign/Code.vue'

export default {
  name: 'Detail',

  components: {
    'v-loader': Loader,
    'v-tracker': Tracker,

    'Step1': Types,
    'Step2': Copy,
    'Step3': Locale,
    'Step4': Duration,
    'Step5': Settings,
    'Step6': Products,
    'Step7': Texts,
    'Step8': Questionnaire,
    'Step9': Code,
  },

  data() {
    return {
      toast: this.$root.$refs.toast,
      campaignTypes: [],
      campaignLanguages: [],
      campaignCountries: [],
      campaignThirdParties: [],
      campaignProducts: [],
      campaignRetailers: [],
      giftableProducts: [],
      campaignTexts: [],
      campaign: {},
      defaultStep: 3,
      activeStep: Number,
      dates: {
        start: new Date(),
        end: new Date(),
        finish: new Date()
      },
      stepCount: 9,
      tracker: {
        steps: []
      },
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
      structure: 'structure',
    }),

    entryStep() {
      let availableStep = this.tracker.steps.find((step) => {
        if (!step.disabled && step.finished === false) {
          return step
        }
      })?.stepNum

      // return the first step that isn't finished and isn't disabled.
      // If there are none, return the default starting point
      return availableStep ?? this.defaultStep
    },

    routeQueryStep() {
      if (
        !this.$route.query.step ||
        !this.tracker.steps[this.$route.query.step - 1]
      ) {
        return this.entryStep
      }

      if (this.tracker.steps[this.$route.query.step - 1].disabled) {
        return this.entryStep
      }

      return Number(this.$route.query.step)
    },

    productMapping() {
      switch(this.campaign.campaign_type_id) {
        case 2:
          return 'cashback'
        case 4:
          return 'points'
        case 5:
          return 'price'
        case 6:
          return 'gift'
      }

      return ''
    }
  },

  mounted() {
    for (let i = 1; i <= this.stepCount; i++) {
      this.tracker.steps.push({stepNum: i})
    }

    this.activeStep = this.entryStep
    if (this.$route.query.step && this.tracker.steps[this.$route.query.step - 1]) {
      if (!this.tracker.steps[this.$route.query.step - 1].disabled) {
        this.activeStep = Number(this.$route.query.step)
      }
    }

    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  methods: {
    fetchData() {
      this.$store.dispatch('CampaignService/getCampaign', this.$route.params.id)
        .then(data => {
          this.campaign = data
          this.campaign.campaign_type_id = data.campaign_type.id
          this.campaign.copy = false
          this.campaign.dates = {
            start: data.start_date.length ? new Date(data.start_date) : addDays(new Date(), 1),
            end: data.end_date.length ? new Date(data.end_date) : addDays(new Date(), 2),
            finish: data.close_date.length ? new Date(data.close_date) : addDays(new Date(), 4)
          }
          if (!this.campaign.products) {
            this.campaign.products = []
          }
          this.campaign.products.forEach(entry => {
            if (!entry.gift) {
              entry.gift = []
            }
            if (!entry.value) {
              entry.value = ''
            }
          })
          if (!this.campaign.questionnaire) {
            this.campaign.questionnaire = []
          }

          if (this.productMapping === 'gift') {
            this.$store.dispatch('GiftService/getGifts')
              .then(data => {
                this.giftableProducts = data

                this.giftableProducts.forEach(entry => {
                  entry.name = entry.product.name
                  entry.article_number = entry.product.article_number
                })
              })
              .catch((error) => {
                this.toast.make({
                  'status': 'error',
                  'text': error.data?.status.message ?? error,
                  'timer': false
                })
              })
          }

          this.$store.dispatch('CampaignService/getTextsByCampaignTypeId', this.campaign.campaign_type_id)
            .then(data => {
              this.campaignTexts = data
            })
            .catch((error) => {
              this.toast.make({
                'status': 'error',
                'text': error.data.status.message,
                'timer': false
              })
            })

          this.$store.dispatch('CampaignService/getCodesByCampaignTypeId', this.campaign.id)
            .then(data => {
              this.campaign.codes = data
            })
            .catch((error) => {
              this.toast.make({
                'status': 'error',
                'text': error.data?.status.message ?? error,
                'timer': false
              })
            })
        })
        .catch((error) => {
          this.$router.push({name: 'Campaigns'})

          this.toast.make({
            'status': 'error',
            'text': error.data.status.message,
            'timer': false
          })
        })

      this.$store.dispatch('CampaignService/getCampaignTypes')
        .then(data => {
          this.campaignTypes = data
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': false
          })
        })

      this.$store.dispatch('CampaignService/getLanguages')
        .then(data => {
          this.campaignLanguages = data.data
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.data.status.message,
            'timer': false
          })
        })

      this.$store.dispatch('CampaignService/getCountries')
        .then(data => {
          this.campaignCountries = data
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.data.status.message,
            'timer': false
          })
        })

      this.$store.dispatch('CampaignService/getThirdParties')
        .then(data => {
          this.campaignThirdParties = data
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.data.status.message,
            'timer': false
          })
        })

      this.$store.dispatch('ProductService/getProducts')
        .then(data => {
          this.campaignProducts = data

          this.campaignProducts.forEach(entry => entry.gift = {})
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.data.status.message,
            'timer': false
          })
        })
    },

    commitData(args) {
      let data = args
      data.id = this.campaign.id

      this.$store.dispatch('CampaignService/commitData', data)
        .then((response) => {
          this.toast.make({
            'status': 'success',
            'text': this.$t(response.status.message),
            'timer': 2000
          })
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.message,
            'timer': false
          })
        })
    },

    completeStep(step, finished) {
      this.tracker.steps.find((trackerStep) => {
        if (trackerStep.stepNum === step) {
          trackerStep.finished = finished
        }
      })
    },

    prevStep() {
      // Show previous step
      this.activeStep = this.activeStep - 1
      window.scrollTo({
        top: 0,
        instant: true
      })

      // Update route to current step
      this.$router.push({
        path:this.$route.path,
        query:{
          step: this.activeStep
        }
      })
    },

    nextStep() {
      // Show next step
      this.activeStep = this.activeStep + 1
      window.scrollTo({
        top: 0,
        instant: true
      })

      // Update route to current step
      this.$router.push({
        path:this.$route.path,
        query:{
          step: this.activeStep
        }
      })
    }
  },

  watch: {
    routeQueryStep: {
      handler() {
        if (this.tracker.steps.length && this.tracker.steps[this.routeQueryStep - 1].disabled) {
          this.activeStep = this.entryStep
        } else {
          this.activeStep = this.routeQueryStep
        }

        window.scrollTo({
          top: 0,
          instant: true
        })
      },
      immediate: true
    }
  }
}
</script>
