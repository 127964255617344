<template>
  <template v-if="isActive">
    <div class="mb-5 text-[var(--NovikGray)]">
      <v-button-row
        v-model="campaign.copy"
        :options="buttonRow.options"
        class="!w-full"
        :keyValue="['value', 'name']"
        :disabled="!!campaign.id"
      />
    </div>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Button from '/src/components/functionality/Button.vue'
import ButtonRow from '/src/components/functionality/ButtonRow.vue'

export default {
  name: 'Copy',

  components: {
    'v-button': Button,
    'v-button-row': ButtonRow,
  },

  data() {
    return {
      buttonRow: {
        options: [
          {
            value: false,
            name: this.$t('components.campaigns.new')
          },
          {
            value: true,
            name: this.$t('components.campaigns.copy')
          }
        ]
      },
    }
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      return [
        !this.nextDisabled
      ]
    },

    nextDisabled() {
      return this.campaign.copy === undefined
    }
  },

  watch: Step.watch
}
</script>
