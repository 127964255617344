<template>
  <v-selectbox
    v-model="newTag"
    theme="white"
    :options="entries"
    :keyValue="keyValue"
    :extraValues="extraValues"
    :searchable="searchable"
    @update:modelValue="addTag"
  />

  <div v-if="!hideTags" class="flex flex-wrap">
    <v-tag
      v-for="tag in modelValue"
      :tag="tag[keyValue[1]]"
      :removable="true"
      @removeAction="removeTag(tag)"
    />
  </div>
</template>

<script>
import Tag from '/src/components/functionality/Tag.vue'
import Input from '/src/components/functionality/Input.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'

export default {
  name: 'MultiSelect',

  components: {
    'v-tag': Tag,
    'v-input': Input,
    'v-selectbox': SelectBox,
  },

  data() {
    return {
      newTag: {}
    }
  },

  props: {
    name: String,
    modelValue: Array,
    entries: {},
    searchable: Boolean,
    keyValue: Array,
    extraValues: Array,
    placeholder: String,
    hideTags: Boolean
  },

  methods: {
    addTag() {
      if (!this.modelValue.includes(this.newTag) && this.newTag !== '') {
        this.modelValue.push(this.newTag)
        this.newTag = {}
      }

      this.$emit('update:modelValue', this.modelValue)
    },

    removeTag(tag) {
      this.modelValue.splice(this.modelValue.indexOf(tag), 1)

      this.$emit('update:modelValue', this.modelValue)
    }
  },
}
</script>
