import CodesRepository from '/src/store/repositories/CodesRepository'
import { setLoading } from '/src/helper.js'

export const CodesService = {
  namespaced: true,

  actions: {
    getCodes({ state }) {
      setLoading(state, 'getCodes', true)
      return CodesRepository.getCodes()
        .then((response) => {
          setLoading(state, 'getCodes', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getCodes', false)
          throw error
        })
    },

    createCode({ state }, data) {
      setLoading(state, 'createCode', true)
      return CodesRepository.createCode(data)
        .then((response) => {
          setLoading(state, 'createCode', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'createCode', false)
          throw error
        })
    },

    generateCodesForCampaign({ state }, data) {
      setLoading(state, 'generateCodesForCampaign', true)
      return CodesRepository.generateCodesForCampaign(data.id, data.data)
        .then((response) => {
          setLoading(state, 'generateCodesForCampaign', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response
        })
        .catch(error => {
          setLoading(state, 'generateCodesForCampaign', false)
          throw error
        })
    }
  }
}
