import GiftRepository from '/src/store/repositories/GiftRepository'
import { setLoading } from '/src/helper.js'

export const GiftService = {
  namespaced: true,

  actions: {
    getGifts({ state }) {
      setLoading(state, 'getGifts', true)
      return GiftRepository.getGifts()
        .then(response => {
          setLoading(state, 'getGifts', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getGifts', false)

          throw error
        })
    },
  }
}
