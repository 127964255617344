// Extending the native Date object with functionality known to Carbon in PHP
export function addDays(date, days) {
  let newDate = new Date(date.toDateString())
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

// Utility function to check if the route has a guard and if so, check the permissions to permit access when applicable
export function routeInPermissions(route, permissions) {
  return route.meta.guard === undefined ||
    route.meta.guard === null ||
    permissions?.includes(route.meta.guard)
}

export function uniqueId() {
  return Date.now().toString(36)
}

export function setLoading(state, method, loading) {
  if (!state.loading) {
    state.loading = {}
  }

  state.loading[method] = loading
}
