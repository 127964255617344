import { createStore } from 'vuex'

import { AuthService }        from '/src/store/services/AuthService'
import { BrandService }       from '/src/store/services/BrandService'
import { CampaignService }    from '/src/store/services/CampaignService'
import { CodesService }       from '/src/store/services/CodesService'
import { CsvService }         from '/src/store/services/CsvService'
import { GeneralService }     from '/src/store/services/GeneralService'
import { GiftService }        from '/src/store/services/GiftService'
import { ManagerService }     from '/src/store/services/ManagerService'
import { ProductService }     from '/src/store/services/ProductService'
import { RetailersService }   from '/src/store/services/RetailersService'
import { RoleService }        from '/src/store/services/RoleService'
import { SerialMaskService }  from '/src/store/services/SerialMaskService'
import { UserService }        from '/src/store/services/UserService'

const store = createStore({
  modules: {
    AuthService:        AuthService,
    BrandService:       BrandService,
    CampaignService:    CampaignService,
    CodesService:       CodesService,
    CsvService:         CsvService,
    GeneralService:     GeneralService,
    GiftService:        GiftService,
    ManagerService:     ManagerService,
    ProductService:     ProductService,
    RetailersService:   RetailersService,
    RoleService:        RoleService,
    SerialMaskService:  SerialMaskService,
    UserService:        UserService,
  },

  mutations: {},
  getters: {
    loading(state) {
      return !Object.values(getStateStructure(state)).map(service => Object.values(service)).flat().every(Boolean)
    },

    structure(state) {
      return getStateStructure(state)
    }
  },

  state() {
    return {}
  }
})

function getStateStructure(state) {
  if (!state.structure) {
    state.structure = {}
  }

  Object.entries(state).forEach(([service, serviceData]) => {
    if (typeof serviceData.loading === 'object') {
      if (!state.structure[service]) {
        state.structure[service] = {}
      }

      Object.entries(serviceData.loading).forEach(([method, data]) => {
        state.structure[service][method] = !data
      })
    }
  })

  return state.structure
}

export default store
