import { createRouter, createWebHistory } from 'vue-router'

import { i18n, user } from '/src/main'
import { routeInPermissions } from '/src/helper'

import routes from '/src/router/routes'

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Before displaying any route, we make a couple of checks.
router.beforeEach(async(to, from, next) => {

  // These routes can only be accessed by redirecting from a specific page.
  if (to.meta.redirectFrom && to.meta.redirectFrom !== from.name) {
    return next('/login')
  }

  // If the route being requested does not require the user to be logged in, allow passage and stop further checks.
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    document.title = `${i18n.global.t(to.meta.title)} - ${i18n.global.t('app.title')}`
    return next()
  }

  // If the route requires authentication, we need a token. If the user does not have a token, let them re-login
  if (!localStorage.getItem('token')) {
    return next('/login')
  }

  // The route is checked against the permissions of the user, which we fetch if they aren't readily available.
  const permissions = user.data?.permissions ??
    await user.refresh()
      .then(user => user.data.permissions)
      .catch(() => false)

  if (!permissions) {
    return next('/login')
  }

  if (routeInPermissions(to, permissions)) {
    document.title = `${i18n.global.t(`routes.${to.meta.title}`)} - ${i18n.global.t('app.title')}`
    return next()
  }

  // If the route has a guard that doesn't show up in the permissions of the user,
  // send them back to the login/dashboard, depending on if they have a token.
  window.dispatchEvent(new CustomEvent('toast.make', {
    detail: {
      status: 'error',
      text: 'No access to route',
      timer: 5000
    }
  }))

  return next(from)
})

export default router
