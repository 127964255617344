<template>
  <div class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 mx-auto md:mx-24 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6">
      <v-avatar
        v-if="!!Object.keys(user).length"
        :user="user"
        theme="large"
      />
    </div>
    <div class="mt-20 px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 mx-auto md:mx-0 flex flex-col">
      <div class="mb-5">
        <label for="first-name" class="block text-sm text-[var(--NovikGray)]">{{ $t('general.firstname') }}</label>
        <v-input
          type="text"
          name="first-name"
          v-model="user.firstname"
          :debounced="true"
          autocomplete="given-name"
        />
      </div>

      <div class="mb-5">
        <label for="last-name" class="block text-sm text-[var(--NovikGray)]">{{ $t('general.lastname') }}</label>
        <v-input
          type="text"
          name="last-name"
          v-model="user.lastname"
          :debounced="true"
          autocomplete="family-name"
        />
      </div>

      <div class="mb-5">
        <label for="email" class="block text-sm text-[var(--NovikGray)]">{{ $t('general.email') }}</label>
        <v-input
          type="email"
          name="email"
          v-model="user.email"
          autocomplete="email"
          :debounced="true"
          :wait-time="2000"
          @update:modelValue="checkEmail(true)"
        />
      </div>

      <div class="mb-5">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('components.language') }}</p>
        <v-selectbox
          v-if="languages.data"
          v-model="user.language"
          :options="languages.data"
          :no-remove="true"
          theme="white"
          :keyValue="['id', 'name']"
        />
      </div>

      <div class="mb-5">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('components.brands') }}</p>
        <v-multiselect
          name="brands"
          v-model="user.brands"
          :entries="brands"
          :keyValue="['fqdn', 'name']"
          :searchable="true"
          :placeholder="$t('general.select')"
        />
      </div>

      <template v-if="user.brands.length">
        <div v-for="(brand, index) in user.brands" class="mb-5 text-[var(--NovikGray)]">
          <label for="roles">{{ brand.name }} {{ $t('components.roles').toLowerCase() }}</label>
          <div class="bg-white p-4 rounded-lg shadow-md">
            <div v-for="role in roles" class="flex justify-between p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
              <v-toggleswitch
                v-model="user.brands[index].roles"
                :entry="role"
                :keyValue="['id', 'name']"
              />
            </div>
          </div>
        </div>
      </template>

      <div class="mb-9 flex justify-end items-center">
        <a class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer" @click="$router.back()">{{ $t('general.cancel') }}</a>
        <v-button
          class="!w-max uppercase"
          theme="gray"
          @click="createUser"
          :disabled="createUserDisabled"
        >
          {{ $t('general.create') }} {{ $t('components.user') }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Button from '/src/components/functionality/Button.vue'
import Input from '/src/components/functionality/Input.vue'
import Modal from '/src/components/partials/Modal.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import multiSelect from '/src/components/functionality/MultiSelect.vue'
import Avatar from '/src/components/account/Avatar.vue'

export default {
  name: 'Add',

  components: {
    'v-button': Button,
    'v-input': Input,
    'v-modal': Modal,
    'v-toggleswitch': ToggleSwitch,
    'v-selectbox': SelectBox,
    'v-multiselect': multiSelect,
    'v-avatar': Avatar
  },

  data() {
    return {
      emailValidated: false,
      languages: [],
      brands: [],
      roles: [],
      user: {
        firstname: '',
        lastname: '',
        email: '',
        language: {},
        brands: []
      },
    }
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    }),

    createUserDisabled() {
      const userData = {}

      Object.entries(this.user).forEach(data => {
        switch(data[0]) {
          case 'firstname':
          case 'lastname':
            userData[data[0]] = !!data[1].length
            break
          case 'email':
            userData[data[0]] = this.emailValidated
            break
          case 'language':
            userData[data[0]] = !!data[1].id
            break
          case 'brands':
            userData[data[0]] = !!data[1].length && !data[1].filter(brand => !brand.roles.length).length
            break
        }
      })

      return !!Object.values(userData).filter(entry => !entry).length
    }
  },

  methods: {
    checkEmail(withResponse) {
      if( !this.user.email.length ) {
        return this.emailValidated = false
      }

      if(!/^\w+(\w|[\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)) {
        if (withResponse) {
          this.$root.$refs.toast.make({
            'status': 'warning',
            'text': this.$t('users.enterValidEmailWarning'),
            'timer': 2000
          })
        }

        return this.emailValidated = false
      }

      const self = this
      return this.$store.dispatch("UserService/verifyEmail", this.user.email)
        .then(response => {
          switch( response.data.data.type ) {
            case 'EmailExist':
            case 'EmailInActive':
            case 'EmailIsDeleted':
            case 'EmailExistInOtherBrands':
              if (withResponse) {
                self.$root.$refs.toast.make( {
                  'status': 'error',
                  'text': response.data.status.message,
                  'timer': false
                })
              }

              return self.emailValidated = false
            default:
              return self.emailValidated = true
          }
        })
        .catch(error => {
          if (withResponse) {
            self.$root.$refs.toast.make( {
              'status': 'error',
              'text': error.data.status.message,
              'timer': false
            })
          }

          return self.emailValidated = false
        })
    },

    createUser() {
      this.$store.dispatch("UserService/addUser", this.user)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })

          this.$router.push({ name: 'Users' })
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.data.status.message,
            'timer': false
          })
        })
    },

    fetchData() {
      this.$store.dispatch("RoleService/getRoles")
        .then( data => {
          this.roles = data
        })
        .catch(function(error) {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error,
            'timer': false
          })
        })

      this.$store.dispatch("UserService/getLanguages")
        .then(data => {
          this.languages = data
        })

      this.$store.dispatch("BrandService/getBrands")
        .then(data => {
          this.brands = data

          this.brands.forEach(brand => brand.roles = [])
        })
    }
  }
}
</script>
