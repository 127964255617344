<template>
  <div class="flex w-full">
    <span>
      {{ range.start }}
    </span>

    <div class="relative mx-4 flex-grow self-center w-full h-1">
      <div class="px-3">
        <span class="hidden tooltip object-center flex flex-col content-center relative -mx-1 bottom-12 py-1 px-3 w-max rounded-lg text-white bg-[var(--NovikGreen)]">
          {{ modelValue }}
          <div class="absolute w-2 h-2 self-center -bottom-1 rotate-45 bg-[var(--NovikGreen)]"></div>
        </span>
      </div>
      <input
        type="range"
        class="w-full h-1 absolute top-0 appearance-none bg-transparent cursor-pointer"
        :min="range.start"
        :max="range.end"
        :value="modelValue"
        @input="updateRange()"
        @mousedown="toggleTooltip"
        @mouseup="updateRange(); toggleTooltip()"
      >
    </div>

    <span>
      {{ range.end }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Range',

  props: {
    range: {},
    modelValue: Number
  },

  emits: [
    'update:modelValue'
  ],

  methods: {
    updateRange() {
      let range = this.$el.querySelector('input[type=range]')

      this.setTooltipPosition(range)

      range.style.setProperty('--rangeTrackWidth', `${range.value / this.range.end * 100}%`)

      this.$emit('update:modelValue', Number(range.value))
    },

    toggleTooltip() {
      let range = this.$el.querySelector('input[type=range]')
      let tooltip = range.parentNode.querySelector('.tooltip')

      this.setTooltipPosition(range)

      tooltip.classList.toggle('hidden')
    },

    setTooltipPosition(range) {
      let tooltip = range.parentNode.querySelector('.tooltip')

      let tooltipWidth = tooltip.offsetWidth
      if (!tooltipWidth) {
        tooltip.classList.toggle('invisible')
        tooltip.classList.toggle('hidden')

        tooltipWidth = tooltip.offsetWidth

        tooltip.classList.toggle('hidden')
        tooltip.classList.toggle('invisible')
      }
      tooltip.style.setProperty('margin-left', `calc(${range.value / this.range.end * 100}% - ${tooltipWidth / 2}px)`)
    }
  },

  mounted() {
    this.updateRange()
  },

  watch: {
    modelValue: {
      handler() {
        this.updateRange()
      }
    }
  }
}
</script>
