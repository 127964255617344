import ProductRepository from '/src/store/repositories/ProductRepository'
import CampaignRepository from '/src/store/repositories/CampaignRepository'
import { setLoading } from '/src/helper.js'

export const ProductService = {
  namespaced: true,

  actions: {
    getProducts({ state }) {
      setLoading(state, 'getProducts', true)
      return ProductRepository.getProducts()
        .then((response) => {
            setLoading(state, 'getProducts', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getProducts', false)
          throw error
        })
    },

    getProduct({ state }, id) {
      setLoading(state, 'getProduct', true)
      return ProductRepository.getProduct(id)
        .then((response) => {
          setLoading(state, 'getProduct', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getProduct', false)
          throw error
        })
    },

    getSerialMasks({ state }) {
      setLoading(state, 'getSerialMasks', true)
      return ProductRepository.getSerialMasks()
        .then((response) => {
          setLoading(state, 'getSerialMasks', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getSerialMasks', false)
          throw error
        })
    },

    getLanguages({ state }) {
      return CampaignRepository.getLanguages()
        .then((response) => {
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          throw error
        })
    },

    createProduct ({ state }, product) {
      return ProductRepository.createProduct(product)
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }
          return response
        })
        .catch(error => {
          throw error
        })
    },

    updateProduct ({ state }, product) {
      return ProductRepository.updateProduct(product)
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }
          return response
        })
        .catch(error => {
          throw error
        })
    },

    updateProductImage ({ state }, data) {
      return ProductRepository.updateProductImage(data)
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }
          return response
        })
        .catch(error => {
          throw error
        })
    },

    removeProductImage ({ state }, data) {
      return ProductRepository.removeProductImage(data)
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }
          return response
        })
        .catch(error => {
          throw error
        })
    },
  }
}
