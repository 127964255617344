<template>
  <template v-if="isActive">
    <p class="text-[var(--NovikGray)]">{{ $t('campaign.questionnaire') }}</p>
    <v-card>
      <v-button-row
        v-model="activeLanguage"
        :options="campaign.languages"
        :keyValue="['id', 'name']"
        :class="{'mb-5': activeLanguage > 0}"
      />
      <v-data-organizer
        v-if="!!campaign.questionnaire && this.activeLanguage > 0"
        v-model="campaign.questionnaire"
        :activeLanguage="activeLanguage"
        :languages="campaign.languages"
        @update:modelValue="
          this.$emit(
          'commitData',
          {
            endpoint: '/questionnaire',
            data: {
              campaign_questionnaire: this.campaign.questionnaire
            }
          }
        )"
      />
    </v-card>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <router-link
        v-if="isFinalStep"
        :to="{name: 'Campaigns'}"
      >
        <v-button
          class="!w-max uppercase"
          theme="gray"
        >
          {{ $t('general.finish') }}
        </v-button>
      </router-link>
      <v-button
        v-else
        class="!w-max uppercase"
        @clicked="$emit('nextStep')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Button from '/src/components/functionality/Button.vue'
import Card from '/src/components/partials/Card.vue'
import DataOrganizer from '/src/components/questionnaire/DataOrganizer.vue'
import ButtonRow from '/src/components/functionality/ButtonRow.vue'

export default {
  name: 'Questionnaire',

  components: {
    'v-button-row': ButtonRow,
    'v-button': Button,
    'v-card': Card,
    'v-data-organizer': DataOrganizer,
  },

  data() {
    return {
      activeLanguage: 0
    }
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      return [true]
    }
  },

  methods: Step.methods,

  watch: {
    ...Step.watch,

    campaign: {
      handler() {
        if (!this.campaign.languages.filter(entry => entry.id === this.activeLanguage).length) {
          this.activeLanguage = this.campaign.languages[0].id
        }
      },
      deep: true
    }
  }
}
</script>
