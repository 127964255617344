import axios from '/src/store/utility/Request'

class CampaignRepository {
  getCampaignTypes() {
    return axios.get('campaigns/type')
  }

  getLanguages() {
    return axios.get('languages')
  }

  getCountries() {
    return axios.get('countries')
  }

  getThirdParties() {
    return axios.get('third-party')
  }

  getTexts() {
    return axios.get('campaign_text')
  }

  getTextsByCampaignTypeId(id) {
    return axios.get(`campaigns/type/${id}/campaign_text`)
  }

  getCodesByCampaignId(id) {
    return axios.get(`codes/batch/campaign/${id}`)
  }

  getSettings() {
    return axios.get('campaigns/settings')
  }

  getCampaigns() {
    return axios.get('campaigns')
  }

  getCampaignDetail( id ) {
    return axios.get(`campaigns/${id}`)
  }

  addCampaign( data ) {
    return axios.post('campaigns', data)
  }

  commitData( data ) {
    return axios.put(`campaigns/${data.id}${data.endpoint}`, JSON.stringify(data.data))
  }

  updateCampaign( data ) {
    return axios.put(`campaigns/${data.id}`, data)
  }
}

export default new CampaignRepository()
