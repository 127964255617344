import axios from '/src/store/utility/Request'

class GeneralRepository {
  getGeneralSettings() {
    return axios.get('general/settings')
  }

  updateGeneralSettings(data) {
    return axios.put('general/settings', data)
  }

  getDomains() {
    return axios.get('general/domains')
  }

  getCountries() {
    return axios.get('countries')
  }

  updateDomains(data) {
    return axios.put('general/domains', data)
  }
}

export default new GeneralRepository()
