<template>
  <div id="login-page" class="flex flex-col h-screen items-center justify-center bg-[var(--NovikLightGreen)]">
    <img class="w-72 mb-20" src="/src/assets/img/novik-logo.svg" alt="logo">

    <div v-if="message" class="form-group">
      <div class="text-[var(--NovikRed)]" role="alert">
        <span>{{ $t(message) }}</span>
      </div>
    </div>

    <form @submit.prevent="sendPassword" class="w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/4 xl:w-1/5">
      <div class="mb-9">
        <label for="password" class="block text-sm font-medium text-gray-700">
          {{ $t('components.password.new') }}
        </label>
        <v-input
          type="password"
          name="password"
          v-model="password"
          autocomplete="off"
          :required="true"
          placeholder="*******************"
        />
      </div>

      <ul class="mb-9">
        <li v-for="validation in validations" class="text-[var(--NovikGray)]" :class="{ is_valid: validation.key }">
          <i class="icon-check-mark" :class="validation.key ? 'text-[var(--NovikGreen)]' : 'text-[var(--NovikGray)]'" />
          {{ validation.text }}
        </li>
      </ul>

      <v-button type="submit" theme="gray" :disabled="!validPassword" class="uppercase font-bold">
        {{ $t('components.password.reset') }}
      </v-button>
    </form>
  </div>
</template>

<script>
import Input from '/src/components/functionality/Input.vue'
import Button from '/src/components/functionality/Button.vue'
import { computed } from 'vue'

export default {
  name: 'ResetPassword',

  components: {
    'v-input': Input,
    'v-button': Button
  },

  data () {
    return {
      message: null,
      password: null,
      validations: [
        {
          'key': computed(() => this.containsEightCharacters),
          'text': this.$t('components.validations.rules.no-of-char')
        },
        {
          'key': computed(() => this.containsNumber),
          'text': this.$t('components.validations.rules.number')
        },
        {
          'key': computed(() => this.containsUppercase),
          'text': this.$t('components.validations.rules.uppercase')
        },
        {
          'key': computed(() => this.containsSpecialCharacter),
          'text': this.$t('components.validations.rules.specialchar')
        }
      ]
    }
  },

  computed: {
    containsEightCharacters() {
      if (!this.password) {
        return false
      }

      return this.password.length >= 8
    },

    containsNumber() {
      if (!this.password) {
        return false
      }

      return /\d/.test(this.password)
    },

    containsUppercase() {
      if (!this.password) {
        return false
      }

      return /[A-Z]/.test(this.password)
    },

    containsSpecialCharacter() {
      if (!this.password) {
        return false
      }

      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
      return format.test(this.password)
    },

    validPassword() {
        return (
          this.containsEightCharacters &&
          this.containsNumber &&
          this.containsUppercase &&
          this.containsSpecialCharacter
        )
    }
  },

  methods: {
    sendPassword () {
      this.message = null

      if (!this.validPassword) {
        return
      }

      this.$store.dispatch('AuthService/resetPassword', {
        password: this.password,
        token: this.$route.params.token
      })
        .then(() => {
          this.$router.push({ name: 'resetSuccess' })
        })
        .catch(error => {
          this.message = error.message
        })
    }
  }
}
</script>
