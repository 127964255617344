<template>
  <footer class="hidden sm:flex bg-[var(--NovikGray)] w-full h-10 z-20 items-center justify-self-end justify-end">
    <p class="text-white mr-6">&copy; {{ $t('general.copyright') }}</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
