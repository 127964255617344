<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full bg-[var(--EntityBG)]">
    <div v-if="sortedItems.length">
      <v-table-view-header
        ref="tableViewHeader"
        v-model="sortDescending"
        :entity="$t('components.role(s)')"
        :deletable="true"
        :delete-clause="!!this.rolesSelected.length"
        @deleteSelection="deleteSelection"
        :searchbar="searchbar"
        :results="visibleItems !== sortedItems.length ? `${visibleItems} ${$t('general.of')} ${sortedItems.length}` : sortedItems.length"
      />

      <template
        v-for="role in sortedItems"
      >
        <div
          v-if="searchables.map(entry => {
            return String(role[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
          }).some(v => v === true)"
          class="
            border-b
            border-[var(--EntityDarkBG)]
            duration-200
            transition-[background-color]
            hover:bg-[var(--EntityDarkBG)]
            flex
          "
        >
          <v-checkbox
            class="pl-5"
            v-model="rolesSelected"
            :option="role"
            theme="white"
            :showText="false"
            :keyValue="['id', 'name']"
          />

          <router-link
            v-if="routeInPermissions(this.$router.resolve({ name: 'UsersDetail', params: { id: role.id } }), permissions)"
            class="flex items-center"
            :to="{ name: 'RolesDetail', params: { id: role.id } }"
          >
            <div class="ml-5 my-2">
              <p
                class="text-[var(--NovikGray)]"
                v-html="highlightSubstringInString(searchbar.request, role.name)"
              />
              <span class="text-[var(--NovikLightGray)]">{{ role.description }}</span>
            </div>
          </router-link>
        </div>
      </template>
    </div>

    <div v-else>
      <h1 class="text-xl text-[var(--NovikGray)] m-5">{{ $t("general.no-results") }}</h1>
    </div>

    <v-add entity="RolesAdd"/>
  </div>
</template>

<script>
import tableView from '/src/views/abstracts/tableView'

export default {
  name: 'Roles',

  components: {
    ...tableView.components
  },

  data() {
    return {
      ...tableView.data,
      toast: this.$root.$refs.toast,
      rolesSelected: [],
      roles: [],
      searchables: ['name']
    }
  },

  inject: {
    ...tableView.inject
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...tableView.computed,

    visibleItems() {
      let count = 0

      this.sortedItems.forEach(item => {
        if (this.searchables.map(entry => {
          return String(item[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
        }).some(v => v === true)) {
          count++
        }
      })

      return count
    },

    sortedItems() {
      if (!this.roles) {
        return []
      }

      const data = tableView.computed.sortData(this.roles, 'name')

      if (this.sortDescending) {
        return data.reverse()
      }

      return data
    }
  },

  methods: {
    ...tableView.methods,

    fetchData() {
      this.$store.dispatch("RoleService/getRoles")
        .then(data => {
          this.roles = data
        })
    },

    deleteSelection() {
      this.roles
        .filter(role => Object.values(this.rolesSelected).includes(String(role.id)))
        .forEach(role => {
            this.$store.dispatch("RoleService/deleteRole", role.id)
              .then((response) => {
                this.toast.make({
                  'status': 'success',
                  'text': response.data.status.message,
                  'timer': 5000
                })

                this.fetchData()
              })
              .catch((error) => {
                this.toast.make({
                  'status': 'error',
                  'text': error.status?.error.message ?? error,
                  'timer': false
                })
              })
        })

      this.$refs.tableViewHeader.$refs.deleteModal.close()
    }
  }
}
</script>
