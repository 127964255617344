import RoleRepository from '/src/store/repositories/RoleRepository'
import { setLoading } from '/src/helper.js'

export const RoleService = {
  namespaced: true,

  actions: {
    getRoles ({ state }) {
      setLoading(state, 'getRoles', true)
      return RoleRepository.getRoles()
        .then(response => {
          setLoading(state, 'getRoles', false)
          if (response.data.status.code !== 200) {
            throw response.data.status.error
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getRoles', false)
          throw error.data.status.error
        })
    },

    getRolesDetail ({ state }, id) {
      setLoading(state, 'getRolesDetail', true)
      return RoleRepository.getRoleDetail(id)
        .then((response) => {
          setLoading(state, 'getRolesDetail', false)
          if (response.data.status.code !== 200) {
            throw response.data.status.error
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getRolesDetail', false)
          throw error.data.status.error
        })
    },

    addRole({ state }, data) {
      setLoading(state, 'addRole', true)
      return RoleRepository.addRole(data)
        .then((response) => {
          setLoading(state, 'addRole', false)
          if (response.data.status.code === 200) {
            return response
          } else {
            throw response
          }
        })
        .catch(error => {
          setLoading(state, 'addRole', false)
          throw error.data.status.error
        })
    },

    updateRole({ state }, data) {
      setLoading(state, 'updateRole', true)
      return RoleRepository.updateRole(data)
        .then((response) => {
          setLoading(state, 'updateRole', false)
          if (response.data.status.code === 200) {
            return response
          } else {
            throw response
          }
        })
        .catch(error => {
          setLoading(state, 'updateRole', false)
          throw error.data.status.error
        })
    },

    deleteRole ({ state }, id) {
      setLoading(state, 'deleteRole', true)
      return RoleRepository.deleteRole(id)
        .then(response => {
          setLoading(state, 'deleteRole', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response
        })
        .catch(error => {
          setLoading(state, 'deleteRole', false)

          throw error
        })
    },

    getPermissions ({ state }) {
      setLoading(state, 'getPermissions', true)
      return RoleRepository.getPermissions()
        .then(response => {
          setLoading(state, 'getPermissions', false)
          if (response.data.status.code !== 200) {
            throw response.data.status.error
          } else {
            return Object.keys(response.data.data)
          }
        })
        .catch(error => {
          setLoading(state, 'getPermissions', false)
          throw error.data.status.error
        })
    },

    getPermissionsByRoleId ({ state }, roleId) {
      setLoading(state, 'getPermissionsByRoleId', true)
      return RoleRepository.getPermissionsByRoleId(roleId)
        .then(response => {
          setLoading(state, 'getPermissionsByRoleId', false)
          if (response.data.status.code !== 200) {
            throw response.data.status.error
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getPermissionsByRoleId', false)
          throw error.data.status.error
        })
    }
  }
}
