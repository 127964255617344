<template>
  <router-link
    :to="{ name: link.name }"
    class="
      transition
      duration-200
      hover:bg-[var(--EntityBG)]
      border-b
      border-[var(--EntityDarkBG)]
      flex
      items-center
      py-5
      text-[var(--NovikGray)]
    "
    :class="extraClasses"
    @click="$emit('clicked')"
  >
    <div class="h-8 mr-6 flex justify-center items-center">
      <i
        v-if="link.meta.title.toLowerCase() !== 'profile'"
        :class="`icon-menu-${link.meta.title.toLowerCase()}`"
        class="w-6 px-1 text-2xl"
        :title="$t(`routes.${link.meta.title}`)"
      />
      <v-avatar
        v-else
        class="!w-8 h-8"
        v-if="!!Object.keys(currentUser).length"
        :user="currentUser"
        theme="small"
      />
    </div>
    <p>{{ $t(`routes.${link.meta.title}`) }}</p>
  </router-link>

  <template v-for="route in siblings">
    <router-link
      v-show="isWindowMobile"
      class="text-[var(--NovikGray)]"
      :to="{ name: route.name }"
      @click="$emit('clicked')"
    >
      <div
        class="
          flex
          justify-between
          pl-12
          bg-[var(--EntityBG)]
          hover:bg-[var(--EntityDarkBG)]
          border-b
          border-[var(--EntityDarkBG)]
        "
      >
        <p class="py-4">{{ $t(`routes.${route.name}`) }}</p>
      </div>
    </router-link>
  </template>
</template>

<script>
import Avatar from '/src/components/account/Avatar.vue'

export default {
  name: 'MenuLink',

  components: {
    'v-avatar': Avatar
  },

  props: {
    link: {},
    extraClasses: String,
    subMenu: {} | null
  },

  emits: [
    'clicked'
  ],

  inject: {
    currentUser: {
      from: 'currentUser'
    },
    isWindowMobile: {
      from: 'isWindowMobile'
    },
    exactRouteName: {
      from: 'exactRouteName'
    }
  },

  computed: {
    siblings() {
      if (this.$route.matched.length && this.$route.matched[0].name === this.link.name) {
        return this.subMenu
      }
    }
  }
}
</script>
