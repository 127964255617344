<template>
  <div
    class="
      bg-white
      w-full
      h-full
      fixed
      z-50
      flex
      justify-center
      items-center
      opacity-100
      ease-in-out
      select-none
    "
    :class="{
      'animation-fade-out hidden': !overlay
    }"
  >
    <img
      class="motion-safe:animate-pulse"
      width="225"
      height="280"
      src="/src/assets/img/novik-icon.svg"
      alt="Novik logo"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PageLoader',

  inject: {
    currentUser: {
      from: 'currentUser'
    }
  },

  computed: {
    ...mapGetters({
      structure: 'structure',
    }),

    overlay() {
      return !(
        !!this.structure.UserService?.getTranslations &&
        (
          this.currentUser === null ||
          (
            this.currentUser &&
            !!this.structure.UserService?.getCurrentUser
          )
        )
      )
    }
  }
}
</script>
