<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full flex flex-col">
    <div class="p-4 bg-[var(--NovikDarkGreen)]">
      <v-tracker
        v-model="activeStep"
        theme="light"
        :steps="tracker.steps"
        :no-text="true"
      />
    </div>

    <div
      class="
        mt-20
        px-4
        md:px-0
        w-full
        sm:w-2/3
        md:w-3/5
        mx-auto
        md:mx-0
        flex
        flex-col
        self-center"
    >
      <template v-for="step in tracker.steps">
        <component
          :is="`Step${step.stepNum}`"
          :isFinalStep="step.stepNum === stepCount"
          :isActive="step.stepNum === activeStep"
          :campaign="campaign"
          :campaignTypes="campaignTypes"

          @finished="(finished) => completeStep(step.stepNum, finished)"
          @createCampaign="createCampaign"
          @prevStep="prevStep"
          @nextStep="nextStep"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Loader from '/src/components/partials/Loader.vue'
import Tracker from '/src/components/functionality/Tracker.vue'

import Types from '/src/components/campaign/Types.vue'
import Copy from '/src/components/campaign/Copy.vue'
import Locale from '/src/components/campaign/Locale.vue'

export default {
  name: 'Add',

  components: {
    'v-loader': Loader,
    'v-tracker': Tracker,

    'Step1': Types,
    'Step2': Copy,
    'Step3': Locale,
  },

  data() {
    return {
      toast: this.$root.$refs.toast,
      campaignTypes: [],
      campaign: {
        name: '',
        campaign_type_id: ''
      },
      entryStep: 1,
      activeStep: Number,
      stepCount: 9,
      tracker: {
        steps: []
      },
    }
  },

  mounted() {
    for (let i = 1; i <= this.stepCount; i++) {
      this.tracker.steps.push({stepNum: i, disabled: null})
      if (i > 3) {
        this.tracker.steps[i - 1].disabled = true
      }
    }

    if (this.$route.query.step && this.tracker.steps[this.$route.query.step - 1]) {
      if (!this.tracker.steps[this.$route.query.step - 1].disabled) {
        this.activeStep = Number(this.$route.query.step)
      } else {
        this.activeStep = this.entryStep
      }
    } else {
      this.activeStep =  this.entryStep
    }

    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    routeQueryStep() {
      if (
        !this.$route.query.step ||
        !this.tracker.steps[this.$route.query.step - 1]
      ) {
        return this.entryStep
      }

      if (this.tracker.steps[this.$route.query.step - 1].disabled) {
        return this.entryStep
      }

      return Number(this.$route.query.step)
    },

    ...mapGetters({
      loading: 'loading',
    })
  },

  methods: {
    fetchData() {
      this.$store.dispatch('CampaignService/getCampaignTypes')
        .then(data => {
          this.campaignTypes = data
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': false
          })
        })
    },

    createCampaign() {
      this.$store.dispatch("CampaignService/addCampaign", this.campaign)
        .then((response) => {
          this.toast.make({
            'status': 'success',
            'text': response.status.message,
            'timer': 2000
          })
          this.$router.push({ name: 'CampaignsDetail', params: { id: response.data.id } })
        })
        .catch((error) => {
          this.toast.make({
            'status': 'error',
            'text': error,
            'timer': false
          })
        })
    },

    completeStep(step, finished) {
      this.tracker.steps.find((trackerStep) => {
        if (trackerStep.stepNum === step) {
          trackerStep.finished = finished
        }
      })
    },

    prevStep() {
      // Show previous step
      this.activeStep = Number(this.activeStep) - 1

      // Update route to current step
      this.$router.push({
        path:this.$route.path,
        query:{
          step: this.activeStep
        }
      })
    },

    nextStep() {
      // Show next step
      this.activeStep = Number(this.activeStep) + 1

      // Update route to current step
      this.$router.push({
        path:this.$route.path,
        query:{
          step: this.activeStep
        }
      })
    }
  },

  watch: {
    routeQueryStep: {
      handler() {
        if (this.tracker.steps.length && this.tracker.steps[this.routeQueryStep - 1].disabled) {
          this.activeStep = this.entryStep
        } else {
          this.activeStep = this.routeQueryStep
        }
      },
      immediate: true
    }
  }
}
</script>
