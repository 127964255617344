<template>
  <template v-if="isActive">
    <div class="mb-9 text-[var(--NovikGray)]">
      <label for="campaign_name" class="w-full">
        {{ $t('components.campaign') }} {{ $t('general.name') }}
      </label>
      <v-input
        name="campaign_name"
        v-model="campaign.name"
        :debounced="!!campaign.id"
        @update:modelValue="(args) => fieldUpdated(args)"
      />
    </div>

    <template v-if="!!campaign.id">
      <div class="mb-5 text-[var(--NovikGray)]">
        {{ $t('general.languages') }}
        <div class="mb-9 flex flex-col justify-between bg-white p-2 rounded-md shadow-md">
          <div
            class="flex justify-between p-2 text-sm rounded hover:bg-[var(--EntityBG)]"
            v-for="language in campaignLanguages"
          >
            <v-toggleswitch
              v-model="campaign.languages"
              @update:modelValue="
                this.$emit(
                  'commitData',
                  {
                    endpoint: '/languages',
                    data: campaign.languages
                  }
                )"
              :entry="language"
              :keyValue="['code', 'name']"
              :debounced="true"
            />
          </div>
        </div>
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        {{ $t('general.countries') }}
        <button
          class="float-right ml-5 hover:underline"
          @click="addAll(campaign.countries, campaignCountries)"
        >
          {{ $t('components.add-all') }}
        </button>
        <button
          class="float-right ml-5 hover:underline"
          @click="removeAll()"
        >
          {{ $t('components.remove-all') }}
        </button>
        <v-multi-select
          name="countries"
          v-model="campaign.countries"
          :entries="campaignCountries"
          :searchable="true"
          :keyValue="['id', 'country']"
          :placeholder="$t('general.multiselect')"
          @update:modelValue="
            this.$emit(
              'commitData',
              {
                endpoint: '/countries',
                data: campaign.countries
              }
            )"
        />
      </div>
    </template>

    <div class="mb-9 flex justify-end items-center">
      <a
        href="#"
        class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
        @click="$emit('prevStep')"
      >
        {{ $t('general.previous') }}
      </a>

      <v-button
        v-if="!campaign.id"
        class="!w-max uppercase"
        @clicked="$emit('createCampaign')"
        theme="gray"
        :disabled="nextDisabled"
      >
        {{ $t('general.create') }} {{ $t('components.campaign') }}
      </v-button>
      <template v-else>
        <router-link
          v-if="isFinalStep"
          :to="{name: 'Campaigns'}"
        >
          <v-button
            class="!w-max uppercase"
            theme="gray"
          >
            {{ $t('general.finish') }}
          </v-button>
        </router-link>
        <v-button
          v-else
          class="!w-max uppercase"
          @clicked="$emit('nextStep')"
          theme="gray"
          :disabled="nextDisabled"
        >
          {{ $t('general.next') }}
        </v-button>
      </template>
    </div>
  </template>
</template>

<script>
import Step from '/src/components/campaign/Step'

import Input from '/src/components/functionality/Input.vue'
import Button from '/src/components/functionality/Button.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import MultiSelect from '/src/components/functionality/MultiSelect.vue'

export default {
  name: 'Locale',

  components: {
    'v-input': Input,
    'v-button': Button,
    'v-toggleswitch': ToggleSwitch,
    'v-multi-select': MultiSelect,
  },

  props: Step.props,
  emits: Step.emits,

  computed: {
    ...Step.computed,

    requiredFields() {
      return [
        !this.nextDisabled,
        !!this.campaign.languages?.length,
        !!this.campaign.countries?.length
      ]
    },

    nextDisabled() {
      return (
        this.campaign.campaign_type_id === '' ||
        this.campaign.name === ''
      )
    }
  },

  methods: {
    fieldUpdated(setting) {
      if (!this.campaign.id) {
        return
      }

      if (!!setting.length) {
        this.$emit(
          'commitData',
          {
            endpoint: '',
            data: {'campaign_type_id': this.campaign.campaign_type.id, 'name': this.campaign.name }
          })

        return
      }

      this.$root.$refs.toast.make({
        'status': 'warning',
        'text': this.$t('campaigns.name-required'),
        'timer': 2000
      })
    },

    addAll(object, entries) {
      object.splice(0, object.length, ...entries)

      this.commitCountries()
    },

    removeAll() {
      this.campaign.countries = []

      this.commitCountries()
    },

    commitCountries() {
      this.$emit(
        'commitData',
        {
          endpoint: 'countries',
          data: this.campaign.countries
        })
    }
  },

  watch: Step.watch
}
</script>
