import RetailersRepository from '/src/store/repositories/RetailersRepository'
import { setLoading } from '/src/helper.js'

export const RetailersService = {
  namespaced: true,

  actions: {
    getRetailers({ state }) {
      setLoading(state, 'getRetailers', true)
      return RetailersRepository.getRetailers()
        .then((response) => {
          setLoading(state, 'getRetailers', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getRetailers', false)
          throw error
        })
    },

    getRetailer({ state }, id) {
      setLoading(state, 'getRetailer', true)
      return RetailersRepository.getRetailer(id)
        .then((response) => {
          setLoading(state, 'getRetailer', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getRetailer', false)
          throw error
        })
    },

    createRetailer({ state }, data) {
      setLoading(state, 'createRetailer', true)
      return RetailersRepository.createRetailer(data)
        .then((response) => {
          setLoading(state, 'createRetailer', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'createRetailer', false)
          throw error
        })
    },

    updateRetailer({ state }, data) {
      setLoading(state, 'updateRetailer', true)
      return RetailersRepository.updateRetailer(data)
        .then((response) => {
          setLoading(state, 'updateRetailer', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'updateRetailer', false)
          throw error
        })
    },

    deleteRetailer({ state }, id) {
      setLoading(state, 'deleteRetailer', true)
      return RetailersRepository.deleteRetailer(id)
        .then((response) => {
          setLoading(state, 'deleteRetailer', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'deleteRetailer', false)
          throw error
        })
    },
  }
}
