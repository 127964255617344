<template>
  <div class="flex divide-x divide-x-reverse w-full md:w-max">
    <v-button
      v-for="option in options"
      @click="this.$emit('update:modelValue', option[keyValue[0]])"
      class="!px-1 md:!px-3 first:rounded-l rounded-none last:rounded-r"
      :theme="modelValue === option[keyValue[0]] ? 'gray' : 'white'"
      :disabled="disabled"
    >
      {{ option[keyValue[1]] }}
    </v-button>
  </div>
</template>

<script>
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'ButtonRow',

  components: {
    'v-button': Button
  },

  props: {
    options: {},
    modelValue: String|Number,
    disabled: Boolean,
    keyValue: Array
  },

  emits: [
    'update:modelValue'
  ]
}
</script>
