import CampaignRepository from '/src/store/repositories/CampaignRepository'
import { setLoading } from '/src/helper.js'

export const CampaignService = {
  namespaced: true,

  actions: {
    getCampaignTypes({ state }) {
      setLoading(state, 'getCampaignTypes', true)
      return CampaignRepository.getCampaignTypes()
        .then((response) => {
          setLoading(state, 'getCampaignTypes', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getCampaignTypes', false)
          throw error
        })
    },

    getLanguages({ state }) {
      setLoading(state, 'getLanguages', true)
      return CampaignRepository.getLanguages()
        .then((response) => {
          setLoading(state, 'getLanguages', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getLanguages', false)
          throw error
        })
    },

    getCountries({ state }) {
      setLoading(state, 'getCountries', true)
      return CampaignRepository.getCountries()
        .then((response) => {
          setLoading(state, 'getCountries', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getCountries', false)
          throw error
        })
    },

    getThirdParties({ state }) {
      setLoading(state, 'getThirdParties', true)
      return CampaignRepository.getThirdParties()
        .then((response) => {
          setLoading(state, 'getThirdParties', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getThirdParties', false)
          throw error
        })
    },

    getTexts({ state }) {
      setLoading(state, 'getTexts', true)
      return CampaignRepository.getTexts()
        .then((response) => {
          setLoading(state, 'getTexts', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getTexts', false)
          throw error
        })
    },

    getTextsByCampaignTypeId({ state }, id) {
      return CampaignRepository.getTextsByCampaignTypeId(id)
        .then((response) => {
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          throw error
        })
    },

    getCodesByCampaignTypeId({ state }, id) {
      return CampaignRepository.getCodesByCampaignId(id)
        .then((response) => {
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          throw error
        })
    },

    getSettings({ state }) {
      setLoading(state, 'getSettings', true)
      return CampaignRepository.getSettings()
        .then((response) => {
          setLoading(state, 'getSettings', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getSettings', false)
          throw error
        })
    },

    getCampaigns({ state }) {
      setLoading(state, 'getCampaigns', true)
      return CampaignRepository.getCampaigns()
        .then((response) => {
          if (response.data.status.code === 200) {
            setLoading(state, 'getCampaigns', false)

            return response.data.data
          } else {
            throw response.data.status
          }
        })
        .catch(response => {
          setLoading(state, 'getCampaigns', false)
          throw response.message
        })
    },

    getCampaign({ state }, id) {
      setLoading(state, 'getCampaign', true)
      return CampaignRepository.getCampaignDetail(id)
        .then((response) => {
          if (response.data.status.code === 200) {
            setLoading(state, 'getCampaign', false)

            return response.data.data
          } else {
            throw response
          }
        })
        .catch(response => {
          setLoading(state, 'getCampaign', false)
          throw response
        })
    },

    commitData({ state }, data) {
      return CampaignRepository.commitData(data)
        .then((response) => {
          if (response.data.status.code === 200 || response.data.status.code === 207) {
            return response.data
          } else {
            throw response.data.status.error
          }
        })
        .catch(error => {
          throw error
        })
    },

    updateCampaign({ state }, data) {
      setLoading(state, 'updateCampaign', true)
      return CampaignRepository.updateCampaign(data)
        .then((response) => {
          if (response.data.status.code === 200) {
            setLoading(state, 'updateCampaign', false)

            return response.data
          } else {
            throw response.data.status.error
          }
        })
        .catch(error => {
          setLoading(state, 'updateCampaign', false)
          throw error
        })
    },

    addCampaign({ state }, data) {
      setLoading(state, 'addCampaign', true)
      return CampaignRepository.addCampaign(data)
        .then(response => {
          setLoading(state, 'addCampaign', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data
          }
        })
        .catch(error => {
          setLoading(state, 'addCampaign', false)
          throw error.response.data.status.message
        })
    }
  }
}
