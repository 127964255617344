import { mapGetters } from 'vuex'
import { routeInPermissions } from '/src/helper'
import { RouterLink } from 'vue-router'

import Add from '/src/components/partials/Add.vue'
import CheckBox from '/src/components/functionality/CheckBox.vue'
import Modal from '/src/components/partials/Modal.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import Loader from '/src/components/partials/Loader.vue'
import Input from '/src/components/functionality/Input.vue'

import TableViewHeader from '/src/components/partials/TableViewHeader.vue'


export default {
  components: {
    'v-add': Add,
    'v-checkbox': CheckBox,
    'v-modal': Modal,
    'v-toggleswitch': ToggleSwitch,
    'v-loader': Loader,
    'v-input': Input,
    'v-table-view-header': TableViewHeader,
    'v-router-link': RouterLink,
  },

  data: {
    sortDescending: false,
    searchbar: {
      visible: false,
      request: ''
    },
  },

  inject: {
    permissions: {
      from: 'permissions'
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
    }),

    sortData(data, key) {
      return data.sort(function(a, b) {
        return a[key].localeCompare(b[key])
      })
    }
  },

  methods: {
    routeInPermissions,

    highlightSubstringInString(substring, string) {
      if (!substring.length) {
        return string
      }

      string = String(string)

      const indexOfSubstring = string.toLowerCase().indexOf(substring.toLowerCase())
      const sliceOfString = string.slice(
        indexOfSubstring,
        indexOfSubstring + substring.length
      )

      const splitString = string.split(sliceOfString)

      const highlight = document.createElement('span')
      highlight.innerHTML = sliceOfString
      highlight.style.color = 'var(--NovikGreen)'

      return splitString.join(highlight.outerHTML)
    }
  }
}
