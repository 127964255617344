import axios from '/src/store/utility/Request'

class ProductRepository {
  getProducts() {
    return axios.get('products')
  }

  getProduct(id) {
    return axios.get(`products/${id}`)
  }

  getSerialMasks() {
    return axios.get(`serial-masks`)
  }

  createProduct(data) {
    return axios.post('products', data)
  }

  updateProduct(data) {
    return axios.put(`products/${data.id}`, data)
  }

  updateProductImage(data) {
    return axios.post(`products/${data.id}/image`, data.form)
  }

  removeProductImage(data) {
    return axios.delete(`products/${data.id}/image/delete`)
  }

  getLanguages() {
    return axios.get('languages')
  }
}

export default new ProductRepository()
