import axios from '/src/store/utility/Request'

class UserRepository {
  getTranslations (locale) {
    return axios.get(`translation?lang=${locale}`)
  }

  loggedUser () {
    return axios.get('users/auth-user')
  }

  getUsers () {
    return axios.get('users')
  }

  getBrand () {
    return axios.get('users/brands')
  }

  getUserDetail (id) {
    return axios.get(`users/${id}`)
  }

  getAdminBrandWithRole () {
    return axios.get('users/admin/brands/roles')
  }

  addUser (data) {
    return axios.post('users', data)
  }

  verifyEmail ($email) {
    return axios.post('users/verify-email-brands', { email: $email })
  }

  addUserToBrand ($email) {
    return axios.post('users/add-to-brands', { email: $email })
  }

  reactivate ($id) {
    return axios.post('users/reactivate', { id: $id })
  }

  uploadAvatar (data) {
    return axios.post('users/avatar', data)
  }

  removeAvatar () {
    return axios.delete('users/avatar/delete')
  }

  updateUser (data) {
    return axios.put(`users/${data.id}`, data)
  }

  commitData(data) {
    return axios.put(`users/${data.id}${data.endpoint}`, JSON.stringify(data.data))
  }

  deleteUser (id) {
    return axios.delete(`users/${id}`)
  }

  getLanguages() {
    return axios.get('languages/admin-languages')
  }
}

export default new UserRepository()
