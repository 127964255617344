<template>
  <div class="w-full text-[var(--NovikGray)] p-5">

<!--    Data organizer-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Data organizer</h2>
      <hr>

      <div class="flex flex-col flex-wrap p-2 text-sm rounded">
        <v-button-row
          v-model="questionnaire.activeLanguage"
          :options="languages"
          :keyValue="['id', 'name']"
          class="mb-5"
        />
        <v-data-organizer
          v-model="questionnaire.backend"
          :activeLanguage="questionnaire.activeLanguage"
          :languages="languages"
          @update:modelValue="console.log(questionnaire.backend)"
        />
      </div>
    </div>

<!--    Button componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Button componenten</h2>
      <hr>

      <div class="flex justify-between gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-button @clicked="buttonClicked" class="!w-max" theme="gray">Gray</v-button>
        <v-button @clicked="buttonClicked" class="!w-max" theme="white">White</v-button>
        <v-button @clicked="buttonClicked" class="!w-max" theme="flat">Flat</v-button>
      </div>

      <div class="flex justify-between gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-button @clicked="buttonClicked" class="!w-max" theme="gray" :disabled="true">Gray</v-button>
        <v-button @clicked="buttonClicked" class="!w-max" theme="white" :disabled="true">White</v-button>
        <v-button @clicked="buttonClicked" class="!w-max" theme="flat" :disabled="true">Flat</v-button>
      </div>

      <div class="flex justify-between gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)] overflow-x-auto">
        <div class="flex divide-x divide-x-reverse">
          <v-button @clicked="buttonClicked" class="!w-max rounded-r-none" theme="white">Yes</v-button>
          <v-button @clicked="buttonClicked" class="!w-max rounded-l-none" theme="gray">No</v-button>
        </div>
      </div>

      <div class="flex justify-between gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)] overflow-x-auto">
        <div class="flex divide-x divide-x-reverse">
          <v-button @clicked="buttonClicked" class="!w-max !px-3 md:!px-5 xl:!px-10 rounded-r-none" theme="gray">Left</v-button>
          <v-button @clicked="buttonClicked" class="!w-max !px-3 md:!px-5 xl:!px-10 rounded-none" theme="gray">Middle</v-button>
          <v-button @clicked="buttonClicked" class="!w-max !px-3 md:!px-5 xl:!px-10 rounded-l-none" theme="gray">Right</v-button>
        </div>
      </div>

      <div class="flex justify-between gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-icon-button @clicked="buttonClicked" class="!w-max" icon="icon-menu-settings" theme="gray">Settings</v-icon-button>
        <v-icon-button @clicked="buttonClicked" class="!w-max" icon="icon-menu-settings" theme="white">Settings</v-icon-button>
        <v-icon-button @clicked="buttonClicked" class="!w-max" icon="icon-menu-settings" theme="flat">Settings</v-icon-button>
      </div>

      <div class="flex justify-between gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <p>Ut maximus bibendum varius. <a class="text-[var(--NovikGreen)] link-external" href="https://www.google.com" target="_blank">aliquam magna eu</a>, fringilla nunc. Ut ut porttitor eros. Mauris pretium fermentum metus sed suscipit. Nulla consectetur ultrices lacus id tempus. Integer nibh lorem, bibendum sit amet placerat at, dignissim et elit. Sed aliquet vel lorem vitae sagittis. Curabitur lacinia nisi sit amet mattis mollis.</p>
      </div>
    </div>

<!--    Tooltip componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Tooltip componenten</h2>
      <hr>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-button @clicked="buttonClicked" class="!w-max" theme="gray">
          <span>Tooltip</span>
          <v-tooltip :location="tooltip.location">
            <p>Hello World</p>
          </v-tooltip>
        </v-button>
      </div>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-button @clicked="buttonClicked" class="!w-max" theme="white">
          <span>Rich tooltip</span>
          <v-rich-tooltip :location="richTooltip.location">
            <template v-slot:content="{closeTooltip}">
              <v-stepped-content
                @scrolledPastBounds="closeTooltip"
                @finishedReading="finishedReading"
                :content="richTooltip.content"
                :trackerTheme="richTooltip.stepTrackerTheme"
              />
            </template>
          </v-rich-tooltip>
        </v-button>
      </div>
    </div>

<!--    Progress tracker componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Progress tracker componenten</h2>
      <hr>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-tracker v-model="tracker.activeStep" :theme="tracker.theme" :steps="tracker.steps"></v-tracker>
      </div>
    </div>

<!--    Progress bar componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Progress bar componenten</h2>
      <hr>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-progress-bar :progress="progressBar.progress" :size="progressBar.size"></v-progress-bar>
      </div>
    </div>

<!--    Calendar componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Calendar componenten</h2>
      <hr>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-datepicker v-model="datepicker.date" :texts="{title: '', description: ''}"></v-datepicker>
      </div>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-button @clicked="buttonClicked" class="!w-max" theme="white">
          <span>Calendar in rich tooltip</span>
          <v-rich-tooltip :location="datepickerTooltip.location">
            <template v-slot:content="{closeTooltip}">
              <v-datepicker class="mt-8 text-left" v-model="datepickerTooltip.date" :texts="{title: '', description: ''}"></v-datepicker>
            </template>
          </v-rich-tooltip>
        </v-button>
      </div>

      <div class="flex flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-daterangepicker v-model="dateRangePicker.date"  :texts="dateRangePicker.texts"></v-daterangepicker>
      </div>
    </div>

<!--    Input validatie-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Input validatie</h2>
      <hr>

      <div class="flex p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <label class="w-full">
          Input Errored
          <v-input v-model="inputs.input1.value" :status="inputs.input1.status"></v-input>
        </label>
      </div>

      <div class="flex p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <label class="w-full">
          Input Success
          <v-input v-model="inputs.input2.value" :status="inputs.input2.status"></v-input>
        </label>
      </div>

      <div class="flex p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <label class="w-full">
          Input Password
          <v-input v-model="inputs.input3.value" :type="inputs.input3.type"></v-input>
        </label>
      </div>

      <div class="flex p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <label class="w-full">
          Input optional
          <v-input v-model="inputs.input4.value" :optional="inputs.input4.optional"></v-input>
        </label>
      </div>
    </div>

<!--    Form componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Form componenten</h2>
      <hr>

      <div class="flex p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <div class="w-1/2">
          <v-selectbox identifier="kitchensinkSelectBox" v-model="selectBox.value" theme="white" :options="selectBox.options" :keyValue="['slug', 'name']"></v-selectbox>
        </div>
      </div>

      <div v-for="entry in toggleSwitch.entries" class="flex justify-between p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-toggleswitch
          v-model="toggleSwitch.selected"
          :entry="entry"
          :keyValue="toggleSwitch.keyValue"
        />
      </div>

      <div class="flex gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-multi-select
          v-model="multiSelect.result"
          :entries="multiSelect.entries"
          :searchable="multiSelect.searchable"
          :keyValue="['slug', 'name']"
          placeholder="Multi Select"
        />
      </div>

      <div class="flex p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <div class="w-1/2">
          <v-selectbox
            v-model="selectBox.value"
            theme="white"
            :options="selectBox.options"
            :keyValue="['slug', 'name']"
            :searchable="true"
          />
        </div>
      </div>

      <div class="flex gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-tag v-for="tag in tags" :tag="tag"></v-tag>
      </div>

      <div class="flex gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-tag v-for="tag in tags" :tag="tag" :removable="true" @removeAction="removeTag(tag)"></v-tag>
      </div>

      <div class="flex gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)] overflow-x-auto">
        <v-counter v-model="count" :large="true"></v-counter>
      </div>

      <div class="flex gap-3 flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-counter v-model="count"></v-counter>
      </div>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-labeled-counter v-model="count" label="Tests performed"></v-labeled-counter>
      </div>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)] overflow-x-auto">
        <v-button-row v-model="buttonRow.value" :options="buttonRow.options" :keyValue="buttonRow.keyValue"></v-button-row>
      </div>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-range v-model="range.value" :range="range"></v-range>
      </div>

      <div class="flex justify-between flex-wrap text-sm">
        <div class="flex flex-col w-full">
          <v-checkbox
            class="rounded p-2 hover:bg-[var(--EntityBG)]"
            v-for="option in checkBox.options"
            v-model="checkBox.value"
            :option="option"
            theme="gray"
            :showText="true"
            :keyValue="checkBox.keyValue"
          />
        </div>
        <div class="flex flex-col w-full">
          <v-checkbox
            class="rounded p-2 hover:bg-[var(--EntityBG)]"
            v-for="option in checkBox.options"
            v-model="checkBox.value"
            :option="option"
            theme="white"
            :showText="true"
            :keyValue="checkBox.keyValue"
          />
        </div>
        <div class="flex flex-col w-full">
          <v-checkbox
            class="rounded p-2 hover:bg-[var(--EntityBG)]"
            v-for="option in checkBox.options"
            v-model="checkBox.value"
            :option="option"
            theme="flat"
            :showText="true"
            :keyValue="checkBox.keyValue"
          />
        </div>
      </div>

      <div class="flex justify-between flex-wrap text-sm">
        <div class="flex flex-col w-full">
          <v-radio
            class="rounded p-2 hover:bg-[var(--EntityBG)]"
            v-for="option in radio.options"
            v-model="radio.value"
            :option="option"
            :id="radio.key"
            theme="gray"
            :keyValue="radio.keyValue"
          />
        </div>
        <div class="flex flex-col w-full">
          <v-radio
            class="rounded p-2 hover:bg-[var(--EntityBG)]"
            v-for="option in radio.options"
            v-model="radio.value"
            :option="option"
            :id="radio.key"
            theme="white"
            :keyValue="radio.keyValue"
          />
        </div>
        <div class="flex flex-col w-full">
          <v-radio
            class="rounded p-2 hover:bg-[var(--EntityBG)]"
            v-for="option in radio.options"
            v-model="radio.value"
            :option="option"
            :id="radio.key"
            theme="flat"
            :keyValue="radio.keyValue"
          />
        </div>
      </div>
    </div>

<!--    Image upload componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Image upload componenten</h2>
      <hr>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <button class="text-[var(--NovikGray)] underline italic" @click="openModal(upload.modal1.identifier)">Modal 1</button>
        <v-modal :ref="`modal-${upload.modal1.identifier}`" modalHeader="Modal 1">
          <v-image-upload v-model="upload.modal3.imageUrl">
            <p>{{ $t('components.upload.drag-and-drop-a-file-here') }}</p>
            <p>{{ $t('components.upload.or') }}</p>
            <v-button @clicked="triggerImageSelect" class="!w-max" theme="gray">{{ $t('components.upload.choose-file') }}</v-button>
          </v-image-upload>
        </v-modal>
      </div>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <button class="text-[var(--NovikGray)] underline italic" @click="openModal(upload.modal2.identifier)">Modal 2</button>
        <v-modal :ref="`modal-${upload.modal2.identifier}`" modalHeader="Modal 2">
          <v-image-upload v-model="upload.modal3.imageUrl">
            <h3 class="text-2xl">{{ $t('components.upload.drag-and-drop') }}</h3>
            <p>{{ $t('components.upload.your-files-or') }} <span class="text-[var(--NovikGreen)] underline">{{ $t('components.upload.browse') }}</span></p>
          </v-image-upload>
        </v-modal>
      </div>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-image-upload v-model="upload.modal3.imageUrl" theme="flat">
          <span class="underline italic">{{ $t('components.upload.click-here-to-add') }}</span>
        </v-image-upload>
      </div>
    </div>

<!--    Pagination componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Pagination componenten</h2>
      <hr>

      <div class="p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-table
          :rowData="pagination.options1.rowData"
          :columns="pagination.options1.columns"
          v-model="pagination.options1.rowsPerPage"
          :paginationOptions="pagination.options1"
        />
      </div>

      <div class="p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-table
          :rowData="pagination.options2.rowData"
          :columns="pagination.options2.columns"
          v-model="pagination.options2.rowsPerPage"
          :paginationOptions="pagination.options2"
        />
      </div>

      <div class="p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-table
          :rowData="pagination.options3.rowData"
          :columns="pagination.options3.columns"
          v-model="pagination.options3.rowsPerPage"
          :paginationOptions="pagination.options3"
        />
      </div>
    </div>

<!--    Toast componenten-->
    <div class="flex flex-col gap-y-4 mb-5 bg-white p-2 rounded-md shadow-sm text-[var(--NovikGray)]">
      <h2 class="text-2xl">Toast componenten</h2>
      <hr>

      <div class="flex justify-between flex-wrap p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
        <v-button @clicked="openToast(toasts[0])" class="!w-max" theme="white">Open error Toast</v-button>
        <v-button @clicked="openToast(toasts[1])" class="!w-max" theme="white">Open warning Toast</v-button>
        <v-button @clicked="openToast(toasts[2])" class="!w-max" theme="white">Open success Toast</v-button>
        <v-button @clicked="openToast(toasts[3])" class="!w-max" theme="white">Open info Toast</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '/src/components/functionality/Button.vue'
import IconButton from '/src/components/functionality/IconButton.vue'

import Tooltip from '/src/components/partials/Tooltip.vue'
import RichTooltip from '/src/components/partials/RichTooltip.vue'
import SteppedContent from '/src/components/functionality/SteppedContent.vue'

import Tracker from '/src/components/functionality/Tracker.vue'

import ProgressBar from '/src/components/functionality/ProgressBar.vue'

import DatePicker from '/src/components/functionality/DatePicker.vue'
import DateRangePicker from '/src/components/functionality/DateRangePicker.vue'

import Table from '/src/components/functionality/Table.vue'

import Input from '/src/components/functionality/Input.vue'

import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import MultiSelect from '/src/components/functionality/MultiSelect.vue'
import Tag from '/src/components/functionality/Tag.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import Counter from '/src/components/functionality/Counter.vue'
import LabeledCounter from '/src/components/functionality/LabeledCounter.vue'
import ButtonRow from '/src/components/functionality/ButtonRow.vue'
import Range from '/src/components/functionality/Range.vue'
import CheckBox from '/src/components/functionality/CheckBox.vue'
import Radio from '/src/components/functionality/Radio.vue'

import Modal from '/src/components/partials/Modal.vue'
import ImageUpload from '/src/components/functionality/ImageUpload.vue'

import DataOrganizer from '/src/components/questionnaire/DataOrganizer.vue'

export default {
  name: 'Testing',

  components: {
    'v-button': Button,
    'v-icon-button': IconButton,

    'v-tooltip': Tooltip,
    'v-rich-tooltip': RichTooltip,
    'v-stepped-content': SteppedContent,

    'v-tracker': Tracker,

    'v-progress-bar': ProgressBar,

    'v-datepicker': DatePicker,
    'v-daterangepicker': DateRangePicker,

    'v-table': Table,

    'v-input': Input,

    'v-toggleswitch': ToggleSwitch,
    'v-selectbox': SelectBox,
    'v-counter': Counter,
    'v-labeled-counter': LabeledCounter,
    'v-button-row': ButtonRow,
    'v-range': Range,
    'v-multi-select': MultiSelect,
    'v-tag': Tag,
    'v-checkbox': CheckBox,
    'v-radio': Radio,

    'v-modal': Modal,
    'v-image-upload': ImageUpload,

    'v-data-organizer': DataOrganizer,
  },

  data() {
    return {
      toggleSwitch: {
        selected: [],
        keyValue: ['key', 'value'],
        entries: [
          {
            key: 1,
            value: 'Test 1'
          },
          {
            key: 2,
            value: 'Test 2'
          },
        ]
      },
      multiSelect: {
        result: [],
        entries: [
          {
            name: 'web',
            slug: 'web'
          },
          {
            name: 'illustration',
            slug: 'illustration'
          },
          {
            name: 'graphics',
            slug: 'graphics'
          },
          {
            name: 'ui',
            slug: 'ui'
          },
          {
            name: 'icon',
            slug: 'icon'
          },
          {
            name: 'design',
            slug: 'design'
          },
          {
            name: 'app',
            slug: 'app'
          },
          {
            name: 'iphone',
            slug: 'iphone'
          },
          {
            name: 'interface',
            slug: 'interface'
          },
          {
            name: 'web design',
            slug: 'web-design'
          }
        ],
        searchable: true
      },
      tags: [
        'web',
        'illustration',
        'graphics',
        'ui',
        'icon',
        'design',
        'app',
        'iphone',
        'interface',
        'web design',
      ],
      selectBox: {
        value: {
          slug: ''
        },
        options: [
          {
            name: 'web',
            slug: 'web'
          },
          {
            name: 'illustration',
            slug: 'illustration'
          },
          {
            name: 'graphics',
            slug: 'graphics'
          },
          {
            name: 'ui',
            slug: 'ui'
          },
          {
            name: 'icon',
            slug: 'icon'
          },
          {
            name: 'design',
            slug: 'design'
          },
          {
            name: 'app',
            slug: 'app'
          },
          {
            name: 'iphone',
            slug: 'iphone'
          },
          {
            name: 'interface',
            slug: 'interface'
          },
          {
            name: 'web design',
            slug: 'web-design'
          }
        ]
      },
      count: 0,
      buttonRow: {
        value: 2,
        keyValue: ['value', 'name'],
        options: [
          {
            value: 1,
            name: 'Apple'
          },
          {
            value: 2,
            name: 'Pear'
          },
          {
            value: 3,
            name: 'Banana'
          },
          {
            value: 4,
            name: 'Kiwi'
          },
          {
            value: 5,
            name: 'Grape'
          }
        ]
      },
      range: {
        value: 5,
        start: 0,
        end: 8
      },
      checkBox: {
        value: ['1'],
        options: {
          'web': {
            text: 'web',
            id: 0
          },
          'illustration': {
            text: 'illustration',
            id: 1
          }
        },
        keyValue: ['id', 'text']
      },
      radio: {
        value: '1',
        key: 'testing',
        options: {
          'design': {
            text: 'design',
            id: 0
          },
          'app': {
            text: 'app',
            id: 1
          }
        },
        keyValue: ['id', 'text']
      },
      pagination: {
        testingGenerate: 100,
        options1: {
          rowData: [],
          columns: ['name', 'value'],
          theme: 'spacious',
          rowsPerPage: 10,
          perPageSelector: true,
          buttonTexts: {
            previous: this.$t('general.previous'),
            next: this.$t('general.next')
          }
        },
        options2: {
          rowData: [],
          columns: ['name', 'value'],
          theme: 'compact',
          rowsPerPage: 10,
          perPageSelector: true
        },
        options3: {
          rowData: [],
          columns: ['name', 'value'],
          theme: 'flat',
          rowsPerPage: 10,
          perPageSelector: true,
          buttonTexts: {
            previous: '&larr;',
            next: '&rarr;'
          }
        }
      },
      datepicker: {
        date: new Date()
      },
      datepickerTooltip: {
        date: new Date(),
        location: 'right'
      },
      dateRangePicker: {
        texts: {
          start: {
            title: 'Start',
          },
          end: {
            title: 'End',
          },
          finish: {
            title: 'Finish',
            description: 'Nam facilisis est quis euismod laoreet. Mauris pharetra, orci ut tristique vulputate, libero nibh euismod arcu, in maximus neque purus id tellus.'
          }
        },
        date: {
          start: new Date(2023, 5, 12),
          end: new Date(2023, 7, 18),
          finish: new Date(2023, 8, 18)
        }
      },
      upload: {
        'modal1': {
          identifier: 'modal1',
          imageUrl: ''
        },
        'modal2': {
          identifier: 'modal2',
          imageUrl: ''
        },
        'modal3': {
          identifier: 'modal3',
          imageUrl: ''
        },
      },
      tooltip: {
        location: 'right'
      },
      richTooltip: {
        location: 'right',
        stepTrackerTheme: 'round',
        content: {
          panel1: {
            title: 'Panel 1',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tincidunt lectus id hendrerit gravida. Vivamus ornare lectus at posuere vulputate. Integer ut mi ut augue pharetra imperdiet vel a nibh. Ut hendrerit tristique ex, at rhoncus lectus lobortis non. Morbi efficitur quis tortor et viverra. Nam ultrices mi id semper finibus. Etiam ornare eget lorem eu scelerisque.'
          },
          panel2: {
            title: 'Panel 2',
            content: 'Vivamus ornare lectus at posuere vulputate.'
          },
          panel3: {
            title: 'Panel 3',
            content: 'Integer ut mi ut augue pharetra imperdiet vel a nibh. Ut hendrerit tristique ex, at rhoncus lectus lobortis non.'
          },
          panel4: {
            title: 'Panel 4',
            content: 'Morbi efficitur quis tortor et viverra. Nam ultrices mi id semper finibus. Etiam ornare eget lorem eu scelerisque.'
          },
        }
      },
      progressBar: {
        progress: 60,
        size: 'large'
      },
      tracker: {
        activeStep: 3,
        theme: 'flat',
        steps: [
          {
            stepNum: 1,
            disabled: false,
            finished: false,
          },
          {
            stepNum: 2,
            disabled: false,
            finished: true,
          },
          {
            stepNum: 3,
            disabled: false,
            finished: false,
          },
          {
            stepNum: 4,
            disabled: false,
            finished: false,
          },
          {
            stepNum: 5,
            disabled: false,
            finished: false,
          },
          {
            stepNum: 6,
            disabled: true,
            finished: false,
          },
          {
            stepNum: 7,
            disabled: false,
            finished: false,
          },
          {
            stepNum: 8,
            disabled: false,
            finished: false,
          },
        ]
      },
      inputs: {
        input1: {
          value: 'error',
          status: 'error'
        },
        input2: {
          value: 'success',
          status: 'success'
        },
        input3: {
          value: 'n0On3C4nKn0w',
          type: 'password',
          required: true
        },
        input4: {
          value: 'optional',
        },
      },
      toast: this.$root.$refs.toast,
      toasts: [
        {
          status: 'error',
          text: 'This is an error toast',
          timer: 0
        },
        {
          status: 'warning',
          text: 'This is a warning toast',
          timer: 0
        },
        {
          status: 'success',
          text: 'This is a success toast',
          timer: 1500
        },
        {
          status: 'info',
          text: 'This is an info toast with an action',
          action: '<a onclick="console.log(\'action\')">ACTION</a>',
          timer: 3000
        }
      ],
      questionnaire: {
        frontend: [
          {
            "type": "p",
            "props": {
              "class": "first"
            },
            "children": "test1"
          },
          {
            "type": "a",
            "props": {
              "class": "text-[var(--NovikGreen)] link-external",
              "href": "https://google.com"
            },
            "children": "test2"
          },
          {
            "type": "p",
            "props": {
              "id": "test-data",
              "data-test": "test-data"
            },
            "children": [
              {
                "type": "text",
                "props": {},
                "children": "test with "
              },
              {
                "type": "strong",
                "props": {
                  "class": "text-red-500"
                },
                "children": "child element"
              },
            ]
          },
          {
            "type": "p",
            "props": {},
            "children": "test4"
          }
        ],
        backend: [],
        activeLanguage: 1
      },
      languages: []
    }
  },

  mounted() {
    this.generateTableData()

    this.$store.dispatch('CampaignService/getLanguages')
      .then(data => {
        this.languages = data.data
      })
      .catch((error) => {
        this.toast.make({
          'status': 'error',
          'text': error.data.status.message,
          'timer': false
        })
      })
  },

  methods: {
    update(entry) {
      console.log(entry.target.value, entry.target.checked)
    },

    removeTag(target) {
      this.tags.splice(this.tags.indexOf(target), 1)
    },

    buttonClicked(e) {
      console.log(e)
    },

    generateTableData(table) {
      let baseData = [
        {
          name: 'Foo',
          value: 'Bar'
        }, {
          name: 'Hello',
          value: 'World'
        }, {
          name: 'Lorem',
          value: 'Ipsum'
        }, {
          name: 'Test',
          value: 123
        }
      ];

      this.pagination.options1.rowData = Array(this.pagination.testingGenerate).fill(null).map(function() {
        return Object.assign({}, baseData[Math.floor(Math.random() * 4)]);
      });
      this.pagination.options2.rowData = Array(this.pagination.testingGenerate).fill(null).map(function() {
        return Object.assign({}, baseData[Math.floor(Math.random() * 4)]);
      });
      this.pagination.options3.rowData = Array(this.pagination.testingGenerate).fill(null).map(function() {
        return Object.assign({}, baseData[Math.floor(Math.random() * 4)]);
      });
    },

    openModal(identifier) {
      this.$refs[`modal-${identifier}`].open()
    },

    triggerImageSelect(e) {
      e.target.parentNode.click()
    },

    finishedReading() {
      console.log('Rich tooltip closed with Finish button')
    },

    openToast(data) {
      this.toast.make({
        'status': data.status,
        'text': data.text,
        'action': data.action,
        'timer': data.timer
      })
    }
  }
}
</script>
