<template>
  <div class="flex flex-col h-full">
    <p v-if="texts?.title" class="text-[var(--NovikGray)]">{{ texts.title }}</p>
    <p class="text-[var(--NovikLightGray)] mb-5 lg:grow">{{ texts.description }}</p>
    <v-datepicker
      :inline="true"
      class="topbar-hidden text-[var(--NovikGray)]"
      :language="appLocale"
      v-model="date"
      :theme="`Novik ${theme}`"
      :openDate="openDate"
      :disabledDates="disabledDates"
      :highlighted="highlighted"
      :preventDisableDateSelection="true"
      :fullMonthName="true"
      :mondayFirst="true"
      @update:modelValue="dateSelected"
    />
  </div>
</template>

<script>
import DatePicker from 'vuejs3-datepicker'

export default {
  name: 'DatePicker',

  components: {
    'v-datepicker': DatePicker
  },

  data() {
    return {
      date: new Date()
    }
  },

  inject: {
    appLocale: {
      from: 'appLocale',
      default: 'en'
    }
  },

  props: {
    modelValue: Date,
    texts: {
      title: String,
      description: String
    },
    theme: String,
    openDate: Date,
    disabledDates: {},
    highlighted: {},
  },

  emits: [
    'update:modelValue'
  ],

  methods: {
    dateSelected(date) {
      this.$emit('update:modelValue', date)
    }
  },

  watch: {
    modelValue: {
      handler() {
        this.date = this.modelValue
      },
      immediate: true
    }
  }
}
</script>
