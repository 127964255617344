class CheckBox {
  constructor() {
    return {
      type: 'checkbox',
      name: 'Checkbox',
      settings: {
        name: [],
        content: [],
        options: [],
        validations: []
      }
    }
  }
}

export default CheckBox
