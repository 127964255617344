<template>
  <div
    v-for="(key, index) in mappings"
    class="
      p-2
      text-sm
      rounded
      hover:bg-[var(--EntityBG)]
      mb-5
    "
  >
    <div
      class="
        flex
        items-center
        justify-between
      "
    >
      <slot :name="`slot-${index}`"></slot>

      <v-select-box
        v-model="mappings[index]"
        :options="values"
        :keyValue="['type', 'title']"
        theme="white"
        class="
          text-[var(--NovikGray)]
          w-full
          md:w-2/3
          lg:w-1/2
        "
        @update:modelValue="$emit('update:modelValue', this.mappings.map(entry => entry[keyValue[0]]))"
      />
    </div>
  </div>
</template>

<script>
import SelectBox from '/src/components/functionality/SelectBox.vue'
import Card from '/src/components/partials/Card.vue'

export default {
  name: 'Mapping',

  components: {
    'v-card': Card,
    'v-select-box': SelectBox,
  },

  data() {
    return {
      mappings: this.keys.map(entry => {
        return {
          [this.keyValue[0]]: '',
          [this.keyValue[1]]: entry[0]
        }
      })
    }
  },

  props: {
    modelValue: {},
    keys: {},
    values: {},
    keyValue: Array
  }
}
</script>
