<template>
  <div class="flex flex-wrap w-full gap-5 mb-9">
    <v-datepicker
      v-model="modelValue.start"
      :openDate="modelValue.start"
      :disabledDates="{
        from: modelValue.end
      }"
      :highlighted="{
        from: modelValue.start,
        to: modelValue.end,
        includeDisabled: true
      }"
      :texts="texts?.start"
      theme="vuejs3-Novik-Range"
      class="start-date lg:max-w-[var(--gridCol2GapSmall)]"
      @update:modelValue="(args) => commitData({start: args})"
    />

    <v-datepicker
      v-model="modelValue.end"
      :openDate="modelValue.end"
      :disabledDates="{
        to: modelValue.start
      }"
      :highlighted="{
        from: modelValue.start,
        to: modelValue.end,
        includeDisabled: true
      }"
      :texts="texts?.end"
      theme="vuejs3-Novik-Range"
      class="end-date lg:max-w-[var(--gridCol2GapSmall)]"
      @update:modelValue="(args) => commitData({end: args})"
    />
  </div>

  <div class="flex flex-wrap w-full mb-9">
    <v-datepicker
      v-model="modelValue.finish"
      :openDate="modelValue.end"
      :disabledDates="{
        to: modelValue.end
      }"
      :highlighted="{
        from: modelValue.end,
        to: modelValue.finish
      }"
      :texts="texts?.finish"
      theme="vuejs3-Novik-Range vuejs3-Novik-Finish"
      class="finish-date"
      @update:modelValue="(args) => commitData({finish: args})"
    />
  </div>
</template>

<script>
import { addDays } from '/src/helper'

import DatePicker from '/src/components/functionality/DatePicker.vue'

export default {
  name: 'DateRangePicker',

  components: {
    'v-datepicker': DatePicker
  },

  data() {
    return {
      newDate: {
        start: this.modelValue.start,
        end: this.modelValue.end,
        finish: this.modelValue.finish
      }
    }
  },

  inject: {
    appLocale: {
      from: 'appLocale',
      default: 'en'
    }
  },

  props: {
    modelValue: {
      start: Date,
      end: Date,
      finish: Date
    },
    texts: {
      start: {
        title: String,
        description: String
      },
      end: {
        title: String,
        description: String
      },
      finish: {
        title: String,
        description: String
      }
    }
  },

  emits: [
    'update:modelValue',
    'commitData',
    'dateSetInvalid'
  ],

  methods: {
    commitData(data) {
      const dateSetValid = []
      this.newDate[Object.keys(data)[0]] = Object.values(data)[0]

      dateSetValid.push({
        allDates: Object.values(this.modelValue).map(entry => typeof entry === 'object').every(Boolean)
      })

      dateSetValid.push({
        start: this.modelValue.start >= addDays(new Date(), 0)
      })
      dateSetValid.push({
        end: this.modelValue.end >= addDays(this.newDate.start, 1)
      })
      dateSetValid.push({
        finish: this.modelValue.finish >= addDays(this.newDate.end, 2)
      })

      if (dateSetValid.map(entry => Object.values(entry)[0]).every(Boolean)) {
        this.$emit('commitData')
        return
      }

      this.$emit('dateSetInvalid', dateSetValid)
    }
  }
}
</script>
