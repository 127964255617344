<template>
  <div class="fixed left-4 bottom-12 max-w-[16rem] md:max-w-full z-40">
    <transition v-for="toast in toasts" name="slide-fade" :onAfterLeave="removeClosedToast">
      <div
        v-if="toast.open"
        class="
          border
          border-[var(--NovikGray)]
          border-l-[16px]
          bg-[var(--NovikGray)]
          text-white
          rounded
          flex
          justify-between
          items-center
          p-4
          mb-8
          shadow-lg
        "
        :class="`border-l-${toast.status}`"
      >
        <span v-html="toast.text"></span>
        <button v-if="toast.action" class="text-white ml-8" v-html="toast.action"></button>
        <button class="text-xl text-white w-8 h-8 ml-8" v-show="!toast.timer" @click="toast.open = false">&times;</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Toast',

  data() {
    return {
      toasts: []
    }
  },

  mounted() {
    window.addEventListener('toast.make', (event) => {
      this.make(event.detail)
    })
  },

  methods: {
    make(toast) {
      toast.open = true
      this.toasts.push(toast)
      this.setClosing(toast)
    },

    setClosing(toast) {
      if (!!toast.timer) {
        setTimeout(
          () => this.toasts[this.toasts.indexOf(toast)].open = false,
          toast.timer
        )
      }
    },

    removeClosedToast() {
      this.toasts.forEach((toast) => {
        if (!toast.open) {
          this.toasts.splice(this.toasts.indexOf(toast), 1)
        }
      })
    }
  }
}
</script>
