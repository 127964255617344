export default {
  en: {
    app: {
      title: 'Novik campaign manager'
    },
    routes: {
      Error: 'Error'
    },
    errors: {
      'refresh-the-page': 'Refresh the page',
      ECONNABORTED: {
        header: 'The connection could not be established',
        body: 'Somehow, somewhere, we\'ve lost connection to our server. ' +
          'This could be due to your internet connection, but it could very well be our server\'s internet connection.'
      },
      ERR_BAD_OPTION: {
        header: 'ERR_BAD_OPTION',
        body: ''
      },
      ERR_BAD_OPTION_VALUE: {
        header: 'ERR_BAD_OPTION_VALUE',
        body: ''
      },
      ERR_BAD_REQUEST: {
        header: 'ERR_BAD_REQUEST',
        body: ''
      },
      ERR_BAD_RESPONSE: {
        header: 'ERR_BAD_RESPONSE',
        body: ''
      },
      ERR_CANCELED: {
        header: 'ERR_CANCELED',
        body: ''
      },
      ERR_DEPRECATED: {
        header: 'ERR_DEPRECATED',
        body: ''
      },
      ERR_FR_TOO_MANY_REDIRECTS: {
        header: 'ERR_FR_TOO_MANY_REDIRECTS',
        body: ''
      },
      ERR_INVALID_URL: {
        header: 'ERR_INVALID_URL',
        body: ''
      },
      ERR_NETWORK: {
        header: 'The connection could not be established',
        body: ''
      },
      ERR_NOT_SUPPORT: {
        header: 'ERR_NOT_SUPPORT',
        body: ''
      },
      ETIMEDOUT: {
        header: 'The connection timed out',
        body: 'Somehow, somewhere, we\'ve lost connection to our server. ' +
          'This could be due to your internet connection, but it could very well be our server\'s internet connection. ' +
          'Either way, not to worry. You can refresh the page to test if the connection is back'
      },
      undefined: {
        header: 'Something went wrong',
        body: 'Not even we are sure what happened here, but something\'s amiss. ' +
          'Let\'s try that again.'
      }
    }
  }
}
