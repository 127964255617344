<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 mx-auto md:mx-0 flex flex-col">
      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="serial-mask-name" class="block text-sm">{{ $t('general.name') }}</label>
        <v-input
          type="text"
          name="serial-mask-name"
          v-model="serialMask.name"
          autocomplete="off"
          readonly
        />
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="serial-mask-example" class="block text-sm">{{ $t('serial-masks.example') }}</label>
        <v-input
          type="text"
          name="serial-mask-example"
          v-model="serialMask.example"
          autocomplete="off"
          readonly
        />
      </div>

      <div v-for="field in serialMask.fields" class="mb-5 text-[var(--NovikGray)]">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('serial-masks.fields') }}</p>
        <v-card
          :label="field.name"
          :closable="true"
        >
          <div class="mb-5 text-[var(--NovikGray)]">
            <label :for="`field-${field.id}-characters`" class="block text-sm">{{ $t('serial-masks.characters') }}</label>
            <v-input
              type="text"
              :name="`field-${field.id}-characters`"
              v-model="field.characters"
              autocomplete="off"
              readonly
            />
          </div>

          <v-add-list
            v-if="field.characters > 0"
            v-model="field.values"
            :add="true"
            :remove="true"
            :key-value="['value', 'value']"
            input-type="number"
            :min-length="field.characters"
            :max-length="field.characters"
            @update:modelValue="(args) =>
              !!field.values.length
              ? commitData(field.id, args)
              : null
            "
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import Loader from '/src/components/partials/Loader.vue'
import Input from '/src/components/functionality/Input.vue'
import AddList from '/src/components/functionality/AddList.vue'
import Card from '/src/components/partials/Card.vue'

export default {
  name: 'Detail',

  components: {
    'v-loader': Loader,
    'v-input': Input,
    'v-add-list': AddList,
    'v-card': Card,
  },

  data() {
    return {
      serialMask: [],
    }
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },

  methods: {
    fetchData() {
      this.$store.dispatch('SerialMaskService/getSerialMask', this.$route.params.id)
        .then(data => {
          this.serialMask = data

          this.serialMask.fields.forEach(entry => {
            return entry.values.sort((a, b) => a.value - b.value)
          })
        })
    },

    commitData(id, values) {
      this.$store.dispatch('SerialMaskService/updateSerialMaskFields', {
        id: id,
        values: values
      })
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })

          this.serialMask.fields.forEach(entry => {
            return entry.values.sort((a, b) => a.value - b.value)
          })
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message ?? error,
            'timer': false
          })
        })
    }
  }
}
</script>
