<template>
  <div class="inline-block relative mb-16 md:mb-12 w-full">
    <span
      :class="{
        'expanded': this.selectBoxOpen,
        'select-box-white': theme === 'white',
        'select-box-flat': theme === 'flat'
      }"
      class="
        select-box
        sm:text-sm
        w-full
        absolute
        inline-block
        min-h-[3rem]
        overflow-hidden
        cursor-pointer
        text-left
        rounded
        transition-all
        duration-300
        overflow-y-clip
        [&:is(.expanded)]:overflow-y-auto
        max-h-12
        [&:is(.expanded)]:max-h-56"
    >
      <v-input
        v-if="searchable"
        :name="`search-selectbox-${identifier}`"
        v-model="search"
        class="!w-full z-10"
        @click="toggleSelectBox"
        autocomplete="off"
        :readonly="!selectBoxOpen"
        :placeholder="placeholder ?? (selectBoxOpen ? $t('general.search') : $t('general.select'))"
      />
      <template v-else>
        <template
          v-if="
            (
              modelValue.length === 0 ||
              !Object.values(modelValue).filter(entry => entry).length
            ) &&
            options.length > 1
          "
        >
          <input
            @input="updateSelection('')"
            :checked="(
                (
                  modelValue.length === 0 ||
                  !Object.values(modelValue).filter(entry => entry).length
                ) &&
                options.length > 1
              )
            "
            :class="{
              'checked': (
                (
                  modelValue.length === 0 ||
                  !Object.values(modelValue).filter(entry => entry).length
                ) &&
                options.length > 1
              )
            }"
            class="hidden sr-only"
            type="radio"
            :name="identifier"
            value=""
            autocomplete="off"
            :id="`selectBoxEntry-option-empty-${identifier}`"
          >
          <label
            class="
              flex
              min-h-[3rem]
              items-center
              px-4
              text-[var(--NovikGray)]
              cursor-pointer
              relative
              transition-color
              duration-300
              hover:bg-[var(--EntityBG)]"
            :for="`selectBoxEntry-option-empty-${identifier}`"
            @click="toggleSelectBox"
          >
            {{ $t('general.select') }}
          </label>
        </template>
      </template>

      <template v-for="option in filteredOptions">
        <input
          @input="updateSelection(option)"
          :checked="(
            (
              (
                !!modelValue.length ||
                !!Object.values(modelValue).length
              ) &&
              modelValue[keyValue[0]] === option[keyValue[0]]
            ) ||
            options.length === 1
          )"
          :class="{
            'checked': (
              (
                (
                  !!modelValue.length ||
                  !!Object.values(modelValue).length
                ) &&
                modelValue[keyValue[0]] === option[keyValue[0]]
              ) ||
              options.length === 1
            )
          }"
          class="hidden sr-only"
          type="radio"
          autocomplete="off"
          :name="identifier"
          :value="option[keyValue[0]]"
          :id="`selectBoxEntry-${option[keyValue[0]]}-${identifier}`"
        >
        <label
          class="
            flex
            min-h-[3rem]
            items-center
            px-4
            text-[var(--NovikGray)]
            cursor-pointer
            relative
            transition-[color]
            duration-300
            hover:bg-[var(--EntityBG)]"
          :for="`selectBoxEntry-${option[keyValue[0]]}-${identifier}`"
          @click="toggleSelectBox"
        >
          <span>
            {{ option[keyValue[1]] }}
          </span>
          <span
            v-if="!!extraValues?.length"
            v-for="extraValue in extraValues"
          >
            {{ `, ${option[extraValue]}` }}
          </span>
        </label>
      </template>

      <i
        :class="{
          'remove': !noRemove,
          'rotate-180': selectBoxOpen && noRemove
        }"
        class="text-[var(--NovikGray)] z-20 icon-menu-brand-chevron"
        @click="clearWhenOpened"
      />
    </span>
  </div>
</template>

<script>
import Input from '/src/components/functionality/Input.vue'

export default {
  name: 'SelectBox',

  components: {
    'v-input': Input
  },

  data() {
    return {
      selectBoxOpen: false,
      selectBox: Element,
      identifier: Math.round(Math.random() * 10000),
      search: ''
    }
  },

  props: {
    modelValue: {},
    options: {},
    keyValue: Array,
    extraValues: Array,
    noRemove: Boolean,
    disabled: Boolean,
    theme: String,
    searchable: Boolean,
    placeholder: String
  },

  computed: {
    filteredOptions() {
      if (this.searchable && this.search.length) {
        const testFor = [this.keyValue[1], ...this.extraValues]

        const entries = []
        testFor.map(value => {
          return this.options.filter(entry => {
            return entry[value].toLowerCase().startsWith(this.search.toLowerCase())
          })
        }).forEach(entry => entries.push(...entry))

        return entries
      }

      return this.options
    }
  },

  emits: [
    'update:modelValue'
  ],

  mounted() {
    this.selectBox = this.$el.querySelector('span')

    if (this.options.length === 1) {
      this.$emit('update:modelValue', this.options[0])
    }

    window.addEventListener('closeSelectBox', () => {
      this.closeSelectBox()
    })
  },

  methods: {
    closeSelectBox() {
      this.scrollToTop()
      this.selectBoxOpen = false
    },

    toggleSelectBox() {
      if (!this.disabled) {
        this.scrollToTop()
        this.selectBoxOpen = !this.selectBoxOpen
      }
    },

    scrollToTop() {
      if (!this.disabled) {
        this.selectBox.scrollTo({
          top: 0,
          instant: true
        })
      }
    },

    updateSelection(option) {
      this.search = option[this.keyValue[1]]

      this.$emit('update:modelValue', option)
    },

    clearWhenOpened() {
      if (this.selectBoxOpen && !this.noRemove) {
        this.$emit('update:modelValue', '')
        this.toggleSelectBox()
      }

      this.search = ''

      this.toggleSelectBox()
    }
  },

  watch: {
    modelValue: {
      handler() {
        this.search = this.modelValue[this.keyValue[1]] ?? ''

        this.scrollToTop()
      }
    }
  }
}
</script>
