<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 mx-auto md:mx-24 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6">
      <v-avatar
        v-if="!!Object.keys(currentUser).length"
        :user="currentUser"
        theme="large"
      />

      <v-image-upload
        v-model="file"
        theme="white"
        :disabled="removing || currentUser.avatar === null"
        class="mt-5"
        @removeImage="removeAvatar"
      />
    </div>
    <div class="mt-20 px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 mx-auto md:mx-0 flex flex-col">
      <div class="mb-5">
        <label for="first-name" class="block text-sm text-[var(--NovikGray)]">{{ $t('general.firstname') }}</label>
        <v-input
          type="text"
          name="first-name"
          v-model="currentUser.firstname"
          autocomplete="given-name"
          :debounced="true"
          @update:modelValue="commitData({
            endpoint: '',
            data: currentUser
          })"
        />
      </div>

      <div class="mb-5">
        <label for="last-name" class="block text-sm text-[var(--NovikGray)]">{{ $t('general.lastname') }}</label>
        <v-input
          type="text"
          name="last-name"
          v-model="currentUser.lastname"
          autocomplete="family-name"
          :debounced="true"
          @update:modelValue="commitData({
                endpoint: '',
                data: currentUser
              })"
        />
      </div>

      <div class="mb-5">
        <label for="email" class="block text-sm text-[var(--NovikGray)]">{{ $t('general.email') }}</label>
        <v-input
          type="email"
          name="email"
          v-model="currentUser.email"
          autocomplete="off"
          :debounced="true"
          readonly
          @update:modelValue="commitData({
                endpoint: '',
                data: currentUser
              })"
        />
      </div>

      <div class="mb-5">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('components.language') }}</p>
        <v-selectbox
          v-if="languages.data"
          identifier="kitchensinkSelectBox"
          v-model="currentUser.language"
          :options="languages.data"
          :noRemove="true"
          :keyValue="['id', 'name']"
          theme="white"
          @update:modelValue="commitData({
            endpoint: '',
            data: currentUser
          })"
        />
      </div>

      <div
        v-for="settingGroup in currentUser.settings"
        class="text-sm text-[var(--NovikGray)]"
      >
        <p class="text-[var(--NovikGray)]">{{ settingGroup.group_name }}</p>
        <v-card>
          <v-setting
            v-for="setting in settingGroup.settings"
            :setting="setting"
            :parent="true"
            @update="(args) => commitData({
              endpoint: '/settings',
              data: args
            })"
          />
        </v-card>
      </div>

      <div class="text-[var(--NovikGray)]">
        <v-button
          class="!w-max float-right"
          theme="gray"
          @click="sendPasswordReset"
        >
          {{ $t('components.change-password') }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Input from '/src/components/functionality/Input.vue'
import Modal from '/src/components/partials/Modal.vue'
import ImageUpload from '/src/components/functionality/ImageUpload.vue'
import Loader from '/src/components/partials/Loader.vue'
import Button from '/src/components/functionality/Button.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import { defaultLocale, loadLocaleMessages } from '/src/i18n'
import { i18n } from '/src/main'
import Avatar from '/src/components/account/Avatar.vue'
import Setting from '/src/components/partials/Setting.vue'
import Card from '/src/components/partials/Card.vue'

export default {
  name: 'Profile',

  components: {
    'v-setting': Setting,
    'v-input': Input,
    'v-modal': Modal,
    'v-image-upload': ImageUpload,
    'v-loader': Loader,
    'v-button': Button,
    'v-selectbox': SelectBox,
    'v-avatar': Avatar,
    'v-card': Card
  },

  data() {
    return {
      file: '',
      languages: [],
      removing: false
    }
  },

  inject: {
    currentUser: {
      from: 'currentUser'
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  methods: {
    fetchData() {
      this.$store.dispatch("UserService/getLanguages")
        .then(data => {
          this.languages = data
        })
    },

    uploadAvatar() {
      const form = new FormData()
      form.append('avatar', this.file)

      this.$store.dispatch("UserService/uploadAvatar", form)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 5000
          })
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': false
          })
        })
    },

    removeAvatar() {
      this.removing = true

      this.$store.dispatch('UserService/removeAvatar')
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 5000
          })

          this.removing = false
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': false
          })

          this.removing = false
        })
    },

    commitData(args) {
      let data = args
      data.id = this.currentUser.id

      this.$store.dispatch('UserService/commitData', data)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message,
            'timer': false
          })
        })

      loadLocaleMessages(i18n, this.currentUser?.language.code.toLowerCase() ?? defaultLocale)
    },

    sendPasswordReset() {
      this.$store.dispatch('AuthService/forgetPassword', {email: this.currentUser.email})
        .then(response => {
          this.$root.$refs.toast.make({
            status: 'success',
            text: response.data.status.message,
            timer: 2000
          })
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            status: 'error',
            text: error.response.data.status?.error.message ?? error.message,
            timer: 0
          })
        })
    }
  },

  watch: {
    file: {
      handler() {
        this.uploadAvatar()
      }
    }
  }
}
</script>
