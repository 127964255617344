<template>
  <v-page-loader />

  <main>
    <v-menu v-if="isLoggedIn" v-model="menuOpen" />

    <div
      v-if="isLoggedIn"
      id="view-port"
      class="
        flex
        justify-self-start
        bg-[var(--EntityBG)]
        relative
        transition-[margin-left]
        lg:border-l
        lg:border-white
        flex-col
        justify-between
      "
      :class="menuOpen ? 'lg:ml-72' : 'lg:ml-16'"
    >
      <v-router-view :to="{ name: exactRouteName }" :name="exactRouteName" class="mb-10" />

      <v-footer />
    </div>
    <v-router-view v-else :to="{ name: exactRouteName }" :name="exactRouteName" />

    <v-toast ref="toast" />
  </main>

  <div
    v-if="modalActive"
    class="
      fixed
      top-0
      left-0
      bg-gray-900
      w-screen
      h-screen
      opacity-30
      z-40
      overscroll-none
    "
    @click="closeAllModals"
  />
</template>

<script>
import { computed } from 'vue'
import { i18n, user } from '/src/main'
import { loadLocaleMessages, defaultLocale } from '/src/i18n'
import { mapGetters } from 'vuex'
import { RouterView } from 'vue-router'

import Menu from '/src/components/menu/Menu.vue'
import Footer from '/src/components/partials/Footer.vue'
import Toast from '/src/components/partials/Toast.vue'
import PageLoader from '/src/components/partials/PageLoader.vue'

export default {
  name: 'App',

  data() {
    return {
      modalActive: false,
      menuOpen: false,
      windowWidth: 0,
      currentUser: user.data,
      token: '',
      errorMessage: ''
    }
  },

  components: {
    'v-menu': Menu,
    'v-footer': Footer,
    'v-toast': Toast,
    'v-router-view': RouterView,
    'v-page-loader': PageLoader
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
    }),

    isLoggedIn() {
      return !!this.currentUser
    },

    exactRouteName() {
      let routeMatches = this.$route.matched.length
      if (routeMatches) {
        return this.$route.matched[routeMatches - 1].name
      }
    },

    isWindowMobile() {
      return this.windowWidth < 1024
    },

    menuState() {
      return this.currentUser?.settings.filter(group => group.group_id === 1)[0]
        .settings.filter(entry => entry.handler === 'MENU_OPEN')[0]
        .value
    }
  },

  mounted() {
    if (!localStorage.getItem('api_url') ||
      localStorage.getItem('api_url').includes('manager')
    ) {
      this.$store.dispatch('ManagerService/getApiUrl', window.location.hostname)
    }

    this.token = localStorage.getItem('token')

    window.addEventListener('tokenChange', () => {
      this.token = localStorage.getItem('token')
      user.refresh()
    })

    window.addEventListener('userRefreshed', () => {
      this.token = localStorage.getItem('token')
      this.currentUser = user.data
    })

    this.windowWidth = window.innerWidth
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth
    })

    if (localStorage.getItem('api_url') &&
      !i18n.global.availableLocales.includes(this.currentUser?.language.code.toLowerCase() ?? defaultLocale)
    ) {
      loadLocaleMessages(i18n, this.currentUser?.language.code.toLowerCase() ?? defaultLocale)
    }

    window.addEventListener('ApiUrlChange', () => {
      loadLocaleMessages(i18n, this.currentUser?.language.code.toLowerCase() ?? defaultLocale)
    })

    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.closeAllModals()
        this.closeAllSelectBoxes()
        this.closeAllTooltips()
      }
    })
  },

  methods: {
    closeAllModals() {
      window.dispatchEvent(new CustomEvent('closeModal'))
    },

    closeAllSelectBoxes() {
      window.dispatchEvent(new CustomEvent('closeSelectBox'))
    },

    closeAllTooltips() {
      window.dispatchEvent(new CustomEvent('closeTooltip'))
    }
  },

  provide() {
    return {
      isLoggedIn: computed(() => this.isLoggedIn),
      errorMessage: computed(() => this.errorMessage),
      isWindowMobile: computed(() => this.isWindowMobile),
      exactRouteName: computed(() => this.exactRouteName),
      currentUser: computed(() => this.currentUser),
      permissions: computed(() => this.currentUser?.permissions ?? [])
    }
  },

  watch: {
    menuOpen: {
      handler() {
        if (!!this.currentUser) {
          if (this.menuOpen !== this.menuState) {
            this.currentUser.settings.filter(group => group.group_id === 1)[0]
              .settings.filter(entry => entry.handler === 'MENU_OPEN')[0]
              .value = this.menuOpen
          }
        }
      }
    },

    menuState: {
      handler() {
        if (!!this.currentUser) {
          if (this.menuOpen !== this.menuState) {
            this.menuOpen = this.currentUser.settings.filter(group => group.group_id === 1)[0]
              .settings.filter(entry => entry.handler === 'MENU_OPEN')[0]
              .value
          }
        }
      }
    }
  }
}
</script>
