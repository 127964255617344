<template>
  <label
    class="flex items-center"
  >
    <input
      type="checkbox"
      class="sr-only peer"
      :disabled="disabled"
      :name="option[keyValue[0]]"
      :value="option[keyValue[0]]"
      :aria-label="`check-${option[keyValue[1]].replace(/\s/g).toLowerCase()}-${identifier}`"
      @input="updateValue"
    >
    <span
      :class="{
        checkboxFlat: theme === 'flat',
        checkboxWhite: theme === 'white',
        checkboxGray: theme === 'gray',

        disabled: disabled,

        'peer-checked': modelValue.includes(String(option[keyValue[0]]))
      }"
      class="
        block
        select-none
        w-7
        aspect-square
        p-1.5
        text-center
        align-middle
        transition-all
        duration-300
        rounded-lg
        fill-transparent
      "
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15.1 10.8">
        <path d="M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z"/>
      </svg>
    </span>
    <span v-if="showText" class="ml-4">
      {{ option[keyValue[1]] }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'CheckBox',

  data() {
    return {
      identifier: Math.round(Math.random() * 10000),
    }
  },

  props: {
    modelValue: Array,
    option: {},
    disabled: Boolean,
    theme: String,
    showText: Boolean,
    keyValue: Array
  },

  emits: [
    'update:modelValue'
  ],

  methods: {
    updateValue(e) {
      if (this.modelValue.includes(e.target.value)) {
        this.modelValue.splice(this.modelValue.indexOf(e.target.value), 1)
      } else {
        this.modelValue.push(e.target.value)
      }

      this.$emit('update:modelValue', this.modelValue)
    }
  }
}
</script>
