<template>
  <v-organizer
    v-model="panels"
    :panel-types="panelTypes"
    @update:modelValue="$emit('update:modelValue', panels)"
  >
    <template
      v-for="(component, index) in panels"
      v-slot:[`slot-${index}`]
    >
      <component
        :is="`v-${component.type.toLowerCase()}-panel`"
        v-model="panels[index].settings"
      />
    </template>
  </v-organizer>
</template>

<script>
import Organizer from '/src/components/questionnaire/abstract/Organizer.vue'

import OptionPanel from '/src/components/questionnaire/panels/Option.vue'

export default {
  name: 'Options',

  components: {
    'v-organizer': Organizer,

    'v-option-panel': OptionPanel
  },

  data() {
    return {
      panels: this.modelValue.map((entry, index) => this.generateComponentFromJson(entry, index)),
      panelTypes: [
        {
          handle: 'option',
          name: 'Option'
        }
      ]
    }
  },

  props: {
    modelValue: {}
  }
}
</script>
