<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 mx-auto md:mx-24 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6">
      <v-image
        :clause="!!product.image"
        theme="large"
        :image="{
          src: product.image,
          alt: product.name
        }"
        class="!rounded-xl"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="p-8 md:p-16" viewBox="0 0 50.732 44.391"><path class="fill-white" d="M50.732,43.1V71.635a4.757,4.757,0,0,1-4.756,4.756H4.756A4.757,4.757,0,0,1,0,71.635V43.1a4.757,4.757,0,0,1,4.756-4.756h8.72l1.219-3.26A4.75,4.75,0,0,1,19.144,32H31.579a4.75,4.75,0,0,1,4.449,3.082l1.229,3.26h8.72A4.757,4.757,0,0,1,50.732,43.1ZM37.257,57.366a11.89,11.89,0,1,0-11.89,11.89A11.9,11.9,0,0,0,37.257,57.366Zm-3.171,0a8.72,8.72,0,1,1-8.72-8.72A8.732,8.732,0,0,1,34.086,57.366Z" transform="translate(0 -32)"/></svg>
      </v-image>

      <v-image-upload
        v-model="product.file"
        class="text-center mt-5"
        theme="white"
        :text="$t('components.edit-image')"
        :disabled="removing || product.image === null"
        @update:modelValue="commitImage()"
        @removeImage="removeProductImage"
      />
    </div>

    <div class="mt-20 mx-auto px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 md:mx-0 flex flex-col">
      <div v-if="Object.keys(product).includes('is_active')" class="text-[var(--NovikGray)]">
        <v-card>
          <div
            class="
              flex
              justify-between
              items-center
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <v-toggleswitch
              v-model="product.is_active"
              :entry="{text: `${$t('products.is-active')}`, entry: false}"
              :keyValue="['entry', 'text']"
              :debounced="false"
              @update:modelValue="commitData()"
            />
          </div>
        </v-card>
      </div>

      <div class="mb-9 text-[var(--NovikGray)]">
        <label for="name" class="mb-1 block text-sm">{{ $t('general.name') }}</label>
        <v-input
          type="text"
          name="name"
          v-model="product.name"
          autocomplete="off"
          :debounced="true"
          @update:modelValue="commitData()"
        />
      </div>

      <div class="mb-9 text-[var(--NovikGray)]">
        <label for="article_number" class="mb-1 block text-sm">{{ $t('products.article_number') }}</label>
        <v-input
          type="text"
          name="article_number"
          v-model="product.article_number"
          autocomplete="off"
          :debounced="true"
          @update:modelValue="commitData()"
        />
      </div>

      <div v-if="Object.keys(product).includes('languages')" class="text-[var(--NovikGray)]">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.languages') }}</p>
        <v-card>
          <div
            v-for="language in product.languages"
            class="
              mb-5
              last:mb-0
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <label :for="language.name" class="mb-1 block text-sm">{{ language.name }}</label>
            <v-input
              type="text"
              :name="language.name"
              v-model="language.value"
              autocomplete="off"
              :debounced="true"
              @update:modelValue="commitData()"
            />
          </div>
        </v-card>
      </div>

      <div v-if="Object.keys(product).includes('has_serial_mask')" class="text-[var(--NovikGray)]">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.serial-mask') }}</p>
        <v-card>
          <div
            class="
              flex
              justify-between
              items-center
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <v-toggleswitch
              v-model="product.has_serial_mask"
              :entry="{text: `${$t('products.has-serial-mask')}`, entry: false}"
              :keyValue="['entry', 'text']"
              :debounced="false"
              @update:modelValue="
                !product.has_serial_mask || !!Object.values(product.serial_mask).length ?
                  commitData() :
                  null
              "
            />
          </div>

          <div
            v-if="!!product.has_serial_mask"
            class="
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
          >
            <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.serial-mask-pattern') }}</p>
            <v-selectbox
              v-if="serialMasks"
              identifier="SelectSerialMask"
              class="mt-5"
              v-model="product.serial_mask"
              :options="serialMasks"
              :noRemove="true"
              theme="white"
              :keyValue="['id', 'example']"
              @update:modelValue="commitData()"
            />
          </div>
        </v-card>
      </div>

      <div v-if="Object.keys(product).includes('ean_barcodes')" class="text-[var(--NovikGray)]">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('products.ean_barcodes') }}</p>
        <v-card>
          <v-add-list
            v-model="product.ean_barcodes"
            :add="true"
            :remove="true"
            :key-value="['ean_barcode', 'ean_barcode']"
            @update:modelValue="
              !!product.ean_barcodes.length
              ? commitData()
              : null
            "
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Loader from '/src/components/partials/Loader.vue'
import Input from '/src/components/functionality/Input.vue'
import Image from '/src/components/partials/Image.vue'
import Card from '/src/components/partials/Card.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import AddList from '/src/components/functionality/AddList.vue'
import ImageUpload from '/src/components/functionality/ImageUpload.vue'

export default {
  name: 'Detail',

  components: {
    'v-image-upload': ImageUpload,
    'v-input': Input,
    'v-loader': Loader,
    'v-image': Image,
    'v-card': Card,
    'v-toggleswitch': ToggleSwitch,
    'v-selectbox': SelectBox,
    'v-add-list': AddList,
  },

  data() {
    return {
      product: {},
      serialMasks: [],
      removing: false
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
    })
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  methods: {
    fetchData() {
      this.$store.dispatch("ProductService/getProduct", this.$route.params.id)
        .then(data => {
          this.product = data

          if (this.product.serial_mask.id === null) {
            this.product.serial_mask = {}
          }

          this.$store.dispatch("ProductService/getLanguages")
            .then(data => {
              this.product.languages = Object.assign(data.data, this.product.languages)
            })

            .catch(error => {
              this.$root.$refs.toast.make({
                'status': 'error',
                'text': error.response.data.status.message,
                'timer': 0
              })
            })
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': 0
          })

          this.$router.push({name: 'Products'})
        })

      this.$store.dispatch("ProductService/getSerialMasks")
        .then(data => {
          this.serialMasks = data
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': 0
          })
        })
    },

    commitData() {
      this.$store.dispatch("ProductService/updateProduct", this.product)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message,
            'timer': false
          })
        })
    },

    commitImage() {
      const form = new FormData()
      form.append('image', this.product.file)

      this.$store.dispatch('ProductService/updateProductImage', {
        id: this.product.id,
        form: form
      })
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })

          const self = this

          const fileReader = new FileReader();
          fileReader.readAsDataURL(this.product.file)
          fileReader.addEventListener("load", function () {
            self.product.image = this.result
          })
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message ?? error,
            'timer': false
          })
        })
    },

    removeProductImage() {
      this.removing = true

      this.$store.dispatch('ProductService/removeProductImage', { id: this.product.id })
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 5000
          })

          this.product.image = null
          this.removing = false
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.data.data.status.message ?? error,
            'timer': false
          })

          this.removing = false
        })
    },
  }
}
</script>
