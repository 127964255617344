import store from '/src/store'

const user = {
  data: null,

  // Get CurrentUser from server and globally set the new data
  refresh: async () => {
    user.data = await store.dispatch("UserService/getCurrentUser")
      .then(data => {
        window.dispatchEvent(new CustomEvent('userRefreshed', { detail: data }))
        return data
      })
      .catch(() => {
        return null
      })

    return user
  }
}

export default user
