export default {
  props: {
    isFinalStep: Boolean,
    isActive: Boolean,
    campaign: {},
    campaignTypes: {},
    campaignLanguages: {},
    campaignCountries: {},
    campaignThirdParties: {},
    campaignProducts: {},
    campaignRetailers: {},
    productMapping: {},
    giftableProducts: {},
    campaignTexts: {},
  },

  emits: [
    'finished',
    'prevStep',
    'nextStep',
    'createCampaign',
    'commitData'
  ],

  computed: {
    finished() {
      return this.requiredFields.every(Boolean)
    },

    nextDisabled() {
      return this.campaign.copy === undefined
    }
  },

  methods: {},

  watch: {
    finished: {
      handler() {
        this.$emit('finished', this.finished)
      },
      immediate: true
    }
  }
}
