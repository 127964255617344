class OpenQuestion {
  constructor() {
    return {
      type: 'open-question',
      name: 'Open question',
      settings: {
        name: [],
        content: [],
        validations: []
      }
    }
  }
}

export default OpenQuestion
