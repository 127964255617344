<template>
  <div class="flex flex-col w-full">
    <div class="mb-5 text-[var(--NovikGray)] w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
      <label :for="`name-${identifier}`" class="block text-sm">{{ $t('general.name') }}</label>
      <v-input
        type="text"
        :name="`name-${identifier}`"
        v-model="modelValue.name.filter(entry => entry.id === activeLanguage)[0].value"
        autocomplete="off"
        :debounced="true"
      />
    </div>

    <div class="mb-5 text-[var(--NovikGray)]">
      <p>{{ $t('data-organizer.content') }}</p>
      <v-content-editor
        v-model="modelValue.content.filter(entry => entry.id === activeLanguage)[0].value"
        :debounced="true"
      />
    </div>

    <div class="mb-5 text-[var(--NovikGray)]">
      <p>{{ $t('data-organizer.options') }}</p>
      <v-data-organizer
        v-model="modelValue.options"
      />
    </div>

    <div class="mb-5 text-[var(--NovikGray)]">
      <p>{{ $t('validations.validations') }}</p>
      <v-checkbox
        class="rounded p-2 hover:bg-[var(--EntityBG)] w-full md:w-2/3 lg:w-1/2 xl:w-1/3"
        v-for="validation in validations"
        v-model="modelValue.validations"
        :option="validation"
        theme="gray"
        :showText="true"
        :key-value="['handle', 'name']"
      />
    </div>
  </div>
</template>

<script>
import Input from '/src/components/functionality/Input.vue'
import CheckBox from '/src/components/functionality/CheckBox.vue'
import DataOrganizer from '/src/components/questionnaire/Options.vue'
import ContentEditor from '/src/components/partials/TinyMCE.vue'
import { uniqueId } from '/src/helper'

export default {
  name: 'CheckBox',

  components: {
    'v-content-editor': ContentEditor,
    'v-data-organizer': DataOrganizer,
    'v-checkbox': CheckBox,
    'v-input': Input
  },

  data() {
    return {
      identifier: uniqueId(),
      validations: [
        {
          handle: 'required',
          name: this.$t(`validations.required`)
        }
      ]
    }
  },

  props: {
    modelValue: {}
  },

  inject: {
    activeLanguage: {
      from: 'activeLanguage'
    }
  },
}
</script>
