<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full flex flex-col bg-[var(--EntityBG)]">
    <div
      v-if="settings"
      class="
        mt-20
        px-4
        md:px-0
        w-full
        sm:w-2/3
        md:w-3/5
        mx-auto
        md:mx-0
        flex
        flex-col
        self-center
      "
    >
      <div v-for="settingGroup in settings">
        <p class="text-[var(--NovikGray)]">{{ settingGroup.general_setting_group_name }}</p>
        <v-card>
          <v-setting
            v-for="setting in settingGroup.settings"
            :setting="setting"
            :parent="true"
            @update="(args) => commitData(args)"
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '/src/components/partials/Loader.vue'
import Setting from '/src/components/partials/Setting.vue'
import Card from '/src/components/partials/Card.vue'

export default {
  name: 'Settings',

  components: {
    'v-setting': Setting,
    'v-loader': Loader,
    'v-card': Card
  },

  data() {
    return {
      settings: []
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  methods: {
    fetchData() {
      this.$store.dispatch("GeneralService/getGeneralSettings")
        .then(data => {
          this.settings = data
        })
    },

    commitData(args) {
      this.$store.dispatch("GeneralService/updateGeneralSettings", args)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })
        })
        .catch((error) => {
          console.log(error)
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.data.status.error.message,
            'timer': false
          })
        })
    },
  }
}
</script>
