<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full bg-[var(--EntityBG)]">
    <div v-if="sortedItems.length">
      <v-table-view-header
        ref="tableViewHeader"
        v-model="sortDescending"
        :entity="$t('components.campaign(s)')"
        :filter="!!statuses.filter(entry => entry.active).length ?
          statuses.filter(entry => entry.active) :
          statuses
        "
        :filter-texts="statuses.filter(entry => entry.active).map(entry => $t(`campaigns.status.${entry.name}`))"
        :searchbar="searchbar"
        :results="visibleItems !== sortedItems.length ? `${visibleItems} ${$t('general.of')} ${sortedItems.length}` : sortedItems.length"
      >
        <template v-slot:filterOptions>
          <div>
            <p class="text-[var(--NovikGray)]">{{ $t('campaigns.toggle-status') }}</p>
            <div v-for="status in statuses" class="flex justify-between p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
              <v-toggleswitch
                v-model="status.active"
                :entry="{
                  text: `${$t(`campaigns.status.${status.name}`)} (${campaigns.filter(campaign => campaign.status === status.name).length})`,
                  entry: status.active
                }"
                :keyValue="['entry', 'text']"
              />
            </div>
          </div>
        </template>
      </v-table-view-header>

      <template
        v-for="campaign in sortedItems"
      >
        <template
          v-if="!!statuses.filter(entry => entry.active).length ?
            statuses.filter(entry => entry.active).filter(entry => entry.name === campaign.status).length :
            statuses
          "
        >
          <template
            v-if="searchables.map(entry => {
              return String(campaign[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
            }).some(v => v === true)"
          >
            <v-router-link
              v-if="routeInPermissions(this.$router.resolve({ name: 'CampaignsDetail', params: { id: campaign.id } }), permissions)"
              class="
                text-[var(--NovikGray)]
                pl-5
                grid
                grid-areas-campaigns-overview
                gap-x-6
                items-center
                min-h-[70px]
                border-b
                border-[var(--EntityDarkBG)]
                duration-200
                transition-[background-color]
                hover:bg-[var(--EntityDarkBG)]
              "
              :to="{ name: 'CampaignsDetail', params: { id: campaign.id } }"
            >
              <v-image
                theme="small"
                :title="campaign.campaign_type.group"
                style="grid-area: type"
              >
                <i
                  class="text-2xl aspect-square"
                  :class="`icon-campaigns-${campaign.campaign_type.group.toLowerCase()}`"
                />
              </v-image>

              <p
                style="grid-area: id"
                class="
                  text-[var(--NovikLightGray)]
                  hover:text-[var(--NovikGray)]
                  transition-[color]
                  duration-300
                "
                v-html="`${$t('campaigns.id')}: ${highlightSubstringInString(searchbar.request, String(campaign.id))}`"
              />
              <p
                style="grid-area: name"
                v-html="highlightSubstringInString(searchbar.request, campaign.name)"
              />
              <p
                style="grid-area: status"
                class="
                  text-[var(--NovikLightGray)]
                  hover:text-[var(--NovikGray)]
                  transition-[color]
                  duration-300
                "
                v-html="`${$t('campaigns.campaign-status')}: ${highlightSubstringInString(searchbar.request, campaign.status)}`"
              />
              <p
                style="grid-area: verification-code"
                class="
                  text-[var(--NovikLightGray)]
                  hover:text-[var(--NovikGray)]
                  transition-[color]
                  duration-300
                "
              >
                {{ $t('campaigns.verification') }}: {{
                  campaign.verification.verification_id !== 1 ?
                    campaign.verification.verification_reference.length ?
                      campaign.verification.verification_reference :
                      '-' :
                    'Manual'
                }}
              </p>
              <p
                style="grid-area: handling-code"
                class="
                  text-[var(--NovikLightGray)]
                  hover:text-[var(--NovikGray)]
                  transition-[color]
                  duration-300
                "
              >
                {{ $t('campaigns.handling') }}: {{
                  campaign.handling.handling_id !== 1 ?
                    campaign.handling.handling_reference.length ?
                      campaign.handling.handling_reference :
                      '-' :
                    $t('campaigns.manual')
                }}
              </p>
              <p
                style="grid-area: dates"
                v-if="campaign.start_date && campaign.end_date"
                class="
                  text-[var(--NovikLightGray)]
                  hover:text-[var(--NovikGray)]
                  transition-[color]
                  duration-300
                "
              >
                {{ formatDate(campaign.start_date) }} {{ $t('general.until') }} {{ formatDate(campaign.end_date) }}
              </p>
              <p
                style="grid-area: dates"
                v-else
                class="
                  text-[var(--NovikLightGray)]
                  hover:text-[var(--NovikGray)]
                  transition-[color]
                  duration-300
                "
              >
                {{ $t('campaigns.no-dates') }}
              </p>
            </v-router-link>
          </template>
        </template>
      </template>
    </div>

    <div v-else>
      <h1 class="text-xl text-[var(--NovikGray)] m-5">{{ $t("general.no-results") }}</h1>
    </div>

    <v-add entity="CampaignsAdd"/>
  </div>
</template>

<script>
import tableView from '/src/views/abstracts/tableView'
import Image from '/src/components/partials/Image.vue'

export default {
  name: 'Campaigns',

  components: {
    ...tableView.components,
    'v-image': Image
  },

  data() {
    return {
      toast: this.$root.$refs.toast,
      statuses: [
        {
          name: 'scheduled',
          active: true
        },
        {
          name: 'ongoing',
          active: false
        },
        {
          name: 'processing',
          active: false
        },
        {
          name: 'completed',
          active: false
        },
        {
          name: 'archived',
          active: false
        }
      ],
      campaigns: [],
      campaignsSelected: [],
      searchables: ['name', 'id', 'status'],
      ...tableView.data
    }
  },

  inject: {
    ...tableView.inject
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...tableView.computed,

    visibleItems() {
      let count = 0

      this.sortedItems.forEach(item => {
        if (this.searchables.map(entry => {
          return String(item[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
        }).some(v => v === true)) {
          count++
        }
      })

      return count
    },

    sortedItems() {
      if (!this.campaigns) {
        return []
      }

      const data = tableView.computed.sortData(this.campaigns, 'name')

      if (this.sortDescending) {
        return data.reverse()
      }

      return data
    }
  },

  methods: {
    ...tableView.methods,

    fetchData() {
      this.$store.dispatch('CampaignService/getCampaigns', 'not-archived')
        .then(data => {
          this.campaigns = data
        })
    },

    formatDate(inputDate) {
      const date = new Date(inputDate)

      const day = date.getDate()
      // getMonth() starts from 0
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      // dd-mm-yyyy
      return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`
    }
  }
}
</script>
