import CsvRepository from '/src/store/repositories/CsvRepository'
import { setLoading } from '/src/helper.js'

export const CsvService = {
  namespaced: true,

  actions: {
    parseData({state}, data) {
      setLoading(state, 'parseData', true)
      return CsvRepository.parseData(data)
        .then(response => {
          setLoading(state, 'parseData', false)
          if (response.data.status.code !== 201) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'parseData', false)

          throw error
        })
    },

    processData({state}, data) {
      setLoading(state, 'processData', true)
      return CsvRepository.processData(data)
        .then(response => {
          setLoading(state, 'processData', false)
          if (response.data.status.code !== 201) {
            throw response
          }

          return response.data
        })
        .catch(error => {
          setLoading(state, 'processData', false)

          throw error
        })
    }
  }
}
