<template>
  <div id="login-page" class="flex flex-col h-screen items-center justify-center bg-[var(--NovikLightGreen)]">
    <div class="w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/4 xl:w-1/5 mb-5">
      <h1 class="headline text-4xl text-center uppercase mb-9 text-[var(--NovikGray)]">{{ $t('components.password.reset') }}</h1>
      <p class="text-center text-[var(--NovikGray)]">{{ $t('components.password.body') }}</p>
    </div>

    <div v-if="message">
      <div class="text-[var(--NovikGray)] font-bold">
        <span>{{ this.message }}</span>
      </div>
    </div>

    <form @submit.prevent="sendPassword" class="w-3/4 sm:w-1/2 md:w-2/5 lg:w-1/4 xl:w-1/5">
      <div class="my-9">
        <label for="email" class="block text-sm font-medium text-gray-700">{{ $t('general.email') }}</label>
        <v-input
          type="text"
          name="email"
          v-model="form.email"
          autocomplete="email"
          :required="true"
        />
      </div>

      <div>
        <v-button theme="gray" :disabled="disableButton" class="uppercase font-bold">
          {{ $t('components.password.reset') }}
        </v-button>
      </div>
    </form>

    <small class="mt-14 underline">
      <router-link
        class="!text-[var(--NovikGray)]"
        :to="{ name: 'Login' }"
      >
        {{ $t('general.back-to') }} {{ $t('routes.Login') }}
      </router-link>
    </small>
  </div>
</template>

<script>
import Input from '/src/components/functionality/Input.vue'
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'ResetEmail',

  components: {
    'v-input': Input,
    'v-button': Button
  },

  data() {
    return {
      message: '',
      form: {
        email: null,
        send: false
      }
    }
  },

  computed: {
    disableButton() {
      if (!this.form.email) {
        return true
      }

      let emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return !emailFormat.test(this.form.email)
    }
  },

  methods: {
    sendPassword: function () {
      this.$store.dispatch('AuthService/forgetPassword', this.form)
        .then(() => {
          this.message = this.$t('components.password.email-sent')
        })
    }
  }
}
</script>
