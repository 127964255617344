<template>
  <div
    v-if="rows > rowsPerPage"
    class="flex items-center h-10"
    :class="{
      paginationSpacious: theme === 'spacious',

      paginationCompact: theme === 'compact',
      'divide-x': theme === 'compact',
      'divide-x-reverse': theme === 'compact',

      paginationFlat: theme === 'flat'
    }"
  >
    <v-button
      v-for="entry in pagination"
      @clicked="$emit('update:modelValue', entry.action)"
      :class="`max-w-[2.5rem] !px-3 !py-2 h-full ${entry.extraClasses} ${theme === 'compact' ? 'first:rounded-l rounded-none last:rounded-r' : ''}`"
      :theme="entry.page === current ? 'gray' : 'white'"
      :disabled="entry.disabled"
      v-html="entry.page"
    />
  </div>
</template>

<script>
import Button from "/src/components/functionality/Button.vue"

export default {
  name: 'Pagination',

  components: {
    'v-button': Button
  },

  props: {
    modelValue: Number,
    rows: Number,
    rowsPerPage: Number,
    options: {},
    theme: String
  },

  emits: [
    'update:modelValue'
  ],

  computed: {
    current() {
      return Math.ceil(this.modelValue / this.rowsPerPage + 1)
    },

    last() {
      return Math.ceil(this.rows / this.rowsPerPage)
    },

    pagination() {
      let selection = []

      if (this.options.buttonTexts?.previous || window.innerWidth < 640) {
        selection.push({
          page: this.options.buttonTexts?.previous ?? this.$t('general.previous'),
          action: this.movePages(-1),
          disabled: this.current === 1,
          extraClasses: '!max-w-max'
        })
      }

      if (this.current !== 1 && window.innerWidth > 640) {
        selection.push({
          page: '1',
          action: 0,
          disabled: false
        })
      }

      if (this.current - 2 > 2 && window.innerWidth > 640) {
        selection.push({
          page: '...',
          action: this.modelValue,
          disabled: false,
          extraClasses: 'hover:!bg-white cursor-default'
        })
      }

      if (this.current - 2 > 1 && window.innerWidth > 768) {
        selection.push({
          page: this.current - 2,
          action: (this.current - 2 - 1) * this.rowsPerPage,
          disabled: false
        })
      }

      if (this.current - 1 > 1 && window.innerWidth > 640) {
        selection.push({
          page: this.current - 1,
          action: (this.current - 1 - 1) * this.rowsPerPage,
          disabled: false
        })
      }

      selection.push({
        page: this.current,
        action: (this.current - 1) * this.rowsPerPage,
        disabled: false
      })

      if (this.current + 1 < this.last && window.innerWidth > 640) {
        selection.push({
          page: this.current + 1,
          action: (this.current + 1 - 1) * this.rowsPerPage,
          disabled: false
        })
      }

      if (this.current + 2 < this.last && window.innerWidth > 768) {
        selection.push({
          page: this.current + 2,
          action: (this.current + 2 - 1) * this.rowsPerPage,
          disabled: false
        })
      }

      if (this.current + 2 < this.last - 1 && window.innerWidth > 640) {
        selection.push({
          page: '...',
          action: this.modelValue,
          disabled: false,
          extraClasses: 'hover:!bg-white cursor-default'
        })
      }

      if (this.current !== this.last && window.innerWidth > 640) {
        selection.push({
          page: this.last,
          action: this.rows - this.rowsPerPage,
          disabled: false
        })
      }

      if (this.options.buttonTexts?.next || window.innerWidth < 640) {
        selection.push({
          page: this.options.buttonTexts?.next ?? this.$t('general.next'),
          action: this.movePages(1),
          disabled: this.current === this.last,
          extraClasses: '!max-w-max'
        })
      }

      return selection
    }
  },

  methods: {
    movePages: function(amount) {
      let newStartRow = this.modelValue + (amount * Number(this.rowsPerPage))
      if (newStartRow >= 0 && newStartRow < this.rows) {
        return newStartRow
      }
    },
  },

  watch: {
    rowsPerPage: {
      handler() {
        this.$emit('update:modelValue', 0)
      }
    }
  }
}
</script>
