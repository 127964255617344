<template>
  <div class="relative">
    <input
      :id="id ?? name?.replace(/\s/g, '-').toLowerCase()"
      :type="_type"
      :name="name"
      :required="_required"
      :value="_value"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :readonly="readonly"
      :aria-labelledby="name?.replace(/\s/g, '-').toLowerCase()"
      :minlength="minLength"
      :maxlength="maxLength"
      class="
        p-4
        h-12
        w-full
        rounded
        shadow-md
        focus:border-indigo-500
        focus:ring-indigo-500
        sm:text-sm
      "
      :class="{
        'border border-[var(--NovikRed)] text-[var(--NovikRed)]': _status === 'error'
      }"
      @input="waitForKey($event.target.value)"
    >

    <span class="absolute top-0 bottom-0 right-4 flex items-center" :title="_message">
      <svg v-if="_status === 'error'" xmlns="http://www.w3.org/2000/svg" class="fill-[var(--NovikRed)]" width="16" height="16" viewBox="0 0 16 16">
        <path d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM9.1,12.2H6.9V10.3H9.2v1.9Zm.1-7.4L8.6,9.2H7.4L6.8,4.8v-1H9.3v1Z"/>
      </svg>
      <i v-if="_status === 'success'" class="text-lg icon-check-mark text-[var(--NovikGreen)]" />

      <i v-if="_type === 'password'" @click="showPassword" class="text-xl icon icon-visible text-[var(--NovikGray)]" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Input',

  data() {
    return {
      delay: {
        current: 0,
        waitTime: this.waitTime ?? 1000
      },
      inputList: [
        'email',
        'number',
        'password',
        'tel',
        'text',
      ],
      _type: null,
      _status: null,
      _value: null,
      _required: null,
      _message: null
    }
  },

  props: {
    id: String | Number,
    type: String,
    name: String | Number,
    status: String,
    modelValue: String | Number,
    placeholder: String | Number,
    autocomplete: String,
    validations: String,
    required: Boolean,
    debounced: Boolean,
    readonly: Boolean,
    waitTime: Number,
    minLength: Number,
    maxLength: Number,
  },

  mounted() {
    // Locally storing variables to make them mutable
    this._type = this.validations?.split('|').filter(validation => this.inputList.includes(validation))[0] ??
      this.type ??
      'text'
    this._status = this.status
    this._required = this.required || this.validations?.split('|').includes('required')
  },

  methods: {
    debouncedUpdate() {
      this.delay.current = setTimeout(() => {
        this.$emit('update:modelValue', this._value)
      }, this.debounced ? this.delay.waitTime : 0)
    },

    waitForKey(value) {
      clearTimeout(this.delay.current)

      this._value = value

      if (this.isInputValid()) {
        this.debouncedUpdate()
      }
    },

    isInputValid() {
      // If the field is required but the value is empty, return an error
      if (this._required && !this._value.length) {
        this._status = 'error'
        this._message = this.$t('validation.field-required')
        return false
      }

      // Apply validations per type.
      // To be extended with cases when need arrives.
      switch(this._type) {
        case 'number':
          // Check if the value is not empty.
          if (!!this._value.length) {
            // !/^\d+$/g.test(): Check if the whole value [^...$] does not [!] contain one or more digits [\d+].
            // Return an error if so.
            if (!/^\d+$/g.test(this._value)) {
              this._status = 'error'
              this._message = this.$t('validation.field-invalid')
              return false
            }

            if (this.validations) {
              // Check if zero is allowed and the value is greater than 0.
              if (this.validations.split('|').includes('no-zero') && !this._value > 0) {
                this._status = 'error'
                this._message = this.$t('validation.zero-not-allowed')
                return false
              }
            }
          }
      }

      // When the check is passed, return value is valid
      this._status = null
      this._message = null
      return true
    },

    showPassword() {
      let [input, icon] = this.$el.childNodes

      if (input.getAttribute('type') === 'password') {
        input.setAttribute('type', 'text')
      } else {
        input.setAttribute('type', 'password')
      }

      icon = icon.querySelector('.icon')
      if (icon.classList.contains('icon-visible')) {
        icon.classList.add('icon-invisible')
        icon.classList.remove('icon-visible')
      } else {
        icon.classList.add('icon-visible')
        icon.classList.remove('icon-invisible')
      }
    }
  },

  watch: {
    modelValue: {
      handler() {
        if (this._value !== this.modelValue) {
          this._value = this.modelValue
        }
      },
      immediate: true
    }
  }
}
</script>
