import GeneralRepository from '/src/store/repositories/GeneralRepository'
import { setLoading } from '/src/helper.js'

export const GeneralService = {
  namespaced: true,

  actions: {
    getGeneralSettings({ state }) {
      setLoading(state, 'getGeneralSettings', true)
      return GeneralRepository.getGeneralSettings()
        .then(response => {
          setLoading(state, 'getGeneralSettings', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getGeneralSettings', false)

          throw error
        })
    },

    updateGeneralSettings({ commit, state }, data) {
      return GeneralRepository.updateGeneralSettings(data)
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }

          return response
        })
        .catch(error => {
          throw error
        })
    },

    getDomains({ state }) {
      setLoading(state, 'getDomains', true)
      return GeneralRepository.getDomains()
        .then(response => {
          setLoading(state, 'getDomains', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getDomains', false)

          throw error
        })
    },

    getCountries({ state }) {
      setLoading(state, 'getCountries', true)
      return GeneralRepository.getCountries()
        .then((response) => {
          setLoading(state, 'getCountries', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            return response.data.data
          }
        })
        .catch(error => {
          setLoading(state, 'getCountries', false)
          throw error
        })
    },

    updateDomains({}, data) {
      return GeneralRepository.updateDomains(data)
        .then(response => {
          if (response.data.status.code !== 200) {
            throw response
          }

          return response
        })
        .catch(error => {
          throw error
        })
    },
  }
}
