<template>
  <v-organizer
    v-model="panels"
    :panel-types="panelTypes"
    @update:modelValue="$emit('update:modelValue', panels)"
  >
    <template
      v-for="(component, index) in panels"
      v-slot:[`slot-${index}`]
    >
      <component
        :is="`v-${component.type.toLowerCase()}-panel`"
        v-model="panels[index].settings"
      />
    </template>
  </v-organizer>
</template>

<script>
import { computed } from 'vue'

import Organizer from '/src/components/questionnaire/abstract/Organizer.vue'

import OpenQuestionPanel from '/src/components/questionnaire/panels/OpenQuestion.vue'
import CheckBoxPanel from '/src/components/questionnaire/panels/CheckBox.vue'
import RadioPanel from '/src/components/questionnaire/panels/Radio.vue'
import SelectBoxPanel from '/src/components/questionnaire/panels/SelectBox.vue'
import MediaUploadPanel from '/src/components/questionnaire/panels/MediaUpload.vue'

export default {
  name: 'DataOrganizer',

  components: {
    'v-organizer': Organizer,

    'v-open-question-panel': OpenQuestionPanel,
    'v-checkbox-panel': CheckBoxPanel,
    'v-radio-panel': RadioPanel,
    'v-selectbox-panel': SelectBoxPanel,
    'v-media-upload-panel': MediaUploadPanel,
  },

  data() {
    return {
      panels: this.modelValue,
      panelTypes: [
        {
          handle: 'open-question',
          name: 'Open question'
        },
        {
          handle: 'selectbox',
          name: 'Select box'
        },
        {
          handle: 'checkbox',
          name: 'Checkbox'
        },
        {
          handle: 'radio',
          name: 'Radio'
        },
        {
          handle: 'media-upload',
          name: 'Media upload'
        }
      ]
    }
  },

  props: {
    modelValue: {},
    activeLanguage: Number,
    languages: {}
  },

  provide() {
    return {
      activeLanguage: computed(() => this.activeLanguage),
      languages: computed(() => this.languages),
    }
  }
}
</script>
