<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full bg-[var(--EntityBG)]">
    <div v-if="sortedItems.length">
      <v-table-view-header
        ref="tableViewHeader"
        v-model="sortDescending"
        :entity="$t('components.serial-mask(s)')"
        :searchbar="searchbar"
        :results="visibleItems !== sortedItems.length ? `${visibleItems} ${$t('general.of')} ${sortedItems.length}` : sortedItems.length"
      />

      <template
        v-for="serialMask in sortedItems"
      >
        <div
          v-if="searchables.map(entry => {
            return String(serialMask[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
          }).some(v => v === true)"
          class="
            border-b
            border-[var(--EntityDarkBG)]
            duration-200
            transition-[background-color]
            hover:bg-[var(--EntityDarkBG)]
            flex
          "
        >
          <div class="ml-5 flex flex-col self-center my-2">
            <router-link
              v-if="routeInPermissions(this.$router.resolve({ name: 'SerialMasksDetail', params: { id: serialMask.id } }), permissions)"
              :to="{ name: 'SerialMasksDetail', params: { id: serialMask.id } }"
            >
              <p
                class="text-[var(--NovikGray)]"
                v-html="highlightSubstringInString(searchbar.request, serialMask.example)"
              />
            </router-link>

            <div>
              <router-link
                v-if="routeInPermissions(this.$router.resolve({ name: 'SerialMasksDetail', params: { id: serialMask.id } }), permissions)"
                :to="{ name: 'SerialMasksDetail', params: { id: serialMask.id } }"
                class="text-[var(--NovikLightGray)] hover:text-[var(--NovikGray)] transition-colors duration-300"
                v-html="highlightSubstringInString(searchbar.request, serialMask.name)"
              />
            </div>
          </div>
        </div>
      </template>
    </div>

    <div v-else>
      <h1 class="text-xl text-[var(--NovikGray)] m-5">{{ $t("general.no-results") }}</h1>
    </div>
  </div>
</template>

<script>
import tableView from '../abstracts/tableView'

export default {
  name: 'SerialMasks',

  components: {
    ...tableView.components
  },

  data() {
    return {
      ...tableView.data,
      serialMasks: [],
      searchables: ['name', 'example']
    }
  },

  inject: {
    ...tableView.inject
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...tableView.computed,

    visibleItems() {
      let count = 0

      this.sortedItems.forEach(item => {
        if (this.searchables.map(entry => {
          return String(item[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
        }).some(v => v === true)) {
          count++
        }
      })

      return count
    },

    sortedItems() {
      if (!this.serialMasks) {
        return []
      }

      const data = tableView.computed.sortData(this.serialMasks, 'name')

      if (this.sortDescending) {
        return data.reverse()
      }

      return data
    }
  },

  methods: {
    ...tableView.methods,

    fetchData() {
      this.$store.dispatch('SerialMaskService/getSerialMasks')
        .then(data => {
          this.serialMasks = data
        })
    }
  }
}
</script>
