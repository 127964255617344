<template>
  <v-button
    @click="this.$emit('click', $event)"
    class="!px-3 leading-none"
    :theme="theme"
  >
    <i :class="icon" class="aspect-square"></i>
    <slot></slot>
  </v-button>
</template>

<script>
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'IconButton',

  components: {
    'v-button': Button
  },

  props: {
    theme: String,
    icon: String
  },

  emits: [
    'click'
  ]
}
</script>
