<template>
  <nav
    class="
      bg-[var(--NovikGreen)]
      text-[var(--NovikGray)]
      flex
      sm:items-center
      justify-between
      h-14
      lg:border-l
      lg:border-white
      transition-[margin-left]
    "
    :class="menuOpen ? 'lg:ml-72' : 'lg:ml-16'"
  >
    <div class="flex">
      <i
        class="
          icon-menu-bars
          text-2xl
          text-white
          ml-6
          mr-5
          my-3
          transition-[margin-left]
          lg:hidden
        "
        @click="$emit('clicked')"
      />
      <h1>
        <router-link
          v-if="isWindowMobile"
          :to="{ name: $route.name }"
          class="
            route
            text-white
            self-center
            flex
            justify-center
            items-center
            cursor-pointer
            hover:bg-[var(--NovikDarkGreen)]
            h-14
          "
        >
          {{ $route?.meta.title ? $t(`routes.${$route.meta.title}`) : '' }}
        </router-link>
        <router-link
          v-else
          :to="{ name: link?.name }"
          class="
            route
            text-white
            ml-0
            flex
            justify-center
            items-center
            cursor-pointer
            hover:bg-[var(--NovikDarkGreen)]
            px-4
            h-14
          "
        >
          {{ link?.meta.title ? $t(`routes.${link.meta.title}`) : '' }}
        </router-link>
      </h1>
    </div>

    <template v-if="!isWindowMobile">
      <div class="inline-flex justify-between w-5/6 sm:w-3/4 md:w-1/2">
        <template v-for="route in subMenu">
          <router-link
            class="h-14 m-auto hover:bg-[var(--NovikDarkGreen)]"
            :to="{ name: route.name }"
          >
            <div class="flex justify-between mx-4">
              <p class="py-4 uppercase">{{ $t(`routes.${route.name}`) }}</p>
            </div>
          </router-link>
        </template>
      </div>

      <div
        class="flex items-center gap-x-4 mr-6">
        <a
          href="#"
          tabindex="0"
          @click="logout()"
          class="
            flex
            justify-center
            items-center
            gap-x-2
            cursor-pointer
            hover:bg-[var(--NovikDarkGreen)]
            h-14
            px-4
            text-[var(--NovikGray)]
          "
        >
          <div class="w-6">
            <i class="icon-logout text-2xl" title="Logout"></i>
          </div>
          <span>{{ $t('routes.Logout') }}</span>
        </a>

        <router-link
          class="
            p-2
            h-14
            m-auto
            hover:bg-[var(--NovikDarkGreen)]
            shrink-0
          "
          :to="{ name: 'Profile' }"
        >
          <v-avatar
            class="!w-10"
            v-if="!!Object.keys(currentUser).length"
            :user="currentUser"
            theme="small"
          />
        </router-link>
      </div>
    </template>
  </nav>
</template>

<script>
import Input from '/src/components/functionality/Input.vue'
import Avatar from '/src/components/account/Avatar.vue'

export default {
  name: 'NavBar',

  components: {
    'v-input': Input,
    'v-avatar': Avatar
  },

  props: {
    menuOpen: Boolean,
    collection: {},
    link: {},
    subMenu: {} | null
  },

  emits: [
    'clicked'
  ],

  inject: {
    isWindowMobile: {
      from: 'isWindowMobile'
    },

    currentUser: {
      from: 'currentUser'
    }
  },

  methods: {
    logout() {
      this.$router.push({ name: 'Login' })
        .then(() => {
          this.$store.dispatch('AuthService/logout')
        })
    }
  }
}
</script>
