<template>
  <div class="w-full flex flex-col bg-[var(--EntityBG)]">
    <div class="p-4 bg-[var(--NovikDarkGreen)]">
      <v-tracker
        v-model="activeStep"
        theme="light"
        :steps="steps"
        :no-text="true"
      />
    </div>

    <div
      class="
        mt-20
        px-4
        md:px-0
        w-full
        sm:w-2/3
        md:w-3/5
        mx-auto
        md:mx-0
        flex
        flex-col
        self-center
      "
    >
      <template v-if="activeStep === 1">
        <div>
          <p class="text-sm text-[var(--NovikGray)]">{{ $t('csv.type.header') }}</p>
          <v-card>
            <p class="text-sm text-[var(--NovikGray)] mb-5">{{ $t('csv.type.description') }}</p>

            <v-select-box
              v-model="csv.type"
              :options="importTypes"
              :keyValue="['handle', 'name']"
              :noRemove="true"
              theme="white"
              class="
                  text-[var(--NovikGray)]
                  w-full
                  md:w-2/3
                  lg:w-1/2
                "
            />
          </v-card>
        </div>

        <div v-if="!!Object.values(this.csv.type).length">
          <p class="text-sm text-[var(--NovikGray)]">{{ $t('csv.file.header') }}</p>
          <v-card>
            <p class="text-sm text-[var(--NovikGray)] mb-5">{{ $t('csv.file.description') }}</p>

            <p
              class="text-[var(--NovikGray)] mb-5"
              v-if="csv.file.name"
            >
              {{ $t('csv.file.file', { file: csv.file.name }) }}
            </p>

            <v-image-upload
              v-model="csv.file"
              theme="gray"
              accept="*"
              type="file"
              :text="csv.file.name ? $t('csv.file.upload-new') : $t('csv.file.upload')"
            />
          </v-card>
        </div>

        <div v-if="csv.file.name">
          <p class="text-sm text-[var(--NovikGray)]">
            {{ $t('csv.settings.header') }}
          </p>
          <v-card>
            <div
              class="
                  p-2
                  text-sm
                  rounded
                  hover:bg-[var(--EntityBG)]
                  mb-5
                "
            >
              <div
                class="
                    flex
                    justify-between
                  "
              >
                <v-toggle-switch
                  v-model="csv.header"
                  :entry="{text: $t('csv.settings.has-header.text'), value: false}"
                  :keyValue="['value', 'text']"
                />
              </div>

              <p class="text-sm text-[var(--NovikGray)] mt-2">
                {{ $t('csv.settings.has-header.description') }}
              </p>
            </div>

            <div
              class="
                  p-2
                  text-sm
                  rounded
                  hover:bg-[var(--EntityBG)]
                  mb-5
                "
            >
              <div
                class="
                    text-[var(--NovikGray)]
                    w-full
                    md:w-2/3
                    lg:w-1/2
                  "
              >
                <label for="delimiter" class="block text-sm">{{ $t('csv.settings.delimiter.text') }}</label>
                <v-input
                  type="text"
                  name="delimiter"
                  v-model="csv.delimiter"
                  autocomplete="off"
                />
              </div>

              <p class="text-sm text-[var(--NovikGray)] mt-2">
                {{ $t('csv.settings.delimiter.description') }}
              </p>
            </div>
          </v-card>
        </div>

        <div v-if="!modelSelectDisabled" class="mb-9 flex justify-end items-center">
          <v-button
            class="!w-max uppercase"
            @clicked="nextStep()"
            theme="gray"
          >
            {{ $t('general.next') }}
          </v-button>
        </div>
      </template>

      <template v-if="activeStep > 1">
        <v-loader v-if="loading" class="mx-auto"></v-loader>

        <template v-else>
          <template v-if="activeStep === 2">
            <div>
              <p class="text-sm text-[var(--NovikGray)]">{{ $t('csv.model.header') }}</p>
              <v-card>
                <p class="text-sm text-[var(--NovikGray)] mb-5">{{ $t('csv.model.description') }}</p>

                <v-select-box
                  v-model="csv.model"
                  :options="models"
                  :keyValue="['id', 'name']"
                  :noRemove="true"
                  theme="white"
                  class="
                    text-[var(--NovikGray)]
                    w-full
                    md:w-2/3
                    lg:w-1/2
                  "
                />
              </v-card>
            </div>

            <div class="mb-9 flex justify-end items-center">
              <v-button
                class="!w-max uppercase"
                @click="parseData"
                theme="gray"
              >
                {{ $t('csv.parse') }}
              </v-button>
            </div>
          </template>

          <template v-if="activeStep === 3">
            <div>
              <p class="text-sm text-[var(--NovikGray)]">{{ $t('csv.mapping.header') }}</p>
              <v-card>
                <p class="text-sm text-[var(--NovikGray)] mb-5">{{ $t('csv.mapping.description') }}</p>

                <v-mapping
                  v-model="processable.fields"
                  :keys="parsedData.data.columns"
                  :values="mappingValues"
                  :keyValue="['type', 'title']"
                >
                  <template v-for="(key, index) in parsedData.data.columns" v-slot:[`slot-${index}`]>
                    <div>
                      <p class="text-[var(--NovikGray)]">{{ parsedData.data.columns[index][0] }}</p>
                      <p class="text-[var(--NovikLightGray)]">{{ parsedData.data.columns[index][1] }}</p>
                    </div>
                  </template>
                </v-mapping>
              </v-card>
            </div>

            <div class="mb-9 flex justify-end items-center">
              <a
                href="#step1"
                class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
                @click="cancelProcess"
              >
                {{ $t('general.cancel') }}
              </a>
              <v-button
                class="!w-max uppercase"
                @click="processData"
                theme="gray"
              >
                {{ $t('csv.process') }}
              </v-button>
            </div>
          </template>

          <template v-if="activeStep === 4">
            <div>
              <v-card>
                <p class="text-sm text-[var(--NovikGray)] mb-5">{{ $t('csv.success') }}</p>
              </v-card>
            </div>

            <div class="mb-9 flex justify-end items-center">
              <a
                href="#"
                class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer"
                @click="cancelProcess()"
              >
                {{ $t('general.back') }}
              </a>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { mapGetters } from 'vuex'

import Loader from '/src/components/partials/Loader.vue'
import Card from '/src/components/partials/Card.vue'
import Mapping from '/src/components/partials/Mapping.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import ImageUpload from '/src/components/functionality/ImageUpload.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import Input from '/src/components/functionality/Input.vue'
import Tracker from '/src/components/functionality/Tracker.vue'
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'Import',

  components: {
    'v-button': Button,
    'v-tracker': Tracker,
    'v-image-upload': ImageUpload,
    'v-loader': Loader,
    'v-card': Card,
    'v-select-box': SelectBox,
    'v-toggle-switch': ToggleSwitch,
    'v-input': Input,
    'v-mapping': Mapping,
  },

  data() {
    return {
      csv: {
        file: {},
        type: {},
        header: false,
        delimiter: '',
        model: {}
      },
      parsedData: {},
      processable: {
        id: Number,
        fields: []
      },
      models: {},
      importTypes: [
        {
          handle: 'products',
          name: this.$t('csv.type.products')
        },
        {
          handle: 'codes',
          name: this.$t('csv.type.codes')
        },
        {
          handle: 'serial-mask-fields',
          name: this.$t('csv.type.serial-mask-fields')
        },
      ],
      entryStep: 1,
      activeStep: null,
      steps: [
        {
          stepNum: 1
        },
        {
          stepNum: 2,
          disabled: true
        },
        {
          stepNum: 3,
          disabled: true
        },
        {
          stepNum: 4,
          disabled: true
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    }),

    routeQueryStep() {
      if (
        !this.$route.query.step ||
        !this.steps[this.$route.query.step - 1]
      ) {
        return this.entryStep
      }

      if (this.steps[this.$route.query.step - 1].disabled) {
        return this.entryStep
      }

      return Number(this.$route.query.step)
    },

    modelSelectDisabled() {
      return !(
        this.csv.file.type === 'text/csv' &&
        !!Object.values(this.csv.type).length &&
        !!this.csv.delimiter.length
      )
    },

    mappingValues() {
      return Object.entries(this.parsedData.fields)
        .map(entry => {
        return {
          title: entry[1].title,
          type: entry[0]
        }
      })
    }
  },

  mounted() {
    if (this.$route.query.step && this.steps[this.$route.query.step - 1]) {
      if (!this.steps[this.$route.query.step - 1].disabled) {
        this.activeStep = Number(this.$route.query.step)
      } else {
        this.activeStep = this.entryStep
      }
    } else {
      this.activeStep = this.entryStep
    }
  },

  methods: {
    nextStep() {
      // Show next step
      this.activeStep = Number(this.activeStep) + 1

      // Update route to current step
      this.$router.push({
        path:this.$route.path,
        query:{
          step: this.activeStep
        }
      })
    },

    getModel() {
      switch(this.csv.type.handle) {
        case 'products':
        case 'codes':
          this.$store.dispatch('CampaignService/getCampaigns')
            .then(data => {
              this.models = data.filter(entry => {
                return (
                  entry.status === 'scheduled' ||
                  entry.status === 'ongoing'
                )
              })
            })
          break
        case 'serial-mask-fields':
          this.$store.dispatch('ProductService/getSerialMasks')
            .then(data => {
              this.models = data
            })
          break
      }
    },

    parseData() {
      const form = new FormData()
      form.append('file', this.csv.file)
      form.append('type', this.csv.type.handle)
      form.append('header', this.csv.header)
      form.append('delimiter', this.csv.delimiter)
      form.append('model_id', this.csv.model.id ?? null)

      this.$store.dispatch('CsvService/parseData', form)
        .then(data => {
          this.parsedData = data
          this.processable.id = this.parsedData.data.id

          this.parseToProcess(3)
        })
    },

    parseToProcess(step) {
      this.steps[0].disabled = !!Object.values(this.parsedData).length
      this.steps[1].disabled = !!Object.values(this.parsedData).length
      this.steps[2].disabled = !Object.values(this.parsedData).length

      this.activeStep = step

      this.$router.push({
        path:this.$route.path,
        query:{
          step: this.activeStep
        }
      })
    },

    cancelProcess() {
      this.parsedData = {}
      this.steps[3].disabled = true

      this.parseToProcess(1)
    },

    processData() {
      this.processable.fields.forEach((field, index) => {
        if (field === undefined) {
          this.processable.fields[index] = ''
        }
      })

      this.$store.dispatch('CsvService/processData', this.processable)
        .then(response => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': this.$t(response.status.message),
            'timer': 2000
          })

          this.steps[3].disabled = false
          this.parseToProcess(4)
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.message,
            'timer': false
          })

          this.cancelProcess()
        })
    }
  },

  watch: {
    routeQueryStep: {
      handler() {
        if (this.steps.length && this.steps[this.routeQueryStep - 1].disabled) {
          this.activeStep = this.entryStep
        } else {
          this.activeStep = this.routeQueryStep
        }
      },
      immediate: true
    },
    modelSelectDisabled: {
      handler() {
        if (!this.modelSelectDisabled) {
          this.steps[1].disabled = false

          this.getModel()
        }
      }
    }
  }
}
</script>
