<template>
  <span
    class="text-[var(--NovikGray)]"
  >
    {{ entry[keyValue[1]] }}
  </span>
  <label
    :for="`toggle-${entry[keyValue[1]].replace(/\s/g, '-').toLowerCase()}-${identifier}`"
    class="
      inline-flex
      relative
      items-center
      cursor-pointer
      toggle-switch
      text-transparent
      text-[1px]
    "
    :class="{
      '!bg-[var(--NovikGreen)]': checked,
      '[&:after]:translate-x-full': checked,
    }"
  >
    <input
      type="checkbox"
      class="sr-only"
      @input="waitForKey($event.target.value)"
      :id="`toggle-${entry[keyValue[1]].replace(/\s/g, '-').toLowerCase()}-${identifier}`"
      :name="`toggle-${entry[keyValue[1]].replace(/\s/g, '-').toLowerCase()}`"
      :checked="checked"
      :value="entry[keyValue[0]]"
    >

    {{ entry[keyValue[1]] }}
  </label>
  <slot name="description>"></slot>
</template>

<script>
export default {
  name: 'ToggleSwitch',

  data() {
    return {
      delay: {
        current: 0,
        waitTime: this.waitTime ?? 1000
      },
      identifier: Math.round(Math.random() * 10000),
    }
  },

  props: {
    modelValue: Array|Boolean,
    entry: {},
    keyValue: Array,
    debounced: Boolean,
    waitTime: Number
  },

  emits: [
    'update:modelValue'
  ],

  computed: {
    checked() {
      if ('boolean' === typeof this.modelValue) {
        return this.modelValue
      }

      try {
        return this.modelValue.find((model) => {
          return model[this.keyValue[0]] === this.entry[this.keyValue[0]]
        })
      }
      catch(e) {
        this.$root.$refs.toast.make({
          'status': 'error',
          'text': this.$t('errors.something-went-wrong'),
          'timer': false
        })
      }
    }
  },

  methods: {
    debouncedUpdate(value) {
      const self = this
      this.delay.current = setTimeout(function (x) {
        if (value && value.length > 0) {
          self.updateValue(value)
        }
      }, this.debounced ? this.delay.waitTime : 0)
    },

    waitForKey(value) {
      clearTimeout(this.delay.current)
      this.debouncedUpdate(value)
    },

    updateValue(value) {
      switch (typeof this.modelValue) {
        case 'boolean':
          this.$emit('update:modelValue', !this.modelValue)
          break
        case 'object':
          let indexOfModel = this.modelValue.indexOf(
            this.modelValue.find((model) => {
              return String(model[this.keyValue[0]]) === value
            })
          )

          if (indexOfModel !== -1) {
            this.modelValue.splice(indexOfModel, 1)
          } else {
            this.modelValue.push(this.entry)
          }

          this.$emit('update:modelValue', this.modelValue)
          break
      }
    }
  }
}
</script>
