import axios from '/src/store/utility/Request'

class BrandRepository {
  switchBrands(fqdn) {
    return axios.get(`users/brand/switch?fqdn=${fqdn}`)
  }

  getBrands() {
    return axios.get('users/brands')
  }
}

export default new BrandRepository()
