<template>
  <label class="flex items-center">
    <input type="radio" class="sr-only peer" :disabled="disabled" :name="`radio-${option[keyValue[0]]}`" :value="option[keyValue[0]]" @input="this.$emit('update:modelValue', $event.target.value)">
    <div
      :class="{
      radioFlat: theme === 'flat',
      radioWhite: theme === 'white',
      radioGray: theme === 'gray',

      disabled: disabled,

      'peer-checked': modelValue === String(option[keyValue[0]])
    }"
      class="select-none w-7 aspect-square p-1.5 text-center align-middle transition-all duration-300 rounded-full fill-transparent"
    >
      <div class="w-3 aspect-square rounded-full border-2 transition-all duration-300" />
    </div>
    <span class="ml-4">
      {{ option[keyValue[1]] }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'Radio',

  props: {
    modelValue: Array|Boolean,
    option: {},
    disabled: Boolean,
    theme: String,
    keyValue: Array
  },

  emits: [
    'update:modelValue'
  ]
}
</script>
