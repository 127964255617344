<template>
  <div class="uploadBtn">
    <template v-if="type === 'file'">
      <v-button
        class="text-[var(--NovikGray)] !w-max"
        @click="this.$refs.modal.open()"
        :theme="theme"
      >
        {{ text }}
      </v-button>
    </template>
    <template v-else>
      <div class="flex gap-x-4 justify-center items-center">
        <v-icon-button
          class="!w-max"
          @click="this.$refs.modal.open()"
          :theme="theme"
          icon="icon-edit-entity"
        />
        <v-icon-button
          class="!w-max"
          @click="$emit('removeImage')"
          :theme="theme"
          :disabled="disabled"
          icon="icon-remove-entity"
        />
      </div>
    </template>

    <v-modal
      ref="modal"
      :modalHeader="text"
    >
      <v-upload
        v-model="file"
        :theme="theme"
        :accept="accept ?? 'image/*'"
        @update:model-value="imageUploaded"
      >
        <p>
          {{ $t('components.upload.drag-and-drop-a-file-here') }}
        </p>

        <p>
          {{ $t('components.upload.or') }}
        </p>

        <v-button
          @clicked="$event.target.parentNode.click()"
          class="!w-max"
          theme="gray"
        >
          {{ $t('components.upload.choose-file') }}
        </v-button>
      </v-upload>
    </v-modal>
  </div>
</template>

<script>
import Modal from '/src/components/partials/Modal.vue'
import Upload from '/src/components/partials/Upload.vue'
import Button from '/src/components/functionality/Button.vue'
import IconButton from '/src/components/functionality/IconButton.vue'

export default {
  name: 'ImageUpload',

  components: {
    'v-modal': Modal,
    'v-upload': Upload,
    'v-button': Button,
    'v-icon-button': IconButton,
  },

  data() {
    return {
      file: ''
    }
  },

  props: {
    modelValue: {},
    theme: String,
    text: String,
    accept: String,
    disabled: Boolean,
    type: String,
  },

  emits: [
    'update:modelValue',
    'removeImage'
  ],

  methods: {
    imageUploaded() {
      this.$emit('update:modelValue', this.file)
      this.$refs.modal.close()
    }
  }
}
</script>
