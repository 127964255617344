<template>
  <div v-if="!!panels.length" class="mb-5 flex flex-col gap-y-2 w-full rounded-lg bg-[var(--EntityBG)] p-2">
    <v-panel
      v-for="(component, index) in panels"
      v-model="panels[index]"
      :order="index"
      :last-index="panels.length - 1"
      :activeLanguage="activeLanguage"
      @changeOrder="args => changeOrder(...args)"
      @removeEntry="removeEntry(index)"
    >
      <slot :name="`slot-${index}`"></slot>
    </v-panel>
  </div>

  <div class="mt-1 flex w-full lg:w-1/2 xl:w-1/3">
    <v-selectbox
      v-if="panelTypes.length > 1"
      v-model="newPanel"
      :options="panelTypes"
      :key-value="['handle', 'name']"
      :no-remove="true"
      theme="white"
      class="mr-5"
    />
    <v-icon-button
      theme="gray"
      icon="icon-add-entity"
      class="rounded-full flex-shrink-0 !w-12 aspect-square"
      :disabled="!Object.values(newPanel).length"
      @click="addOutline"
      :title="$t('general.add')"
    />
  </div>
</template>

<script>
import Panel from '../Panel.vue'
import SelectBox from '../../functionality/SelectBox.vue'
import IconButton from '../../functionality/IconButton.vue'

import OutlineOpenQuestionPanel from '../panels/outlines/open-question'
import OutlineSelectBoxPanel from '../panels/outlines/selectbox'
import OutlineCheckBoxPanel from '../panels/outlines/checkbox'
import OutlineRadioPanel from '../panels/outlines/radio'
import OutlineMediaUploadPanel from '../panels/outlines/media-upload'
import OutlineOptionPanel from '../panels/outlines/option'

export default {
  name: 'Organizer',

  components: {
    'v-panel': Panel,
    'v-selectbox': SelectBox,
    'v-icon-button': IconButton,
  },

  data() {
    return {
      openByDefault: true,
      panels: this.modelValue,
      newPanel: {}
    }
  },

  props: {
    modelValue: {},
    panelTypes: {},
  },

  inject: {
    languages: {
      from: 'languages'
    },
    activeLanguage: {
      from: 'activeLanguage'
    }
  },

  mounted() {
    if (this.panelTypes.length === 1) {
      this.newPanel = this.panelTypes[0]
    }
  },

  methods: {
    generateComponentFromJson(entry) {
      entry.visible = this.openByDefault
      return entry
    },

    addOutline() {
      let panel = {}
      switch(this.newPanel.handle) {
        case 'open-question':
          panel = new OutlineOpenQuestionPanel()
          break
        case 'selectbox':
          panel = new OutlineSelectBoxPanel()
          break
        case 'checkbox':
          panel = new OutlineCheckBoxPanel()
          break
        case 'radio':
          panel = new OutlineRadioPanel()
          break
        case 'media-upload':
          panel = new OutlineMediaUploadPanel()
          break
        case 'option':
          panel = new OutlineOptionPanel()
          break
      }

      this.languages.forEach(language => {
        panel.settings.name.push({
          id: language.id,
          value: ''
        })

        panel.settings.content.push({
          id: language.id,
          value: ''
        })
      })

      this.panels.push(panel)

      const newComponent = this.panels[this.panels.length - 1]

      newComponent.order = this.panels.length
      newComponent.visible = this.openByDefault
    },

    changeOrder(index, moveUp) {
      const component = this.panels[index]

      this.panels[index] = this.panels[moveUp ? index - 1 : index + 1]
      this.panels[moveUp ? index - 1 : index + 1] = component

      this.panels[index].order = index + 1
      component.order = (moveUp ? index - 1 : index + 1) + 1
    },

    removeEntry(index) {
      this.panels.splice(index, 1)
    }
  },

  watch: {
    panels: {
      handler() {
        this.$emit('update:modelValue', this.panels)
      },
      deep: true
    }
  }
}
</script>
