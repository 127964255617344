<template>
  <v-loader></v-loader>
</template>

<script>
import Loader from '/src/components/partials/Loader.vue'

export default {
  name: 'Logout',

  components: {
    'v-loader': Loader
  },

  mounted() {
    this.$router.push({ name: 'Login' })
      .then(() => {
        this.$store.dispatch('AuthService/logout')
      })
  }
}
</script>
