<template>
  <span class="flex self-center">
    {{ modelValue }}
    {{ label }}
  </span>

  <div class="flex divide-x divide-x-reverse self-center">
    <v-button
      @click="$emit('update:modelValue', --modelValue)"
      class="decrement !w-max !px-3 rounded-r-none rounded-lg"
      theme="white"
    >
      &minus;
    </v-button>

    <v-button
      @click="$emit('update:modelValue', ++modelValue)"
      class="increment !w-max !px-3 rounded-l-none rounded-lg"
      theme="white"
    >
      &plus;
    </v-button>
  </div>
</template>

<script>
import Button from '/src/components/functionality/Button.vue'

export default {
  name: 'LabeledCounter',

  components: {
    'v-button': Button
  },

  props: {
    label: String,
    modelValue: Number
  },

  emits: [
    'update:modelValue'
  ]
}
</script>
