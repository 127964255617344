import Axios from 'axios'

const axios = Axios.create({
  baseURL: `https://${ localStorage.getItem('api_url') ?? import.meta.env.VITE_MANAGER_URL }/api/`,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  },
  timeout: import.meta.env.VITE_API_CALL_TIMEOUT
})

window.addEventListener('ApiUrlChange', () => {
  axios.defaults.baseURL = `https://${localStorage.getItem('api_url') }/api/`
})

window.addEventListener('tokenChange', () => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`
})

export default axios
