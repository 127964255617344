class MediaUpload {
  constructor() {
    return {
      type: 'media-upload',
      name: 'Media upload',
      settings: {
        name: [],
        content: [],
        validations: []
      }
    }
  }
}

export default MediaUpload
