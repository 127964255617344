<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full bg-[var(--EntityBG)]">
    <div v-if="sortedItems.length">
      <v-table-view-header
        ref="tableViewHeader"
        v-model="sortDescending"
        :entity="$t('components.user(s)')"
        :deletable="true"
        :delete-clause="!!this.usersSelected.length"
        @deleteSelection="deleteSelection"
        :searchbar="searchbar"
        :results="visibleItems !== sortedItems.length ? `${visibleItems} ${$t('general.of')} ${sortedItems.length}` : sortedItems.length"
      >
        <template v-slot:filterOptions>
          <div>
            <p class="text-[var(--NovikGray)]">{{ $t('components.role.toggle-status') }}</p>
            <div v-for="role in roles" class="flex justify-between p-2 text-sm rounded hover:bg-[var(--EntityBG)]">
              <v-toggleswitch
                v-model="role.active"
                :entry="{
                  text: `${role.name} (${users.filter(user => user.role.filter(entry => entry.name === role.name).length).length})`,
                  entry: role.active
                }"
                :keyValue="['entry', 'text']"
              />
            </div>
          </div>
        </template>
      </v-table-view-header>

      <template
        v-for="user in sortedItems"
      >
        <template
          v-if="!!roles.filter(entry => entry.active).length ?
            roles.filter(entry => entry.active).filter(role => user.role.filter(entry => entry.name === role.name).length).length :
            roles
          "
        >
          <div
            v-if="searchables.map(entry => {
              return String(user[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
            }).some(v => v === true)"
            class="
              border-b
              border-[var(--EntityDarkBG)]
              duration-200
              transition-[background-color]
              hover:bg-[var(--EntityDarkBG)]
              flex
            "
          >
            <v-checkbox
              class="pl-5"
              v-model="usersSelected"
              :option="user"
              theme="white"
              :showText="false"
              :keyValue="['id', 'firstname']"
            />

            <router-link
              v-if="routeInPermissions(this.$router.resolve({ name: 'UsersDetail', params: { id: user.id } }), permissions)"
              class="flex items-center ml-5 my-2"
              :to="{ name: 'UsersDetail', params: { id: user.id } }"
            >
              <v-avatar v-if="!!Object.keys(user).length" :user="user" theme="small"></v-avatar>
            </router-link>

            <div class="ml-5 flex flex-col self-center my-2">
              <router-link
                v-if="routeInPermissions(this.$router.resolve({ name: 'UsersDetail', params: { id: user.id } }), permissions)"
                :to="{ name: 'UsersDetail', params: { id: user.id } }"
              >
                <p
                  class="text-[var(--NovikGray)]"
                  v-html="highlightSubstringInString(searchbar.request, `${user.firstname} ${user.lastname}`)"
                />
              </router-link>
              <div>
                <template v-for="(role, index) in user.role">
                  <router-link
                    v-if="routeInPermissions(this.$router.resolve({ name: 'RolesDetail', params: { id: role.id } }), permissions)"
                    :to="{ name: 'RolesDetail', params: { id: role.id } }"
                    class="text-[var(--NovikLightGray)] hover:text-[var(--NovikGray)] transition-colors duration-300"
                  >
                    {{ role.name }}<span v-if="index !== user.role.length - 1">, </span>
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>

    <div v-else>
      <h1 class="text-xl text-[var(--NovikGray)] m-5">{{ $t("general.no-results") }}</h1>
    </div>

    <v-add entity="UsersAdd"/>
  </div>
</template>

<script>
import tableView from '/src/views/abstracts/tableView'

import Avatar from '/src/components/account/Avatar.vue'

export default {
  name: 'Users',

  components: {
    ...tableView.components,
    'v-avatar': Avatar
  },

  data() {
    return {
      ...tableView.data,
      toast: this.$root.$refs.toast,
      users: [],
      usersSelected: [],
      roles: [],
      searchables: ['firstname', 'lastname']
    }
  },

  inject: {
    ...tableView.inject
  },

  mounted () {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...tableView.computed,

    visibleItems() {
      let count = 0

      this.sortedItems.forEach(item => {
        if (this.searchables.map(entry => {
          return String(item[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
        }).some(v => v === true)) {
          count++
        }
      })

      return count
    },

    sortedItems() {
      if (!this.users) {
        return []
      }

      const data = tableView.computed.sortData(this.users, 'firstname')

      if (this.sortDescending) {
        return data.reverse()
      }

      return data
    }
  },

  methods: {
    ...tableView.methods,

    fetchData () {
      this.$store.dispatch('UserService/users')
        .then(data => {
          this.users = data
        })
      this.$store.dispatch('RoleService/getRoles')
        .then(data => {
          this.roles = data
          this.roles.forEach(entry => entry.active = false)
        })
    },

    deleteSelection() {
      this.users
        .filter(user => Object.values(this.usersSelected).includes(String(user.id)))
        .forEach(user => {
          this.$store.dispatch("UserService/deleteUser", user.id)
              .then((response) => {
                this.toast.make({
                  'status': 'success',
                  'text': response.data.status.message,
                  'timer': 5000
                })

                this.fetchData()
              })
              .catch((error) => {
                this.toast.make({
                  'status': 'error',
                  'text': error.response.data.status.error.message,
                  'timer': 0
                })
              })
        })

      this.$refs.tableViewHeader.$refs.deleteModal.close()
    }
  }
}
</script>
