<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full flex flex-col bg-[var(--EntityBG)]">
    <div
      class="
        mt-20
        px-4
        md:px-0
        w-full
        sm:w-2/3
        md:w-3/5
        mx-auto
        md:mx-0
        flex
        flex-col
        self-center
      "
    >
      <v-card>
        <p>export</p>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Loader from '/src/components/partials/Loader.vue'
import Card from '/src/components/partials/Card.vue'

export default {
  name: 'Export',

  components: {
    'v-loader': Loader,
    'v-card': Card
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },
}
</script>
