import BrandRepository from '/src/store/repositories/BrandRepository'
import { setLoading } from '/src/helper.js'

export const BrandService = {
  namespaced: true,

  actions: {
    switchBrands ({ state }, data) {
      setLoading(state, 'switchBrands', true)
      return BrandRepository.switchBrands(data.fqdn)
        .then(response => {
          setLoading(state, 'switchBrands', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          localStorage.setItem('api_url', data.fqdn)
          window.dispatchEvent(new CustomEvent('ApiUrlChange'))

          localStorage.setItem('token', response.data.data.token)
          window.dispatchEvent(new CustomEvent('tokenChange'))

          return response
        })
        .catch(error => {
          setLoading(state, 'switchBrands', false)
          throw error
        })
    },

    getBrands({ state }) {
      setLoading(state, 'getBrands', true)
      return BrandRepository.getBrands()
        .then(response => {
          setLoading(state, 'getBrands', false)
          if (response.data.status.code !== 200) {
            throw response
          }

          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'getBrands', false)
          throw error
        })
    }
  }
}
