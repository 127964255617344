import AuthRepository from '/src/store/repositories/AuthRepository'
import { setLoading } from '/src/helper.js'

export const AuthService = {
  namespaced: true,

  actions: {
    login ({ state }, credentials) {
      setLoading(state, 'login', true)
      return AuthRepository.login(credentials)
        .then(response => {
          setLoading(state, 'login', false)
          if (response.data.status.code !== 200) {
            throw response
          } else {
            localStorage.setItem('token', response.data.data.token)
            window.dispatchEvent(new CustomEvent('tokenChange'))
          }
        })
        .catch(error => {
          setLoading(state, 'login', false)
          throw error
        })
    },

    logout () {
      // Globally set the user object to null
      window.dispatchEvent(new CustomEvent('userRefreshed', { detail: null }))

      localStorage.removeItem('token')
      localStorage.removeItem('api_url')
    },

    forgetPassword ({ state }, data) {
      return AuthRepository.forgetPassword(data)
        .then(response => {
          return response
        })
        .catch(error => {
          throw error
        })
    },

    resetPassword({ state }, data) {
      setLoading(state, 'resetPassword', true)
      return AuthRepository.resetPassword(data)
        .then(response => {
          setLoading(state, 'resetPassword', false)
          if (response.data.status.code !== 200) {
            throw response
          }
          return response.data.data
        })
        .catch(error => {
          setLoading(state, 'resetPassword', false)
          throw error.response.data.status.error.message
        })
    }
  }
}
