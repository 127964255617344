<template>
  <div class="flex flex-col lg:flex-row gap-x-8 w-full">
    <div class="mb-5 text-[var(--NovikGray)] w-full lg:w-1/2 xl:w-1/3">
      <label :for="`key-${identifier}`" class="block text-sm">{{ $t('general.key') }}</label>
      <v-input
        type="text"
        :name="`key-${identifier}`"
        v-model="modelValue.name.filter(entry => entry.id === activeLanguage)[0].value"
        autocomplete="off"
        :debounced="true"
      />
    </div>
    <div class="mb-5 text-[var(--NovikGray)] w-full lg:w-1/2 xl:w-1/3">
      <label :for="`name-${identifier}`" class="block text-sm">{{ $t('general.name') }}</label>
      <v-input
        type="text"
        :name="`name-${identifier}`"
        v-model="modelValue.content.filter(entry => entry.id === activeLanguage)[0].value"
        autocomplete="off"
        :debounced="true"
      />
    </div>
  </div>
</template>

<script>
import Input from '/src/components/functionality/Input.vue'
import { uniqueId } from '/src/helper'

export default {
  name: 'Option',

  components: {
    'v-input': Input
  },

  data() {
    return {
      identifier: uniqueId(),
    }
  },

  props: {
    modelValue: {}
  },

  inject: {
    activeLanguage: {
      from: 'activeLanguage'
    }
  },
}
</script>
