<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="flex flex-col md:flex-row justify-center">
    <div class="mt-20 mx-auto md:mx-24 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6">
      <v-avatar
        v-if="!!Object.keys(user).length"
        :user="user"
        theme="large"
      />
    </div>

    <div class="mt-20 mx-auto px-4 md:px-0 w-full sm:w-2/3 md:w-1/2 lg:w-2/5 xl:w-1/3 md:mx-0 flex flex-col">
      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="first-name" class="block text-sm">{{ $t('general.firstname') }}</label>
        <v-input
          type="text"
          name="first-name"
          v-model="user.firstname"
          autocomplete="given-name"
          :debounced="true"
          @update:modelValue="commitData({
            endpoint: '',
            data: user
          })"
        />
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="last-name" class="block text-sm">{{ $t('general.lastname') }}</label>
        <v-input
          type="text"
          name="last-name"
          v-model="user.lastname"
          autocomplete="family-name"
          :debounced="true"
          @update:modelValue="commitData({
            endpoint: '',
            data: user
          })"
        />
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <label for="email" class="block text-sm">{{ $t('general.email') }}</label>
        <v-input
          type="email"
          name="email"
          v-model="user.email"
          autocomplete="off"
          :debounced="true"
          readonly
        />
      </div>

      <div class="mb-5">
        <p class="text-sm text-[var(--NovikGray)]">{{ $t('components.language') }}</p>
        <v-selectbox
          v-if="languages && user.language"
          identifier="kitchensinkSelectBox"
          v-model="user.language"
          :options="languages"
          :noRemove="true"
          theme="white"
          :keyValue="['id', 'name']"
          @update:modelValue="commitData({
            endpoint: '',
            data: user
          })"
        />
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <p class="text-[var(--NovikGray)]">{{ $t('components.roles') }}</p>
        <v-card>
          <div
            v-if="!!user.role"
            class="
              flex
              justify-between
              p-2
              text-sm
              rounded
              hover:bg-[var(--EntityBG)]
            "
            v-for="role in roles"
          >
            <v-toggleswitch
              v-model="user.role"
              :entry="role"
              :keyValue="['id', 'name']"
              :debounced="false"
              @update:modelValue="commitData({
                endpoint: '',
                data: user
              })"
            />
          </div>
        </v-card>
      </div>

      <div
        v-if="user.settings"
        v-for="settingGroup in user.settings"
        class="mb-5 text-[var(--NovikGray)]"
      >
        <p class="text-[var(--NovikGray)]">{{ settingGroup.group_name }}</p>
        <v-card>
          <v-setting
            v-for="setting in settingGroup.settings"
            :setting="setting"
            :parent="true"
            @update="(args) => commitData({
                endpoint: '/settings',
                data: args
              })"
          />
        </v-card>
      </div>

      <div class="mb-5 text-[var(--NovikGray)]">
        <v-button
          class="!w-max float-right"
          theme="gray"
          @click="this.$refs.modal.open()"
        >
          {{ $t('components.deactivate') }} {{ $t('components.user') }}
        </v-button>
      </div>
      <v-modal ref="modal" :modalHeader="$t('general.delete-selection')">
        <p>{{ $t('general.delete-confirmation', {'entity': $t('components.user')}) }}</p>
        <div class="flex justify-end items-center">
          <a class="mr-9 text-[var(--NovikGray)] uppercase cursor-pointer" @click="this.$refs.modal.close()">
            {{ $t('general.cancel') }}
          </a>
          <v-button
            class="!w-max uppercase"
            theme="gray"
            @click="deleteUser"
          >
            {{ $t('general.confirm') }}
          </v-button>
        </div>
      </v-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Button from '/src/components/functionality/Button.vue'
import Input from '/src/components/functionality/Input.vue'
import ToggleSwitch from '/src/components/functionality/ToggleSwitch.vue'
import Modal from '/src/components/partials/Modal.vue'
import Loader from '/src/components/partials/Loader.vue'
import SelectBox from '/src/components/functionality/SelectBox.vue'
import Avatar from '/src/components/account/Avatar.vue'
import Card from '/src/components/partials/Card.vue'
import Setting from '/src/components/partials/Setting.vue'

export default {
  name: 'Detail',

  components: {
    'v-setting': Setting,
    'v-button': Button,
    'v-input': Input,
    'v-toggleswitch': ToggleSwitch,
    'v-modal': Modal,
    'v-loader': Loader,
    'v-selectbox': SelectBox,
    'v-avatar': Avatar,
    'v-card': Card
  },

  data() {
    return {
      user: {},
      roles: [],
      languages: []
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
    })
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  methods: {
    fetchData() {
      this.$store.dispatch("UserService/getUser", this.$route.params.id)
        .then(data => {
          this.user = data
        })
        .catch(error => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.message,
            'timer': 0
          })

          this.$router.push({name: 'Users'})
        })

      this.$store.dispatch("RoleService/getRoles")
        .then(data => {
          this.roles = data
        })

      this.$store.dispatch("UserService/getLanguages")
        .then(data => {
          this.languages = data.data
        })
    },

    commitData(args) {
      let data = args
      data.id = this.user.id

      this.$store.dispatch('UserService/commitData', data)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 2000
          })
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message,
            'timer': false
          })
        })
    },

    deleteUser() {
      this.$refs.modal.close()

      this.$store.dispatch("UserService/deleteUser", this.user.id)
        .then((response) => {
          this.$root.$refs.toast.make({
            'status': 'success',
            'text': response.data.status.message,
            'timer': 5000
          })

          this.$router.push({name: 'Users'})
        })
        .catch((error) => {
          this.$root.$refs.toast.make({
            'status': 'error',
            'text': error.response.data.status.error.message ?? error,
            'timer': 0
          })
        })
    }
  }
}
</script>
