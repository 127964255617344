import axios from '/src/store/utility/Request'

class CodesRepository {
  getCodes() {
    return axios.get('codes')
  }

  createCode(data) {
    return axios.post('codes', data)
  }

  generateCodesForCampaign(id, data) {
    return axios.post(`codes/generate/campaign/${id}`, data)
  }
}

export default new CodesRepository()
