<template>
  <v-loader v-if="loading"></v-loader>

  <div v-else class="w-full bg-[var(--EntityBG)]">
    <div v-if="sortedItems.length">
      <v-table-view-header
        ref="tableViewHeader"
        v-model="sortDescending"
        :entity="$t('components.product(s)')"
        :searchbar="searchbar"
        :results="visibleItems !== sortedItems.length ? `${visibleItems} ${$t('general.of')} ${sortedItems.length}` : sortedItems.length"
      />

      <template
        v-for="product in sortedItems"
      >
        <div
          v-if="searchables.map(entry => {
            return String(product[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
          }).some(v => v === true)"
          class="
            border-b
            border-[var(--EntityDarkBG)]
            duration-200
            transition-[background-color]
            hover:bg-[var(--EntityDarkBG)]
            flex
          "
        >
          <div class="flex justify-center items-center pl-5">
            <div
              class="w-4 h-4 rounded-full"
              :class="product.is_active ? 'bg-[var(--NovikGreen)]' : 'bg-[var(--NovikRed)]'"
            />
          </div>

          <router-link
            v-if="routeInPermissions(this.$router.resolve({ name: 'ProductsDetail', params: { id: product.id } }), permissions)"
            class="flex items-center ml-5 my-2"
            :to="{ name: 'ProductsDetail', params: { id: product.id } }"
            :title="product.name"
          >
            <v-image
              :clause="!!product.image"
              theme="small"
              :image="{
                src: product.image,
                alt: product.name
              }"
              class="!rounded-xl"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 50.732 44.391"><path class="fill-white" d="M50.732,43.1V71.635a4.757,4.757,0,0,1-4.756,4.756H4.756A4.757,4.757,0,0,1,0,71.635V43.1a4.757,4.757,0,0,1,4.756-4.756h8.72l1.219-3.26A4.75,4.75,0,0,1,19.144,32H31.579a4.75,4.75,0,0,1,4.449,3.082l1.229,3.26h8.72A4.757,4.757,0,0,1,50.732,43.1ZM37.257,57.366a11.89,11.89,0,1,0-11.89,11.89A11.9,11.9,0,0,0,37.257,57.366Zm-3.171,0a8.72,8.72,0,1,1-8.72-8.72A8.732,8.732,0,0,1,34.086,57.366Z" transform="translate(0 -32)"/></svg>
            </v-image>
          </router-link>

          <div class="ml-5 flex flex-col self-center my-2">
            <router-link
              v-if="routeInPermissions(this.$router.resolve({ name: 'ProductsDetail', params: { id: product.id } }), permissions)"
              :to="{ name: 'ProductsDetail', params: { id: product.id } }"
            >
              <p
                class="text-[var(--NovikGray)]"
                v-html="highlightSubstringInString(searchbar.request, product.name)"
              />
            </router-link>

            <div>
              <router-link
                v-if="routeInPermissions(this.$router.resolve({ name: 'ProductsDetail', params: { id: product.id } }), permissions)"
                :to="{ name: 'ProductsDetail', params: { id: product.id } }"
                class="text-[var(--NovikLightGray)] hover:text-[var(--NovikGray)] transition-colors duration-300"
                v-html="$t('components.article-number', {articleNumber: highlightSubstringInString(searchbar.request, product.article_number)})"
              />
            </div>
          </div>
        </div>
      </template>
    </div>

    <div v-else>
      <h1 class="text-xl text-[var(--NovikGray)] m-5">{{ $t("general.no-results") }}</h1>
    </div>

    <v-add entity="ProductsAdd"/>
  </div>
</template>

<script>
import tableView from '/src/views/abstracts/tableView'
import Image from '/src/components/partials/Image.vue'

export default {
  name: 'Products',

  components: {
    ...tableView.components,
    'v-image': Image
  },

  data() {
    return {
      ...tableView.data,
      toast: this.$root.$refs.toast,
      products: [],
      searchables: ['name', 'article_number', 'serial_mask']
    }
  },

  inject: {
    ...tableView.inject
  },

  mounted() {
    this.fetchData()
    window.addEventListener('userRefreshed', () => this.fetchData())
  },

  computed: {
    ...tableView.computed,

    visibleItems() {
      let count = 0

      this.sortedItems.forEach(item => {
        if (this.searchables.map(entry => {
          return String(item[entry]).toLowerCase().includes(this.searchbar.request.toLowerCase())
        }).some(v => v === true)) {
          count++
        }
      })

      return count
    },

    sortedItems() {
      if (!this.products) {
        return []
      }

      const data = tableView.computed.sortData(this.products, 'name')

      if (this.sortDescending) {
        return data.reverse()
      }

      return data
    }
  },

  methods: {
    ...tableView.methods,

    fetchData() {
      this.$store.dispatch('ProductService/getProducts')
        .then(data => {
          this.products = data
        })
    }
  }
}
</script>
